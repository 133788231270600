import { makeStyles, createStyles } from '@material-ui/core'
import {smFontSize, xsFontSize} from "../../../utils/constants";

export const styles = theme => createStyles({
  header: {
    fontFamily: 'Roboto-Bold!important',
    fontSize: 24,
    color: '#202A38!important',
    margin: 0
  },
  sourceItemCheckBox: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      margin: 0,
      padding: 0
    },
    marginBottom: 5
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,

    '& button >span > span': {
      fontSize: 14
    }
  },
  subNavigationContainer: {
    display: 'flex',
    marginLeft: 'auto',
    padding: '8px 0',
    justifyContent: 'flex-end',
  },
  mainContentContainer: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '24px 0'
  },
  sectionHeader: {
    fontFamily:'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0,
    display: 'flex',
    alignItems: 'baseline'
  },
  instrumentsNumber: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  tableCell: {
    width: 100,
    '&:nth-child(2)': {
      minWidth: 83,
      whiteSpace: 'normal'
    },
    '&:first-child': {
      width: 350
    },
    whiteSpace: 'nowrap'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > h1': {
      fontFamily: 'Roboto-Bold',
      fontSize: '202px',
      color: '#80858C',
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: 122
      },
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 40,
      margin: 0,
      color: '#202A38',
      marginTop: -40,

      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
        marginTop: -20,
      },
    }
  },
  assetsDataErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 24,
      color: '#202A38'
    }
  },
  navigation: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#202A38',
      margin: '15px 0'
    }
  },
  whiteBox: {
    background: 'transparent linear-gradient(180deg, transparent 0%, transparent 54.5px, #FFF 54.5px, #FFF 100%) 0% 0% no-repeat padding-box'
  },
  link: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  backwardArrow: {
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.6em',
      height: '0.6em',
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginRight: '0.3em'
    }
  },
  tabContent: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '50px 0',
    [theme.breakpoints.only("xs")]: {
      '& .MuiTabs-scrollButtonsDesktop:not(.Mui-disabled)': {
        display: 'inline-flex !important',
        width: 30
      }
    }
  },
  tabTitle: {
    // backgroundColor: 'white',
    background: 'white linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 74%, #F5F6F7 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    fontSize: 14,
    padding: 15,
    fontFamily: 'Roboto-Bold!important',
    color: '#202A38!important',
    marginLeft: 1,
    marginRight: 2,
    maxWidth: 'none',
    "&:first-child": {
      marginLeft: 0
    },
    "&:last-child": {
      marginRight: 0
    },
    '& .Mui-selected': {
      background: 'white'
    },
    '&:focus': {
      outline: 'none'
    },

    [theme.breakpoints.only("sm")]: {
      fontSize: smFontSize,
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: xsFontSize,
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  selected: {
    background: 'white',
    color: theme.palette.primary.main+"!important",
    '&:after': {
      content: "''",
      width: 10,
      height: 10,
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.primary.main}`,
      display: 'block',
      position: 'absolute',
      transform: 'rotate(45deg)',
      borderRight: 'none',
      borderBottom: 'none',
      bottom: -4
    }
  },
  indicator: {
    display: 'none',
  },
  tabsHeader: {
    background: 'none!important',
    boxShadow: 'none!important'
  },
  loadingLine: {
    width: '30%',
    fontSize: 24,
    lineHeight: '1.2em',
    backgroundColor: '#d5d6d7',
    backgroundImage: 'linear-gradient(to right, #d5d6d7 0%, #b8bcbd 20%, #d5d6d7 40%, #d5d6d7 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '& p': {
      display: 'inline-block',
      margin: 0
    },

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },

  investmentNavigationContainer: {
    width: 'calc(78% - 9px)',

    [theme.breakpoints.only('md')]: {
      width: 'calc(75% - 9px)',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  totalReturnContainer: {
    width: '22%',
    marginLeft: 9,

    [theme.breakpoints.only('md')]: {
      width: '25%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0
    },
  },
    totalReturnCard: {
    backgroundColor: 'white',
    width: '100%',
    padding: 20,
    position: 'relative',
    minHeight: 105,
    maxHeight: 105,
    margin: '10px 0px',
    boxSizing: 'border-box',
    borderRadius: 2,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(1362)]: {
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.only('md')]: {
      padding: 20
    },

    [theme.breakpoints.down('sm')]: {
      padding: 15,
      margin: 0
    },
  },
  containerName: {
    marginLeft: '9px !important'
  },
  containerCheckbox: {
    marginLeft: 5
  },
  // TODO: duplicated
  panelButton: {
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      minHeight: 35
    },
  },
})

export default makeStyles(styles)