import React from 'react';
import clsx from 'clsx';
import Tooltip from '../Tooltip';

import useStyles, {useInputWarningTooltipStyles} from './styles';
import WarningTooltip from "./index";
import {InfoIcon} from "../../images";

export default (props) => {
  const {
    placement = 'top',
    icon,
    iconClassName
  } = props;

  const classes = useStyles(props);

  return (
    <>
      <Tooltip placement={placement} {...props}>
        {icon || (<i className={clsx(iconClassName || classes.icon, 'far fa-info-circle',)} />)}
      </Tooltip>
    </>
  );
};

/**
 * Warning Tooltip wrapper for customized styles to be used alongside with inputs.
 * @param props
 * @constructor
 */
export function InputWarningTooltip(props) {

  const classes = useInputWarningTooltipStyles();

  return (
    <WarningTooltip {...props} icon={<span className={classes.icon} ><InfoIcon color={"rgba(0, 0, 0, 0.26)"}/></span>}/>
  )

}

export const PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT = 'Nur als “Gesamtdepot” darstellbar';

