import {fontSize, smFontSize} from "../../../../utils/constants";

export default (theme) => ({
  closeButton: {
    float: 'right',
    padding: 4
  },
  dialogFullWidth: {
    width: 'calc(100% - 48px)',
    margin: '0 !important'
  },
  dialogTitle: {
    padding: '8px 8px 0',
    '& span': {
      fontFamily: 'Roboto-Bold'
    }
  },
  dialogContent: {
    padding: 0,
    '& > li': {
      paddingLeft: 9
    }
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: 'transparent !important',
    zIndex: 2,
    marginTop: '1px !important'
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryRoot: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    minHeight: '45px !important',
    height: '45px !important',
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
    boxSizing: 'border-box',
    borderRadius: '4px !important',
    padding: 10,
    backgroundColor: 'white',
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px!important',
    height: '45px!important',
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    color: '#202A38',
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    fontSize: 16,
    padding: '0px',
    width: 'calc(100% - 48px)'
  },
  expansionPanelDetailsRoot: {
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'white !important',
    display: 'block'
  },
  portfolioList: {
    backgroundColor: 'white',
    padding: 0,
    border: `1px solid #D8DCDF`,
    borderRadius: 3,
    borderTop: 'none',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '65vh',
      overflowY: 'auto'
    },
    '& $portfolioListItem': {
      transition: 'all',
      transitionDuration: '0.2s',

      [theme.breakpoints.down('sm')]: {
        padding: 8,
      },

    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    },

    '& $portfolioListItemSelected:hover': {
      cursor: 'pointer',
      backgroundColor: '#D8DCDF'
    },

    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#202A38',
    }
  },
  portfolioListDialog: {
    '& li': {
      paddingLeft: '0 !important'
    },
  },
  alwaysScrollableList: {
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 40px)',

    ['@media (max-height:900px)']: {
      maxHeight: 'calc(70vh - 40px)',
    },
  },
  portfolioListItem: {
    padding: 0,
    paddingRight: 9,
    height: 45,
    '& span': {
      fontFamily: 'Roboto-Regular',
      color: '#202A38',
      fontSize: 14,

      [theme.breakpoints.down('md')]: {
        fontSize: smFontSize
      }
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingRight: 8
    },
  },
  portfolioListItemSelected: {
    backgroundColor: '#DBEDFA !important',
    '& > span': {
      fontFamily: 'Roboto-Bold'
    }
  },
  portfolioListCheckbox: {
    '&:hover': {
      backgroundColor: 'transparent'
    },

    [theme.breakpoints.down('md')]: {
      padding: 5,
    }
  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
  itemPlaceholder: {
    width: '100%',
    height: '20px',
    backgroundColor: '#f6f7f8',
    backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',
  },
  portfolioListItemPlaceholder: {
    borderBottom: '1px solid #E1E3E9',
    '&:nth-child(2n) $itemPlaceholder': {
      width: '75%'
    }
  },
  startButton: {
    fontFamily: 'Roboto-Bold',
    color: 'white',
    textTransform: 'none',
    padding: '9px 16px',
    boxShadow: 'none',
    borderRadius: 0,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  productsComparisonListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > span:first-child': {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '& > span:last-child': {
        display: 'block',
      }
    }
  },
  portfolioListItemAggregateSelectedCheckbox: {
    paddingLeft: 25,
    '&:hover': {
      cursor: 'auto',
      background: 'white'
    },
    '& > span': {
      color: '#4D4F5C',
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
      marginLeft: 15
    }
  },
  aggregatePortfoliosSwitchContainer: {
    height: 30,
    width: 69
  },
  aggregatePortfoliosSwitchThumb: {
    height: 24,
    width: 24,
    top: 2,
    left: 2
  },
  aggregatePortfoliosSwitchLabel: {
    padding: '0 10px'
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 10,
    fontSize: 17,
    color: '#4D4F5C',
  },
  errorIcon: {
    color: '#ADB6BD',
    fontSize: 22
  },
  portfolioListItemWithAssets: {
    position: 'relative',
    paddingRight: 37
  },
  portfolioListItemFocused: {
    background: '#EFEFF2 !important'
  },
  assetsArrowButton: {
    position: 'absolute',
    right: 0,
    padding: 6,
    '& svg': {
      fill: 'rgba(0, 0, 0, 0.54)'
    }
  },
  menuPaper: {
    border: '1px solid rgba(0, 0, 0, 0.23) !important',
    maxHeight: 450,
  },
  selectedAssetsWarning: {
    fontSize: '12px !important',
    color: '#F0AB58 !important',
    '& > i': {
      marginRight: 5,
      marginLeft: 10
    }
  }
});