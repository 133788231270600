import React from 'react';
import _ from 'lodash';
import { HistoricalValueChart } from 'fincite-react-charts';
import { connect } from 'react-redux';

import styles from './styles';
import {INVESTMENT_CHART_COLOR} from "../../../../../../utils/constants";
import {hasResponseError, paginateArray} from "../../../../../../utils/utils";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import { getHostRelatedSetting } from '../../../../../../utils/sharedSettings';
import { HOST_RELATED_SHARED_SETTINGS_KEYS} from '../../../../../../components/SharedSettingsProvider/constants';
import {ChartTooltipSettings, getPortfolioChartName, setDatesChanged} from "../../../../utils";
import {DashboardChartSection} from "../index";
import {Grid} from "@material-ui/core";
import moment from "moment";
import {setTimeSelectorDateChange} from "../../../../actions";
import clsx from "clsx";
import {aggregateSelectedDisabledExplanation} from "../../../../../DashboardSettings/constants";
import {getSharedSettingsSelector} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import {parseResponse} from "../../../../../../utils/api";
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import {TRANSACTIONS_DATA_LOADING_ERROR} from "../../../../../TransactionsMonitoring/constants";
import Transactions from "../../../../../TransactionsMonitoring/components/Transactions/Transactions";
import {TransactionsMonitoringContext} from "../../../../../TransactionsMonitoring/TransactionsMonitoring";
import {
  showOrderDateNote,
  tableStructureMinimised
} from "../../../../../TransactionsMonitoring/components/Transactions/components/List/table-data";
import {buildComponentsWithSubItemsDataSource} from "../../../../../../components/Charts/InstrumentsAllocationTable";
import HTMLHostRelatedSharedSetting from "../../../../../../components/HTMLHostRelatedSharedSettingV2";
import AggregatedDepotsSwitch from "../../../../components_v2/AggregatedDepotsSwitch/AggregatedDepotsSwitch";
import ChartSectionBordered from "../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import Legend from "../../../../components_v2/Legend/Legend";
import {useHistoricalData} from "../../../../hooks/useHistoricalData";


const mapStateToProps = (state) => ({
  sharedSettings:getSharedSettingsSelector(state)
});


const _historicalPerformanceChartPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.chartData.timestamp === nextProps.chartData.timestamp
    && prevProps.startDate === nextProps.startDate
    && prevProps.endDate === nextProps.endDate
}

export const getColorsFromChartData = (data) => data.map((portfolio) => portfolio.color)

const _HistoricalPerformanceChart = React.memo(({control, onDatesChange, startDate, endDate, chartData, classes}) => {

  let chartOptionSeries = [];
  if (chartData.transactionsTimeSeries ) {
    chartOptionSeries = [
      ...chartData.data.map(() => ({})), // Generate empty object for each time series
      {}, // Generate empty object for dummy line
      chartData.transactionsTimeSeries ? chartData.transactionsTimeSeries : {}]
  }

  const data = [...chartData.data, {name: '', portfolio: []}]; // Ghost element should be in array to use 'line' chart

  return (
    <div className={classes.chartContainer}>
      <HistoricalValueChart
        colors={getColorsFromChartData(data)}
        stockName="Portfolio X"
        title={''}
        data={data}
        chartOptions={{
          rangeSelector: {
            allButtonsEnabled: false,
            inputStyle: {
              visibility: 'hidden',
              opacity: 0,
            },
            labelStyle:{
              visibility: 'hidden',
              opacity: 0,
            },
            verticalAlign: 'top',
            buttons: []
          },
          chart: {
            ...ChartTooltipSettings.chart,
            height: 350,
            marginTop: 0,
            animation: false
          },
          boost: {
            enabled: true,
            useGPUTranslations: true,
            seriesThreshold: 1,
            debug: {
              timeRendering: true
            }
          },
          tooltip: {
            ...ChartTooltipSettings.tooltip,
            padding: ChartTooltipSettings.getTooltipPadding(data.length)
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            min: Date.parse(startDate) || undefined,
            max: Date.parse(endDate) || undefined,
            gridLineWidth: 1,
            events: {
              setExtremes: function (event) {
                const self = event.target.chart;
                if(self.rangeSelector.minInput.value && self.rangeSelector.maxInput.value) {
                  onDatesChange({start: moment(self.rangeSelector.minInput.value), end: moment(self.rangeSelector.maxInput.value)})
                }
              }
            }
          },
          yAxis: [{
            opposite: false,
            align: 'right',
            labels: {
              x: -2,
              y: 4,
              formatter: function() {
                return toShortGermanFormat(this.value, '', ' €', 2, true);
              }
            }
          }, {
            opposite: true,
            linkedTo: 0,
            align: 'right',
            labels: {
              x: 2,
              y: 4,
              formatter: function() {
                return toShortGermanFormat(this.value, '', ' €', 2, true);
              }
            }
          }],
          plotOptions: {
            series: {
              events: {
                legendItemClick: function legendItemClick() {
                  return true;
                }
              },
              connectNulls: true,
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              },
              showInNavigator: true,
            }
          },
          series: chartOptionSeries
        }}
      />
    </div>
  )
}, _historicalPerformanceChartPropsAreEquals)


const groupOrdersByExecutionDates = (orders) => {
  return  _.flatten(orders || []).reduce((result, order) => {
    const executionDateTime = moment(order.execution_datetime).format('YYYY-MM-DD');
    result[executionDateTime] = order.execution_datetime_point

    return result
  }, {})
}

const prepareOrdersDataPoints = (timeSeries, orders, dataPointsColor) => {

  const ordersGroupedByDate = groupOrdersByExecutionDates(orders);

  return (timeSeries || []).reduce((result, item) => {
    if (item.date in ordersGroupedByDate) {
      result.data.push({
        x: Date.parse(item.date),
        y: item.value,
        marker: {
          enabled: true,
          radius: 4,
          symbol: 'circle',
          fillColor: dataPointsColor
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return ordersGroupedByDate[item.date]
          }
        },
      })
    }

    return result
  }, {data: [], color: 'transparent', enableMouseTracking: false});

}


const HistoricalPerformanceChart = (props) => {
  const {
    dispatch,
    classes,
    theme,
    width,
    sharedSettings,
    onChartSettingsChange,
    onExpanded,
    expanded,
    hideControl,
    showInvestmentCapital,
    aggregateSelectedPortfolios,
    isCumulativeDisplaySwitchDisabled,
    showEmptyAssetsCheckbox,
    showEmptyAssets,
    TransactionsOverviewProps,
    customerId,
    startDate,
    endDate,
    dataProvider,
    portfolios,
    withHistorical,
    aggregatedPortfolioName,
    investmentStrategyId,
  } = props;

  const historicalData = useHistoricalData(
    customerId, portfolios, startDate, endDate, withHistorical, dataProvider, false, investmentStrategyId);

  const [
    dashboardData,
    dashboardDataLoading,
    dashboardDataLoadingError,
    timestamp
  ] = [
    historicalData.data,
    historicalData.loading,
    historicalData.errors,
    historicalData.updatedAt
  ]

  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    data: [],
    error: false
  });

  const [transactions, transactionTypes, showExecutionDateNote] = React.useMemo(() => {

    if (!TransactionsOverviewProps.data) {
      return [{}, [], false]
    }

    let transactions = _.cloneDeep(TransactionsOverviewProps.data);
    let _transactionTypes = [];
    let _showExecutionDateNote = false;

    if (!transactions.loading && !transactions.errors && transactions.updatedAt) {

      _transactionTypes = _.get(transactions, 'data.transaction_types') || [];
      _transactionTypes = _transactionTypes.map((type) => ({...type, id: type.code, value: type.name}))

      parseResponse(TransactionsOverviewProps.data.data, 'orders', (data) => {
        _showExecutionDateNote = _.some(data, (order) => showOrderDateNote(order, TransactionsOverviewProps.validateTransactionsExecutionDate && TransactionsOverviewProps.trackingStartDate))
        data = buildComponentsWithSubItemsDataSource(data, false, 'components');
        transactions.data = [...paginateArray(data, 10)]
        transactions.errors = undefined
      }, (errors) => {
        console.error(errors)
        transactions.errors = errors
        transactions.data = undefined
      });
    }

    if (!!transactions.errors) {
      props.dispatch(displayErrorSnackBar(TRANSACTIONS_DATA_LOADING_ERROR))
    }

    return [transactions, _transactionTypes, _showExecutionDateNote]

  }, [_.get(TransactionsOverviewProps.data, 'updatedAt')])

  React.useEffect(() => {
    if (dashboardData) {

      if (!requiredDataExist(dashboardData)) {
        return setChartData({...chartData, error: true});
      }

      let data = prepareFromDashboardData();

      if (TransactionsOverviewProps.enabled && !transactions.loading && !transactions.errors && transactions.data && transactions.updatedAt) {

        const portfolioTimeSeries = _.get(data, 'preparedData.0') || {};

        data.transactionsTimeSeries = prepareOrdersDataPoints(
          portfolioTimeSeries.portfolio || [], transactions.data || [], portfolioTimeSeries.color)
      }

      setReloadTrigger(true);
       setTimeout(() => {
         setChartData({
           data: data.preparedData,
           transactionsTimeSeries: data.transactionsTimeSeries,
           error: data.hasError,
           timestamp: new Date().getTime()
         });
         setTimeout(() => {
           setReloadTrigger(false);
         }, 100);
       }, 100);
    }
  }, [dashboardData, showInvestmentCapital, aggregateSelectedPortfolios, timestamp, transactions.updatedAt]);

  function prepareFromDashboardData() {
    // Returns chart data with or without invest capital

    let preparedData = [];

    // invest capital timeseries are attached to aggregated portfolio, which must be the first one
    let portfolioWithInvestCapital = dashboardData.portfolios.find((portfolio) => !portfolio.id);
    let investment_timeseries = portfolioWithInvestCapital && portfolioWithInvestCapital.investment_timeseries || [];

    const portfolios = dashboardData.portfolios.filter(portfolio => aggregateSelectedPortfolios
      ? portfolio.id === 0
      : portfolio.id !== 0);

    let hasError = portfolios.some((portfolio) => hasResponseError(portfolio.historical));

    if (!hasError) {
      preparedData = portfolios
      .map(portfolio => ({
        name: getPortfolioChartName(portfolio, aggregatedPortfolioName),
        color: portfolio.color,
        ...portfolio.historical
      }));

      // add invest capital if it has timeseries
      if (investment_timeseries && investment_timeseries.length && showInvestmentCapital) { // showInvestment
        let investmentData = investment_timeseries.map(value => ({
          date: value.transaction_date,
          value: value.invested_amount
        }));

        preparedData.push({
          name: 'Kapitaleinsatz',
          color: INVESTMENT_CHART_COLOR,
          portfolio: investmentData
        });
      }

    }

    return {
      hasError,
      preparedData,
    }
  }

  function requiredDataExist(dashboardData) {
    return _.isArray(dashboardData.portfolios)
      && dashboardData.portfolios.every(portfolio => !!portfolio.historical);
  }

  const isInvestCapitalEnabled = () => {
    return !!dashboardData;
  };

  const handleShowInvestmentChange = (checked) => {
    onChartSettingsChange('historical', 'withInvestiertesAmount', checked);
  }

  const handleAggregateSelectedChange = (checked) => {
    onChartSettingsChange('historical', 'aggregateSelected', checked);
  }

  let timeout = React.useRef(null);
  const onDatesChange = (dates) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      dispatch(setTimeSelectorDateChange(dates))
    }, 1000)
  }

  const minHeight = ['xs', 'sm', 'md'].includes(width) ? 520 : 486;

  const empty = chartData.data && chartData.data.every(item => item.portfolio.length === 0);

  const orders_error = dashboardData && dashboardData.portfolios.some(portfolio => portfolio.orders_error)|| false;
  const market_value_error = dashboardData && dashboardData.portfolios.some(portfolio => portfolio.market_value_error) || false;

  const getWarningMessage = () => {
    const order_error_message = getHostRelatedSetting(
      sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.HISTORICAL_CHART_ORDER_ERROR_MESSAGE_TEXT);

    const portfolio_names = dashboardData.portfolios
      .filter(portfolio => portfolio.market_value_error).reduce((names, portfolio) => {
        return `${names} \n- ${portfolio.name}`
      }, '\n');
    const market_value_error_message = getHostRelatedSetting(
      sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.HISTORICAL_CHART_MARKET_VALUE_ERROR_MESSAGE_TEXT, {
        "{{portfolios}}": portfolio_names
      });

    if (order_error_message || market_value_error_message) {
      return (
        <div>
          {orders_error && <p>{order_error_message}</p>}
          {market_value_error && <p>{market_value_error_message}</p>}
        </div>
      )
    }
  };

  const handleOrderingChange = (orderingColumn) => {
    onChartSettingsChange('transactionsOverview', 'orderingColumn', orderingColumn);
  }

  return (
    <ChartSectionBordered
      title={(
        <>
          <b>Historische Entwicklung & Kapitaleinsatz in €</b>
          <WarningTooltip
            title={<HTMLHostRelatedSharedSetting sharedSettingKey={HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_HISTORICAL_CHART_EXPLANATION} />}
            icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
            width={600}
            interactive
          />
        </>
      )}
      titleControl={(
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item>
            <AggregatedDepotsSwitch
              title="Kapitaleinsatz"
              disabled={!isInvestCapitalEnabled()}
              value={showInvestmentCapital}
              onChange={handleShowInvestmentChange}
            />
          </Grid>
          <Grid item>
            <AggregatedDepotsSwitch
              disabled={isCumulativeDisplaySwitchDisabled}
              value={aggregateSelectedPortfolios}
              onChange={handleAggregateSelectedChange}
              tooltip={(!dashboardDataLoading && isCumulativeDisplaySwitchDisabled) ? aggregateSelectedDisabledExplanation : undefined}
            />
          </Grid>
        </Grid>
      )}
      content={dashboardData && (
        <>
          <Grid container>
            <Grid item xs={12} md={8}>
              <_HistoricalPerformanceChart
                chartData={chartData}
                onDatesChange={onDatesChange}
                classes={classes}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {!_.isEmpty(chartData.data) && (
                <Legend data={chartData.data}/>
              )}
            </Grid>
          </Grid>
          {(orders_error || market_value_error) && (
            <div style={{marginTop: '1.5em'}}>
              <p className={classes.footerText}>{getWarningMessage()}</p>
            </div>
          )}
        </>
      )}
      extraContent={TransactionsOverviewProps.enabled && (
        <DashboardChartSection
          title={"Transaktionsübersicht"}
          loading={transactions.loading}
          error={transactions.errors}
          displayError={true}
          expanded={TransactionsOverviewProps.expanded}
          onExpanded={TransactionsOverviewProps.onExpanded}
          content={(
            <TransactionsMonitoringContext.Provider value={{
              onOrderingChange: handleOrderingChange,
              dataProvider: TransactionsOverviewProps.dataProvider,
              transactions: transactionTypes,
              customerId: TransactionsOverviewProps.customerId
            }}>
              <Transactions loading={transactions.loading} transactions={transactions.data || []} errors={transactions.errors}>
                <Transactions.List
                  isGrouped
                  tableStructure={tableStructureMinimised}
                  tableClasses={classes}
                  tableOptions={{
                    viewOnly: TransactionsOverviewProps.editable,
                    onDeleteClick: TransactionsOverviewProps.onDeleteClick,
                    onEditClick: TransactionsOverviewProps.onEditClick,
                    deleteInProgress: TransactionsOverviewProps.deleteInProgress,
                    editInProgress: TransactionsOverviewProps.editInProgress,
                    onAllSelect: TransactionsOverviewProps.onAllSelect,
                    onSelect: TransactionsOverviewProps.onSelect,
                    selected: TransactionsOverviewProps.selected,
                    totalSelectableTransactionsCount: TransactionsOverviewProps.totalSelectableTransactionsCount,
                    trackingStartDate: TransactionsOverviewProps.validateTransactionsExecutionDate && TransactionsOverviewProps.trackingStartDate
                  }}
                />
              </Transactions>
              {showExecutionDateNote && (
                <p style={{fontSize: 16, marginTop: 15}}>*An diesem Datum lagen erstmals Preisdaten für das Produkt vor.</p>
              )}
            </TransactionsMonitoringContext.Provider>
          )}
          customClasses={{
            container: classes.transactionsContainer,
            contentContainer: classes.contentContainer
          }}
        />
      )}
      loading={dashboardDataLoading || reloadTrigger}
      error={dashboardDataLoadingError || chartData.error}
      minHeight={minHeight}
      empty={empty}
      displayError={true}
      expanded={expanded}
      onExpanded={onExpanded}
    />
  );
};

HistoricalPerformanceChart.defaultProps = {
  TransactionsOverviewProps: {}
}

export default connect(mapStateToProps)(
  withWidth()(
    withStyles(styles, {withTheme: true})(HistoricalPerformanceChart)));
