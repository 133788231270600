import React from "react";
import {connect} from "react-redux";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import {ArrowBack} from "@material-ui/icons";

import styles from "../styles";
import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import StepFooter from "../../StepFooter/StepFooter";
import {
  INSTRUMENT_SOURCE_TYPE,
  INSTRUMENTS_STEP_STATE
} from "../../../../../constants";
import InvestmentRecommendationStep from "./components/InvestmentRecommendationStep";
import clsx from "clsx";
import MusterDepot from "./components/InvestmentRecommendationStep/components/MusterDepot";
import FavoriteListSelection from "./components/InvestmentRecommendationStep/components/FavoriteListSelection";
import {
  EinzeltitelTradingInstrumentsHandler,
  getContractTypeConfiguration, getRiskProfilingAnswers,
  getRiskScoreConfirmation,
  InvestmentStrategyHandler,
  ModelPortfolioHandler
} from "../../../../../utils";
import {
  displayErrorSnackBar,
  displaySuccessSnackBar,
  displayWarningSnackBar
} from "../../../../../../../components/SnackbarProvider/actions";
import ModelPortfolioSelection from "./components/InvestmentRecommendationStep/components/ModelPortfolioSelection";
import {useConfirmationModalContext} from "../../../../../../../components/ConfirmationModalContextProvider";
import {CircularProgress} from "@material-ui/core";
import { getErrorMessage, getSubSystemConfigItem } from '../../../../../../../utils/utils';
import GoogleAnalytics from '../../../../../../../utils/GoogleAnalytics';
import InvestmentStrategySelection from "./components/InvestmentRecommendationStep/components/InvestmentStrategySelection/InvestmentStrategySelection";
import {SERVICE_CONCEPTS} from "../../../../../../Trades/constants";
import {
  MUSTERDEPOT_ALL_ASSETS_AVAILABLE, MUSTERDEPOT_NOT_ALL_ASSETS_AVAILABLE
} from "./components/InvestmentRecommendationStep/components/MusterDepot/constants";
import PortfolioBuilder from './components/InvestmentRecommendationStep/components/PortfolioBuilder';
import PortfolioBuilderInfoText
  from './components/InvestmentRecommendationStep/components/PortfolioBuilder/components/PortfolioBuilderInfoText';

const ProductSelectionStep = props => {
  const {
    classes,
    custom_classes,
    uid,
    name,
    questions: [
      instrumentsQuestion,
      sourceQuestion,
      portfolioBuilderQuestion,
    ],
    help_text,
    info_text,
    isFirst,
    onNextClick,
    onPrevClick,
    onAnswerChange,
    dataService,
  } = props;

  const [step, setStep] = React.useState(INSTRUMENTS_STEP_STATE.MAIN);
  const [activeSource, setActiveSource] = React.useState(null);
  const [activeSourceItem, setActiveSourceItem] = React.useState(null);
  const [selectedInstruments, setSelectedInstruments] = React.useState([]);
  const [selectedDepot, setDepot] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const modalContext = useConfirmationModalContext();
  const [instrumentsHandler, setInstrumentsHandler] = React.useState(null);
  const [portfolioBuilderActive, setPortfolioBuilderActive] = React.useState(false);

  React.useEffect(() => {

    (async () => {
      setLoading(true);
      await dataService.syncDepotTypeInfo()
      const depot = dataService.getDepot(true);
      setDepot(depot);

      const depositStepData = dataService.getInvestedAmountData();
      const fundsDisabled = !depositStepData.single_investment_enabled
        && !depositStepData.vl_plan_enabled
        && !depositStepData.savings_plan_enabled;

      const handler = new EinzeltitelTradingInstrumentsHandler(
        depot.bankCompanyId,
        dataService.current_customer && dataService.current_customer.last_srri && dataService.current_customer.last_srri.srri,
        dataService._target_market,
        !depositStepData.einzeltitel_enabled,
        depositStepData.vl_plan_enabled,
        dataService.bank,
        dataService.user && dataService.user.bca_level_id,
        dataService.serviceConcept,
        getRiskProfilingAnswers(dataService, dataService.bank && dataService.bank.available_asset_classes || {}),
        fundsDisabled)
      setInstrumentsHandler(handler);

      validateInstrumentsOnInit().then((instruments) => {
        onAnswerChange(instruments)
      }).catch((error) => {
        props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
      }).finally(() => {
        setLoading(false)
      })
    })()

  }, []);

  const summaryStep = React.useMemo(() => {
    return dataService.getStep("product-selection-summary")
  }, []);


  const _validateInstrumentsSource = async (source, handler, errorMessage) => {

    const [validSource, _] = handler.filterInstruments([source])
    if (!validSource.length) {
      return errorMessage
    }

  }

  const _validateInstruments = async (instruments, bankCompanyId, vlPlanEnabled) => {

    const depositStepData = dataService.getInvestedAmountData();
    const fundsDisabled = !depositStepData.single_investment_enabled
      && !depositStepData.vl_plan_enabled
      && !depositStepData.savings_plan_enabled;

    const handler = new EinzeltitelTradingInstrumentsHandler(
      bankCompanyId,
      dataService.current_customer && dataService.current_customer.last_srri && dataService.current_customer.last_srri.srri,
      dataService.targetMarket,
      !depositStepData.einzeltitel_enabled,
      vlPlanEnabled,
      dataService.bank,
      dataService.user && dataService.user.bca_level_id,
      dataService.serviceConcept,
      getRiskProfilingAnswers(dataService, dataService.bank && dataService.bank.available_asset_classes || {}),
      fundsDisabled)

    await handler.getCustodianAndFilterInstruments(instruments)

  }

  const _getInstrumentsHandlingParams = (source, instruments) => {

    const depositStepData = dataService.getInvestedAmountData()
    const depot = dataService.getDepot(true);

    const availableInstrumentsSources = getContractTypeConfiguration(
      depot && depot.selected_contract_type || {},
      dataService.isOpenOnly ? 'open_only_instrument_sources' : 'instrument_sources',
      [])

    return {
      "Modellportfolio": {
        sourceTypeValid: availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.MODEL_PORTFOLIO),
        validateInstrumentsSource: async () =>  {
          await _validateInstruments(
            instruments, depot.bankCompanyId, depositStepData.vl_plan_enabled)
          await _validateInstrumentsSource(
            source, new ModelPortfolioHandler(),
            'Die ausgewählte das Modellportfolio ist nicht zulässig!')
        }
      },
      "Vermögensverwaltung": {
        sourceTypeValid: availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.INVESTMENT_STRATEGY),
        validateInstrumentsSource: async () => {
          await _validateInstruments(
            instruments, depot.bankCompanyId, depositStepData.vl_plan_enabled)
          await _validateInstrumentsSource(
            source, new InvestmentStrategyHandler(undefined, dataService._target_market),
            'Die ausgewählte Vermögensverwaltung ist nicht zulässig!')
        }
      }
    }[source && source.sourceType] || {
      sourceTypeValid: availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.SEARCH),
      validateInstrumentsSource: async () => await _validateInstruments(
        instruments, depot.bankCompanyId, depositStepData.vl_plan_enabled)
    }

  }

  /**
   * Validate selected instruments. Should be called when go back,
   * or page reload (componentDidMount)
   */
  const validateInstrumentsOnInit = async () => {

    // check all instruments for custodian availability
    const instruments = [...(instrumentsQuestion.answer || [])];
    const instrumentsSource = sourceQuestion.answer

    // clean prev data
    instruments.map(item => {
      delete item.custodian_data;
      delete item.disabledByFilter;
      delete item.disabledByAcceptanceCriterias;
    });

    const instrumentsHandlingParams = _getInstrumentsHandlingParams(
      instrumentsSource, instruments)

    const individualInstrumentSource = !instrumentsSource || ["MusterDepot", "FavouriteList"].includes(instrumentsSource.sourceType)

    if (!instrumentsHandlingParams.sourceTypeValid) {
      instrumentsQuestion.error = instrumentsQuestion.disabledByFilter =  individualInstrumentSource ? "Für den gewählten Produkttyp besteht nicht die Möglichkeit individuelle Instrumenten auszuwählen." : `Der ausgewählte Produkttyp kann nicht in ${instrumentsSource && instrumentsSource.sourceType} verwendet werden.`
    } else {
      instrumentsQuestion.error = instrumentsQuestion.disabledByFilter = await instrumentsHandlingParams.validateInstrumentsSource()
    }

    return instruments

  }

  const handleSourceClick = (source) => () => {
    setActiveSource(source);
    setStep(INSTRUMENTS_STEP_STATE.LIST);
  };

  const openPortfolioBuilder = () =>  {
    setPortfolioBuilderActive(true);
    GoogleAnalytics.trackPortfolioBuilderOpen();
  };

  const setPortfolioBuilderCase = (caseData) => {
    onAnswerChange(portfolioBuilderQuestion.uid, caseData);
  };

  const availableInstrumentsSources = React.useMemo(() => {

    if (!selectedDepot || !selectedDepot.selected_contract_type) {
      return []
    }

    return getContractTypeConfiguration(
      selectedDepot.selected_contract_type,
      dataService.isOpenOnly ? 'open_only_instrument_sources' : 'instrument_sources',
      [])

  }, [selectedDepot])

  // indicates if it's individual instruments selection
  const isInstrumentsStep = () => {
    return (['FavouriteList', 'MusterDepot'].includes(activeSource));
  };


  const handleSaveClick = async (nextStep, sourceItem, hasWarning) => {
    // Use assets directly from sourceItem in case it provided from details page
    let newItems = [...(sourceItem ? sourceItem.assets : selectedInstruments)],
        sourceQuestionAnswer = null,
        existingInstruments = instrumentsQuestion.answer,
        msg = "Die Instrumente wurde der Produktauswahl hinzugefügt.";

    if(activeSource === "MusterDepot"){
      msg = hasWarning ? MUSTERDEPOT_NOT_ALL_ASSETS_AVAILABLE : MUSTERDEPOT_ALL_ASSETS_AVAILABLE
    }

    const isInvestmentStrategy = _.get(sourceItem || activeSourceItem, 'isInvestmentStrategy');

    // if there is no source or source requires validation - check if risk score confirmation required
    // if source is PI we validate it sri instead of assets inside.
    const riskScoreConfirmation = getRiskScoreConfirmation(
      dataService, !isInvestmentStrategy ? newItems : [sourceItem || activeSourceItem],
      undefined, !isInvestmentStrategy ? (asset) => asset.sri : (strategy) => strategy.srri)

    // wait for user to confirm risk score or stop process
    if (riskScoreConfirmation && !await modalContext.confirm(riskScoreConfirmation, 'Bitte bestätigen Sie hier')) {
      return false
    }
    // if there were answers - confirm that they will be overwritten or stop process
    if(!!sourceQuestion.answer) {
      if(!await modalContext.confirm("Sind Sie sicher? Ihre aktuelle Auswahl wird durch die neue Auswahl ersetzt.")) {
        return;
      }

      existingInstruments = []; // override model portfolio instruments
    }
    // if active source is FavouriteList or MusterDepot and there are existingInstruments -> add source instruments to existing
    if(isInstrumentsStep()){
      if(!_.isEmpty(existingInstruments)){
        // add new items to existing
        newItems = _.uniqBy([...existingInstruments, ...newItems], 'isin');
      }
    } else {
      // if active source is Invest strategy or model portfolio | no active source for PortfolioBuilder -
      // confirm that existingInstruments will be overwritten or stop process
      if(!_.isEmpty(existingInstruments) && !await modalContext.confirm("Sind Sie sicher? Ihre aktuelle Auswahl wird durch die neue Auswahl ersetzt.")) {
        return;
      }

      if (activeSource) {
        sourceQuestionAnswer = {...(sourceItem || activeSourceItem), sourceType: activeSource};
        if (activeSource === "Modellportfolio")
          msg = "Das Modellportfolio wurde der Produktauswahl hinzugefügt.";
        else
          msg = "Die Vermögensverwaltung wurde der Produktauswahl hinzugefügt.";
      }
    }

    instrumentsQuestion.error = instrumentsQuestion.disabledByFilter = null; // clean error
    onAnswerChange(instrumentsQuestion.uid, newItems);
    onAnswerChange(sourceQuestion.uid, sourceQuestionAnswer);
    setStep(nextStep);
    cleanSourceItem();
    // clean summary answers
    summaryStep && summaryStep.question.forEach(q => q.answer = null);
    // set proper snackbar
    let displaySnackBar = hasWarning ? displayWarningSnackBar : displaySuccessSnackBar;
    props.dispatch(displaySnackBar(msg));
  };

  const cleanSourceItem = () => {
    setActiveSourceItem(null);
    setSelectedInstruments([]);
  };

  const isFormStep = () => {
    return step === INSTRUMENTS_STEP_STATE.ADD_FORM;
  }

  const handleBackClick = () => {
    if (step === INSTRUMENTS_STEP_STATE.ADD_FORM) {
      cleanSourceItem();
      return setStep(INSTRUMENTS_STEP_STATE.LIST);
    }

    if (step === INSTRUMENTS_STEP_STATE.LIST) {
      cleanSourceItem();
      setActiveSource(null);
      return setStep(INSTRUMENTS_STEP_STATE.MAIN);
    }

    return onPrevClick();
  }

  const handleNextClick = () => {
    if (isFormStep()) {
      return handleSaveClick(INSTRUMENTS_STEP_STATE.MAIN);
    }

    return onNextClick();
  };

  const handleAdditionalNextClick = () => {
    handleSaveClick(INSTRUMENTS_STEP_STATE.LIST);
  };

  const getNextButtonMessage = () => {
    if (step === INSTRUMENTS_STEP_STATE.MAIN) {
      return 'Speichern & fortfahren';
    }

    if(isInstrumentsStep()) {
      return 'Hinzufügen und zurück zur Produktauswahl';
    } else {
      if(activeSource === "Modellportfolio")
        return 'Modellportfolio hinzufügen'
      else
        return 'Vermögensverwaltung hinzufügen'
    }
  }

  const getAdditionalNextButtonMessage = () => {
    if(activeSource === "FavouriteList") {
      return 'Hinzufügen und zurück zu Favoritenlisten';
    }
    return 'Hinzufügen und zurück zu Musterdepots'
  }

  const getNextButtonDisabled = () => {
    if (step === INSTRUMENTS_STEP_STATE.ADD_FORM){
      return selectedInstruments.length === 0;
    }

    return false;
  };

  const getLeftButtonItems = () => {
    if (isFirst && step === INSTRUMENTS_STEP_STATE.MAIN) {
      return [];
    }

    return [BackButton];
  }

  const getRightButtonItems = () => {
    if (step === INSTRUMENTS_STEP_STATE.LIST) {
      return [];
    }

    if (isInstrumentsStep() && step !== INSTRUMENTS_STEP_STATE.MAIN) {
      return [AdditionalNextButton, NextButton]
    }

    return [NextButton]
  }

  const BackButton = (
    <PrimaryButton
      text={"Zurück"}
      icon={<ArrowBack color={'primary'}/>}
      variant={"outlined"}
      onButtonClick={handleBackClick}
    />
  );

  const AdditionalNextButton = (
    <PrimaryButton
      text={getAdditionalNextButtonMessage()}
      onButtonClick={handleAdditionalNextClick}
      disabled={getNextButtonDisabled()}
    />
  );

  const NextButton = (
    <PrimaryButton
      text={getNextButtonMessage()}
      onButtonClick={handleNextClick}
      disabled={getNextButtonDisabled()}
    />
  );

  /**
   * Handler for onDetailsClick event.
   * @param item Selected item
   * @param skipDetails Flag, that indicate if Item Details screen should be shown
   */
  const handleDetailsClick = (item, skipDetails, hasWarning) => {
    setActiveSourceItem(item);
    if (!skipDetails) {
      setStep(INSTRUMENTS_STEP_STATE.ADD_FORM);
    } else {
      handleSaveClick(INSTRUMENTS_STEP_STATE.MAIN, item, hasWarning)
    }
  };

  const onInstrumentSelect = (item) => {
    let selectedAssetsCopy = [...selectedInstruments];

    const idx = selectedInstruments.findIndex(i => i.isin === item.isin);
    if (idx !== -1) {
      selectedAssetsCopy.splice(idx, 1);
    } else {
      selectedAssetsCopy = [...selectedAssetsCopy, item]
    }

    setSelectedInstruments(selectedAssetsCopy);
  };

  const setPortfolioBuilderRecommendations = (assets) => {
    // NOTE: as instruments are added to Portfolio component on Init - we should re-render it only after assets added
    handleSaveClick(INSTRUMENTS_STEP_STATE.MAIN, {assets: assets})
      .finally(() => setPortfolioBuilderActive(false));

    GoogleAnalytics.trackPortfolioBuilderResults();
  };

  const depositStepData = dataService.getInvestedAmountData();

  const renderComponent = () => {
    const Component = {
      "MusterDepot": MusterDepot,
      "FavouriteList": FavoriteListSelection,
      "Modellportfolio": ModelPortfolioSelection,
      "Vermögensverwaltung": InvestmentStrategySelection
    }[activeSource];

    return !!Component ?
      <Component
        depot={selectedDepot}
        dataService={dataService}
        onDetailsClick={handleDetailsClick}
        instrumentsHandler={instrumentsHandler}
        /* for MP/PI there should be no instruments selection */
        onInstrumentSelect={!['Modellportfolio', 'Vermögensverwaltung'].includes(activeSource) && onInstrumentSelect}
        onSelectAll={setSelectedInstruments}
        selectedItem={activeSourceItem}
        selectedInstruments={selectedInstruments}
      /> : null
  };

  if (loading){
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingIndicator}/>
      </div>
    );
  }

  const getAvailableSources = () => {
    const options = [
      {
        text: "Favoritenlisten",
        variant: "outlined",
        onButtonClick: handleSourceClick("FavouriteList"),
        disabled: !availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.FAVOURITE_LIST)
      }, {
        text: "Musterdepot",
        variant: "outlined",
        onButtonClick: handleSourceClick("MusterDepot"),
        disabled: !availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.MUSTER_DEPOT) || depositStepData.vl_plan_enabled
      }, {
        text: "Vermögensverwaltung",
        variant: "outlined",
        onButtonClick: handleSourceClick("Vermögensverwaltung"),
        disabled: !availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.INVESTMENT_STRATEGY) || depositStepData.vl_plan_enabled
      }, {
        text: "Modellportfolio",
        variant: "outlined",
        onButtonClick: handleSourceClick("Modellportfolio"),
        disabled: !availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.MODEL_PORTFOLIO)
          || dataService.serviceConcept === SERVICE_CONCEPTS.ExecutionOnly
          || depositStepData.vl_plan_enabled
      }
    ];

    if(getSubSystemConfigItem('onboarding', 'portfolio_builder_enabled')){
      let prtBuilderDisableCriteria = [];

      if(!availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.PORTFOLIO_BUILDER)) {
        prtBuilderDisableCriteria.push('Depotstelle')
      }
      if(dataService.serviceConcept !== SERVICE_CONCEPTS.Anlageberatung) {
        prtBuilderDisableCriteria.push('Servicekonzept: Anlageberatung')
      } else if(!dataService.hasInvestmentFundsKnowledge(dataService.serviceConcept)) {
        prtBuilderDisableCriteria.push('fehlende Kenntnisse: Offene Investmentfonds')
      }
      if(!depositStepData.single_investment) {
        prtBuilderDisableCriteria.push('Einmalanlage fehlt')
      }

      const prtBuilderDisabled = !_.isEmpty(prtBuilderDisableCriteria);

      options.push({
        text: "Portfolio-Builder",
        variant: "outlined",
        onButtonClick: openPortfolioBuilder,
        disabled: prtBuilderDisabled,
        tooltip: prtBuilderDisabled
          ? <>{prtBuilderDisableCriteria.map((c, i) => <li key={i}>{c}</li>)}</>
          : <PortfolioBuilderInfoText />,
      })
    }

    return options;
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepScrollContainer}>
        <div className={clsx(classes.stepContent, 'app-page-container')}>
          {step === INSTRUMENTS_STEP_STATE.MAIN && (
            <>
              {name && (
                <div className={clsx(classes.stepName, custom_classes && custom_classes.stepName)} data-id={`${uid}-name`}>
                  {name}
                </div>
              )}
              {info_text && (
                <div className={classes.stepInfoText} data-id={`${uid}-info`}>
                  <span className='far fa-info-circle' />
                  &nbsp;
                  {info_text}
                </div>
              )}
              {help_text && (
                <div className={classes.stepHelpText} data-id={`${uid}-help`}>
                  {help_text}
                </div>
              )}
              {portfolioBuilderActive ? (
                <PortfolioBuilder
                  dataService={dataService}
                  investmentAmount={depositStepData.single_investment}
                  caseData={portfolioBuilderQuestion.answer || {}}
                  setCaseData={setPortfolioBuilderCase}
                  setRecommendations={setPortfolioBuilderRecommendations}
                  closeHandler={() => setPortfolioBuilderActive(false)}
                  instrumentsHandler={instrumentsHandler}
                />
              ) : (
                <InvestmentRecommendationStep
                  questions={props.questions}
                  onAnswerChange={onAnswerChange}
                  dataService={dataService}
                  instrumentsSearchDisabled={!availableInstrumentsSources.includes(INSTRUMENT_SOURCE_TYPE.SEARCH)}
                  instrumentsHandler={instrumentsHandler}
                  instrumentsSources={getAvailableSources()}
                />
              )}
            </>
          )}
          {step !== INSTRUMENTS_STEP_STATE.MAIN && renderComponent()}
        </div>
        <div className={classes.scrollFix} />
      </div>

      <div id={'step_footer_buttons'}>
        <StepFooter
          leftItems={getLeftButtonItems()}
          rightItems={getRightButtonItems()}
          />
      </div>
    </div>
  );
};

export default connect()(withStyles(styles)(ProductSelectionStep));
