import React from 'react';
import _ from 'lodash';
import moment from "moment";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import ChartSectionBordered from "../components_v2/ChartSectionBordered/ChartSectionBordered";
import DashboardInfoTooltip from "../components_v2/DasboardInfoTooltip";
import useStyles from './styles';
import {useDepotFinancialData} from "../hooks/useDepotFinancialData";
import {
  getErrorMessage, getSubSystemConfigItem,
  isExpertSectionVisible,
  isSectionVisible,
  withEuroOrDash,
  withPercentOrDash
} from '../../../utils/utils';
import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {useCustomerESGProfileData} from "../hooks/useCustomerEsgProfileData";
import {useOtherAssetsData} from "../hooks/useOtherAssetsData";
import {
  ASSET_CATEGORIES_COLORS,
  ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT
} from "../../OtherAssets/components/AssetsList/components/AssetsCategories/constants";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Link from "../../../components/Link";
import {OtherAssetsModal} from "../../OtherAssets/OtherAssets";
import {openAssetModal} from "../../../components/AssetModal/actions";
import {connect} from "react-redux";
import images from '../../../images';
import { getRiskType } from '../../RiskDashboard/utils';
import { RISK_FILTERING_TYPE } from '../../../components/FilteringPanel/components/RiskFilter/constants';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils';
import EsgSlide from '../components_v2/EsgSlide';
import { ESG_INFO_TEXT, OKO_INFO_TEXT, PAI_INFO_TEXT } from '../../FactSheetsPage/constants';
import { toGermanFormat } from '../../../utils/numberFormater';
import { getInvestmentPlatformSelector } from '../../../utils/redaxSelectors';
import Skeleton from '@material-ui/lab/Skeleton';
import {ErrorOutline} from "@material-ui/icons";
import ReactDOMServer from 'react-dom/server';
import {useUnrealizedProfitAndLossData} from "../hooks/useUnrealizedProfitAndLoss";
import {getDataRetrievingParamsFromProps, getSharedSettingsWarning, getSharedSettingsSelector} from "../utils";
import { DATE_FORMAT } from '../../../utils/constants';
import useAggregatedReturnSectionStyles
  from '../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection/styles';
import ReturnValue
  from '../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection/components/ReturnValue';
import AggregatedPortfolioReturnSection from '../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection';
import ReturnHeader
  from '../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection/components/ReturnHeader';
import Resource from "../../../utils/api";
import {displayErrorSnackBar} from "../../../components/SnackbarProvider/actions";
import WarningTooltip from "../../../components/WarningTooltip";


// region Single Portfolio Details
const useSummarySectionValuesStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  },
  cell: {
    textAlign: 'right'
  },
  cellEmpty: {
    textAlign: 'center'
  }
}));


const PortfolioSummarySection = React.memo(({customerId, portfolio, startDate, endDate, dataProvider, reportType, customSettings, sharedSettings}) => {

  const [data] = useDepotFinancialData(customerId, [portfolio], startDate, endDate, false, undefined, dataProvider);

  const classes = useSummarySectionValuesStyles();
  const isEmpty = portfolio.is_empty;
  const cellClass = isEmpty ? classes.cellEmpty : classes.cell;

  const renderSectionValue = (dataKey, field) => {
    const sectionData = _.get(data, `data.data.${dataKey}`);
    const value = _.get(sectionData, field);
    const warning = getSharedSettingsWarning(_.get(sectionData, `${field}_warning`), sharedSettings.data);

    const valueHandler = {
      'percentage': withPercentOrDash,
      'value': withEuroOrDash,
      'percentage_pa': withPercentOrDash
    }[field];

    return <div className={cellClass}>
      {!!warning
        ? <>- <WarningTooltip title={warning}/></>
        : isEmpty ? '-' : <ReturnValue value={value} valueHandler={valueHandler} />}
    </div>
  };

  const renderTitle = () => {
    const name = (<b>{portfolio.name}</b>);
    if (!data.data) {
      return name;
    }

    const depotValue = _.get(data, 'data.data.aum.aum_value') || 0;
    return (
      <div>
        {name} <br />
        <b>{withEuroOrDash(depotValue)}</b>
      </div>
    )
  };

  return (
    <ChartSectionBordered
      title={renderTitle()}
      isPortfolioSection
      borderLeftColor={portfolio.color}
      expanded
      subSection
      loading={data.loading}
      error={data.error}
      displayError
      content={reportType != REPORT_TYPES.DEPOT_STATUS.value ? (
        <Grid container spacing={4}>
          <Grid item>
            <div>&nbsp;</div>
            <div className={classes.title}>Ausgewählter Zeitraum</div>
            <div className={classes.title}>Seit Investmentbeginn</div>
          </Grid>
          {isSectionVisible(customSettings, 'profit_perc', reportType) && (
            <Grid item>
              <div className={clsx(classes.title, cellClass)}>GuV (%)</div>
              {renderSectionValue('ydt', 'percentage')}
              {renderSectionValue('ysb', 'percentage')}
            </Grid>
          )}
          {isSectionVisible(customSettings, 'profit_amount', reportType) && (
            <Grid item>
              <div className={clsx(classes.title, cellClass)}>GuV (€)</div>
              {renderSectionValue('ydt', 'value')}
              {renderSectionValue('ysb', 'value')}
            </Grid>
          )}
          {isSectionVisible(customSettings, 'profit_pa', reportType) && (
            <Grid item>
              <div className={clsx(classes.title, cellClass)}>GuV p.a. (%)</div>
              {renderSectionValue('ydt', 'percentage_pa')}
              {renderSectionValue('ysb', 'percentage_pa')}
            </Grid>
          )}
        </Grid>
      ) : null}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.customerId == nextProps.customerId
    && JSON.stringify(prevProps.portfolio) == JSON.stringify(nextProps.portfolio)
    && prevProps.startDate == nextProps.startDate
    && prevProps.endDate == nextProps.endDate
    && prevProps.reportType == nextProps.reportType;
});
// endregion

function ESGProfileSection({customerId, portfolios, aggregatedPortfolioName}) {

  const data = useCustomerESGProfileData(customerId, portfolios, aggregatedPortfolioName);
  const esgData = _.get(data.data, 'esg_profile_matching');
  const dataError = data.error;
  const hasProfile = !_.isEmpty(esgData);

  return (
    <ChartSectionBordered
      title={<b>Nachhaltigkeitsprofil</b>}
      titleControl={!dataError &&
        <div style={{alignContent: 'center'}}>
          {data.loading
            ? <Skeleton width={111} />
            : <>Erfüllungsgrad<DashboardInfoTooltip
              title={hasProfile
                ? "Der Wert auf der linken Seite zeigt den Erfüllungsgrad des Portfolios in Prozent an. Der Wert auf der rechten Seite gibt die prozentuale Vorgabe des Kunden aus dem Nachhaltigkeitsprofil an."
                : "Kein Nachhaltigkeitsprofil vorhanden. Ohne ein Nachhaltigkeitsprofil kann der Erfüllungsgrad nicht angezeigt werden."
              }/></>
          }
        </div>
      }
      expanded
      subSection
      error={dataError}
      displayError
      content={<ESGProfileCard esgData={esgData} loading={data.loading} />}
    />
  )
}

const RiskProfileCard = ({customerRisk, productRisk, loading}) => {
  const classes = useStyles();

  let [label, color] =
    customerRisk ?
      productRisk ? {
        [RISK_FILTERING_TYPE.GREEN.value]: [<><i className={"far fa-check-circle"}/> Angemessenes Risiko</>, 'green'],
        [RISK_FILTERING_TYPE.RED.value]: [<><i className={"far fa-exclamation-triangle"}/> Zu hohes Risiko</>, 'red'],
        [RISK_FILTERING_TYPE.YELLOW.value]: [<><i className={"far fa-exclamation-triangle"}/> Leicht überschrittenes Risiko</>, 'yellow']
      }[getRiskType(customerRisk, productRisk).value]
      : [<><i className={"far fa-question-circle"}/> Unbekannt</>, 'grey']
    : ['Kein Kundenrisikoprofil vorhanden', 'grey'];

  const formatNumber = (value) => value > 0 ? toGermanFormat(value, undefined, undefined, 2, true) : '-';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? <Skeleton height={52} variant="rounded" /> :
          <div className={clsx(classes.indicatorCard, color)}>
            <span className={classes.hugeNumber}>{label}</span>
          </div>
        }
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <div>Max. ø Portfoliorisiko</div>
          <div className={classes.hugeNumber}>{loading ? <Skeleton width={50}/> : formatNumber(productRisk)}</div>
        </Grid>
        <Grid item xs={6}>
          <div>Risikoklasse Kunde</div>
          <div className={classes.hugeNumber}>{loading ? <Skeleton width={50}/> : formatNumber(customerRisk)}</div>
        </Grid>
      </Grid>
    </Grid>
  )
};

const ESGProfileCard = ({esgData, loading}) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <EsgSlide
        categoryData={_.get(esgData, 'eco_sustainability')}
        title={<>Ökologisch-nachhaltig<DashboardInfoTooltip title={OKO_INFO_TEXT}/></>}
        loading={loading} />
    </Grid>
    <Grid item xs={12}>
      <EsgSlide
        categoryData={_.get(esgData, 'esg')}
        title={<>ESG-Kriterien<DashboardInfoTooltip title={ESG_INFO_TEXT}/></>}
        loading={loading} />
    </Grid>
    <Grid item xs={12}>
      <EsgSlide
        categoryData={_.get(esgData, 'pai')}
        title={<>PAI-Nachhaltigkeitsfaktoren<DashboardInfoTooltip title={PAI_INFO_TEXT}/></>}
        loading={loading} />
    </Grid>
  </Grid>
);

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state),
  sharedSettings: getSharedSettingsSelector(state),
});

const useEmptyOtherAssetsStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto-Regular'
  },
  chartPlaceholder: {
    width: 150,
    height: 150,
    border: '20px solid #f2f5f9',
    borderRadius: '50%'
  }
}))

function EmptyOtherAssets({handleAddNewAsset}) {
  const classes = useEmptyOtherAssetsStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.wrapper}>
        <img src={images.pie_chart_placeholder} />
      </Grid>
      <Grid item xs={12} className={classes.wrapper}>
        <span>Keine weiteren Vermögenswerte vorhanden</span>
      </Grid>
      <Grid item xs={12} className={classes.wrapper}>
        <Link
          title={"Vermögenswerte hinzufügen"}
          icon={<i className="far fa-plus"></i>}
          onClick={handleAddNewAsset}
        />
      </Grid>
    </Grid>
  )
}

const useOtherAssetsTotalStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto-Regular'
  },
  value: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#eceef1',
    padding: 10,
    borderRadius: '10px'
  },
}));

function OtherAssetsTotal({value}) {
  const classes = useOtherAssetsTotalStyles();
  return (
    <div className={classes.wrapper}>
      <span className={classes.value}>{withEuroOrDash(value)}</span>
    </div>
  )
}

const useOtherAssetsRatingStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontFamily: 'Roboto-Bold',
    fontSize: 12
  },
  line: {
    justifyContent: 'space-between',
    fontFamily: 'Roboto-Bold',
    fontSize: 14
  }
}))

function OtherAssetsRating({otherAssets}) {
  const classes = useOtherAssetsRatingStyles();

  if (_.isEmpty(otherAssets)) return null;

  return (
    <Grid container>
      {otherAssets.map((asset) => (
        <Grid item xs={12}>
          <Grid container className={classes.line} style={{color: asset.color}}>
            <Grid item>{asset.title}</Grid>
            <Grid item>{withEuroOrDash(asset.total_value)}</Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

const OtherAssetsChart = React.memo(({otherAssets, updatedAt}) => {
  return (
    <PieChart
      highcharts={Highcharts}
      options={{
        chart: {
          type: 'pie',
          height: 240,
          margin: 0
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            return `<b>${this.point.name}:</b> ${this.point.amount}`
          },
        },
        plotOptions: {
          pie: {
            size: "100%",
          },
        },
        series: [{
          type: "pie",
          innerSize: '60%',
          data: otherAssets.map((asset) => ({
            name: asset.title,
            color: asset.color,
            y: asset.weight < 0.01 ? 0.01 : asset.weight, // use 1 % for category if it is less then 1%
            icon: asset.icon,
            amount: withEuroOrDash(asset.total_value)
          })),
          dataLabels: {
            connectorWidth: 0,
            formatter: function() {
              if (this.point.icon && this.point.y > 0.05) { // don't show icon if category is less then 5% as it won't fit
                return ReactDOMServer.renderToString(<span style={{fontSize: 20}}>{this.point.icon}</span>); //icon
              }
            },
            padding: 0,
            color: 'white',
            crop: false,
            distance: -20,
            useHTML: true,
            overflow: 'allow'
          }
        }]
      }}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.updatedAt == nextProps.updatedAt;
});

const OtherAssetsSection = React.memo(connect()(({customerId, portfolios, startDate, endDate, dispatch, onUpdate}) => {

  const classes = useStyles();

  const [data, fetchOtherAssetsData] = useOtherAssetsData(customerId, portfolios, startDate, endDate);

  const [otherAssetsModalOpen, setOtherAssetsModalOpen] = React.useState(false);

  const useUnity = (getSubSystemConfigItem('reporting', 'useUnityForOtherAssets') || []).includes(customerId);

  const jumpToUnity = () => {
    new Resource('cios/').at('sst-token/').get({customer_id: customerId})
      .then((res) => {
        window.open(res.detail)
      }).catch((err) => dispatch(displayErrorSnackBar(getErrorMessage(err))));
  };

  const openDetails = useUnity ? jumpToUnity : () => {
    setOtherAssetsModalOpen(true);
  };

  const handleAddNewAsset = useUnity ? jumpToUnity : () => {
    dispatch(openAssetModal())
  };

  const getContent = () => {
    if (!data.data) return null;

    const totalOtherAssetsValue = data.data.total_value;
    const otherAssets = _.orderBy(Object.entries(data.data.assets || {})
      .map(([otherAssetType, otherAssetData], index) => ({
        title: ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT[otherAssetType].title,
        color: ASSET_CATEGORIES_COLORS[index],
        icon: ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT[otherAssetType].reactIcon,
        weight: otherAssetData.total_value / totalOtherAssetsValue,
        ...otherAssetData
      })), ['total_value'], ['desc']);

    return (
      <>
        {_.isEmpty(data.data.assets) ? (
          <EmptyOtherAssets handleAddNewAsset={handleAddNewAsset}/>
        ) : (
          <Grid container spacing={1}>
            <Grid item md={6} xs={12} className={classes.otherAssetsChart}>
              <OtherAssetsChart otherAssets={otherAssets} updatedAt={data.updatedAt}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <OtherAssetsTotal value={totalOtherAssetsValue} />
                </Grid>
                <Grid item xs={12}>
                  <OtherAssetsRating otherAssets={otherAssets}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <PrimaryButton
                variant={'text'}
                text={"Vermögenswerte hinzufügen"}
                icon={<i className="far fa-plus"></i>}
                onButtonClick={handleAddNewAsset}
              />
            </Grid>
          </Grid>
        )}
        <OtherAssetsModal
          assetsData={data}
          customerId={customerId}
          open={otherAssetsModalOpen}
          onClose={() => setOtherAssetsModalOpen(false)}
          onUpdate={() => {
            onUpdate && onUpdate();
            fetchOtherAssetsData();
          }}
        />
      </>
    )
  }

  return (
    <ChartSectionBordered
      title={
        <>
          <b>Sonstige Vermögenswerte</b>
          <DashboardInfoTooltip
            title={'Übersicht über weitere Vermögenswerte Ihrer Kunden. Können, mit Ausnahme der Verrechnungskonten, manuell erfasst und bearbeitet werden. Die eingetragenen Werte bleiben unverändert. (Ausnahme: Verrechnungskonten, da aktualisierte Stände von Depotstellen geliefert werden)'}
          />
        </>
      }
      titleControl={!!data.data && !_.isEmpty(data.data.assets) && (
        <PrimaryButton
          onButtonClick={openDetails}
          variant={'text'} text={'Zur Detailansicht'}
          icon={<i className={"far fa-long-arrow-right"} style={{fontSize: 16, verticalAlign: 'middle'}} />}
          iconPosition={'right'}
        />
      )}
      expanded
      fullHeight
      subSection
      loading={data.loading}
      error={data.error}
      displayError
      content={getContent()}
    />
  )
}), (prevProps, nextProps) => {
  return prevProps.customerId == nextProps.customerId
    && JSON.stringify(prevProps.portfolios) == JSON.stringify(nextProps.portfolios)
    && prevProps.startDate == nextProps.startDate && prevProps.endDate == nextProps.endDate;
});

const useOpenTransactionValueStyles = makeStyles(() => ({
  openTransactionLoadingContainer: {
    width: '50%'
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      marginRight: 5
    }
  },
  subHeader: {
    margin: 0,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D4F5C',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

function OverviewTab(props) {
  const {customSettings, reportType, sharedSettings} = props;
  const classes = useStyles();
  const openTransactionClasses = useOpenTransactionValueStyles();
  const returnClasses = useAggregatedReturnSectionStyles();

  const withOtherAssets = _.get(props, 'dashboardSettings.with_other_assets', true);
  const excludedDepotId = _.get(props, 'dashboardSettings.excluded_portfolios.value');

  const {
    customerId,
    portfolios,
    dates,
    dataProvider,
    withHistorical,
  } = getDataRetrievingParamsFromProps(props);

  const [data, fetch] = useDepotFinancialData(
    customerId, portfolios, dates.start, dates.end, withHistorical, excludedDepotId, dataProvider, withOtherAssets);
  const unrealizedProfitAndLoss = useUnrealizedProfitAndLossData(
    customerId, portfolios, dates.start, dates.end, withHistorical, dataProvider);

  const isExpertChartVisible = (chartName) => {
    return isExpertSectionVisible(chartName, reportType, customSettings);
  };

  const _renderTotalValue = (dataKey) => {
    if (!data.data) return null;

    const value = _.get(data, dataKey) || 0;

    return <span className={returnClasses.value}>{withEuroOrDash(value)}</span>
  };

  const getValueFraction = (value) => {
    if (value) {
      let fraction = 2;

      let openTransactionValueString = value.toString()
      let digits = openTransactionValueString.split('.')
      if (digits.length > 1) {
        let zeroNumbers = 0;
        for (let i = 0; i < digits[1].length; i++) {
          zeroNumbers++;
          if (digits[1][i] != '0') {
            break
          }
        }

        if (zeroNumbers > fraction) {
          fraction = zeroNumbers
        }
      }

      return fraction
    }

    return 2
  };

  const renderOpenTransactionValue = () => {
    let [
      unrealizedProfitAndLossData,
      unrealizedProfitAndLossDataLoading,
      unrealizedProfitAndLossDataLoadingError
    ] = [unrealizedProfitAndLoss.data || {}, unrealizedProfitAndLoss.loading, unrealizedProfitAndLoss.errors];

    if (unrealizedProfitAndLossDataLoading) {
      return (
        <div className={openTransactionClasses.openTransactionLoadingContainer}>
          <Skeleton/>
        </div>
      )
    }

    if (unrealizedProfitAndLossDataLoadingError) {
      return (
        <p className={clsx([openTransactionClasses.subHeader, openTransactionClasses.errorMessage])}>
          <ErrorOutline/>{getErrorMessage(unrealizedProfitAndLossDataLoadingError)}</p>
      )
    }

    const value = unrealizedProfitAndLossData.pending_switches;

    if (!_.isNil(value) && value != 0) {
      return (
        <p className={openTransactionClasses.subHeader}>Offene Täusche {withEuroOrDash(value || 0, getValueFraction(value))}</p>
      )
    }

    return null
  };

  const renderTotalInvestedAmount = () => {

    return (
      <>
        {_renderTotalValue('data.data.aum.aum_value')}
        {renderOpenTransactionValue()}
      </>
    )
  };

  const renderTotalInvestedAmountIncludingOtherAssets = () => {
    return _renderTotalValue('data.data.total.value')
  };

  const renderTransactionSaldo = () => {
    return _renderTotalValue('data.data.overall_invested_amount')
  };

  const openRiskDetails = () => {
    executeIfPathExist(props.investmentPlatform.routes, 'RISK_INDICATOR_OVERVIEW', path => {
      window.open(`/${getInvestmentDynamicPath()}${path.replace(':customer_id', customerId)}`, '_blank')
    })
  };

  const customerRisk = _.get(props, 'customerData.last_srri.srri'),
    productRisk = _.get(data, 'data.data.sri'),
    riskProfileLoading = !props.isMounted || data.loading;
  const endDateFormatted = moment(dates.end).format(DATE_FORMAT);
  const trackingStartDate = _.get(data, 'data.data.start_date');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={withOtherAssets ? 6 : 12}>
        <Grid container spacing={2} style={{height: 'calc(100% + 16px)'}}>
          {isSectionVisible(customSettings, 'totalAmount', reportType) && (
            <Grid item xs={12}>
              <ChartSectionBordered
                title={
                  <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <b>Gesamtvermögen zum {endDateFormatted}</b>
                      <DashboardInfoTooltip
                        title={`Summe aller vorhandenen Depots ${withOtherAssets ? ', Verrechnungskonten und sonstigen Vermögenswerte':'und Verrechnungskonten'} zum gewählten Betrachtungszeitraum.`}
                      />
                    </div>
                    <span className={classes.subHeader}>{`Alle Depots inkl. ${withOtherAssets ? 'sonstige Vermögenswerte/' : ''}Verrechnungskonten`}</span>
                  </div>
                }
                expanded
                subSection
                fullHeight
                loading={data.loading}
                error={data.error}
                displayError
                content={renderTotalInvestedAmountIncludingOtherAssets()}
              />
            </Grid>
          )}
          {isSectionVisible(customSettings, 'selectedAmount', reportType) && (
            <Grid item xs={12}>
              <ChartSectionBordered
                title={
                  <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <b>Wert gewählte Depots zum {endDateFormatted}</b>
                      <DashboardInfoTooltip title={"Summe der ausgewählten Depots zum gewählten Betrachtungszeitraum."}/>
                    </div>
                    <span className={classes.subHeader}>Exkl. sonstige Vermögenswerte/Verrechnungskonten</span>
                  </div>
                }
                expanded
                subSection
                loading={data.loading}
                error={data.error}
                displayError
                fullHeight
                content={renderTotalInvestedAmount()}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {withOtherAssets && (
        <Grid item xs={12} md={6}>
          <OtherAssetsSection customerId={customerId} portfolios={portfolios} startDate={dates.start} endDate={dates.end} onUpdate={fetch}/>
        </Grid>
      )}

      {reportType == REPORT_TYPES.DEPOT_STATUS.value ? (
        <Grid item xs={12}>
          <ChartSectionBordered
            title={<b>Transaktionssaldo</b>}
            expanded
            loading={data.loading}
            error={data.error}
            displayError
            subSection
            content={renderTransactionSaldo()}
          />
        </Grid>
      ) : (
        <>
          {isSectionVisible(customSettings, 'selectedPeriodReturn', reportType) && (
            <Grid item xs={12} md={6}>
              <ChartSectionBordered
                title={
                  <ReturnHeader
                    title={"Rendite gewählte Depots im ausgewählten Zeitraum"}
                    subTitle={`${moment(dates.start || trackingStartDate).format(DATE_FORMAT)} - ${endDateFormatted}`}
                  />
                }
                expanded
                loading={data.loading}
                error={data.error}
                displayError
                subSection
                content={(
                  <AggregatedPortfolioReturnSection
                    data={data.data}
                    dataKey={'ydt'}
                    customSettings={_.get(customSettings, 'selectedPeriodReturn.subcategories')}
                    reportType={reportType}
                  />
                )}
              />
            </Grid>
          )}
          {isSectionVisible(customSettings, 'totalReturn', reportType) && (
            <Grid item xs={12} md={6}>
              <ChartSectionBordered
                title={
                  <ReturnHeader
                    title={"Rendite gewählte Depots seit Investmentbeginn"}
                    subTitle={`seit ${moment(trackingStartDate).format(DATE_FORMAT)}`}
                  />
                }
                expanded
                loading={data.loading}
                error={data.error}
                displayError
                subSection
                content={(
                  <AggregatedPortfolioReturnSection
                    data={data.data}
                    dataKey={'ysb'}
                    customSettings={_.get(customSettings, 'totalReturn.subcategories')}
                    reportType={reportType}
                  />
                )}
              />
            </Grid>
          )}
        </>
      )}
      {isExpertChartVisible('riskProfile') && (
        <Grid item xs={12} md={6}>
          <ChartSectionBordered
            title={
              <>
                <b>Risikoprofil</b>
                <DashboardInfoTooltip
                  title={'Gegenüberstellung der Risikoklasse des Kunden mit dem durchschnittlichen Portfoliorisiko.'}
                />
              </>
            }
            titleControl={!_.isNil(customerRisk) &&
              <PrimaryButton
                onButtonClick={openRiskDetails}
                variant={'text'} text={'Zur Risikoklassenanalyse'}
                icon={<i className={"far fa-long-arrow-right"} style={{fontSize: 16, verticalAlign: 'middle'}} />}
                iconPosition={'right'}
              />
            }
            expanded
            subSection
            fullHeight
            content={<RiskProfileCard customerRisk={customerRisk} productRisk={productRisk} loading={riskProfileLoading} />}
          />
        </Grid>
      )}
      {isExpertChartVisible('esgProfile') && (
        <Grid item xs={12} md={6}>
          <ESGProfileSection
            customerId={customerId}
            portfolios={portfolios}
            aggregatedPortfolioName={props.aggregatedPortfolioName}
          />
        </Grid>
      )}
      {isSectionVisible(customSettings, 'depots', reportType) && portfolios.map((portfolio) => (
        <Grid item xs={12} md={6} key={portfolio.depotNumber}>
          <PortfolioSummarySection
            sharedSettings={sharedSettings}
            customerId={customerId}
            portfolio={portfolio}
            startDate={dates.start}
            endDate={dates.end}
            dataProvider={dataProvider}
            reportType={props.reportType}
            customSettings={_.get(customSettings, 'depots.subcategories')}
          />
        </Grid>
      ))}
    </Grid>
  )
}

OverviewTab.propTypes = {};

OverviewTab.propDefs = {};

OverviewTab.defaultProps = {};

export default connect(mapStateToProps)(OverviewTab);