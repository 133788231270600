import React from 'react';

import Grid from '@material-ui/core/Grid'

import ChartSectionBordered from "../ChartSectionBordered/ChartSectionBordered";
import WarningTooltip from "../../../../components/WarningTooltip";
import clsx from "clsx";
import useStyles from './styles';
import {formatNumber, toShortGermanFormat} from "../../../../utils/numberFormater";
import HighChartBase from "../../../../components/Charts/Base";
import InfoDialog from "../../../../components/InfoDialog";
import {getErrorMessage} from "../../../../utils/utils";
import {usePerformanceForecastData} from "../../hooks/usePerformanceForecastData";
import Legend from "../Legend/Legend";

const ChartColorBest = '#7ED2CF';
const ChartColorMiddle = '#0098C6';
const ChartColorWorst = '#1F4998';

const forecast = {
  'good': {
    'name': 'Starke',
    'style': {background: ChartColorBest},
    'tooltipText': '10%',
  },
  'mid': {
    'name': 'Durchschnittliche',
    'style': {background: ChartColorMiddle},
    'tooltipText': '50%',
  },
  'bad': {
    'name': 'Schlechte',
    'style': {background: ChartColorWorst},
    'tooltipText': '90%',
  }
};

const Chart = React.memo((props) => {
  const classes = useStyles();

  const renderLegend = () => {

    let numOptions = {
      fraction: 2,
      decimal: ',',
      suffix: '',
      prefix: '',
    }

    let legend = Object.keys(forecast).map(key =>  {
      return {
        name: (
          <span>
          {forecast[key].name} Wertentwicklung:&nbsp;
            <b>{toShortGermanFormat(props.data[key + '_return_last'])} EUR</b>
            &nbsp;({formatNumber(props.data[key + '_return_pa'] * 100, numOptions)} % p.a.)
          </span>
        ),
        color: forecast[key].style.background
      }
    })

    return (
      <Legend data={legend}/>
    )
  }

  const renderChart = () => {

    const goodReturnTS = props.data['good_return']
      .map(value => ([Date.parse(value.date), value.return]));
    const midReturnTS = props.data['mid_return'].map(value => ([Date.parse(value.date), value.return]));
    const badReturnTS = props.data['bad_return'].map(value => ([Date.parse(value.date), value.return]));
    const fillAreaTS = props.data['good_return']
      .map((value, i) => ([Date.parse(value.date), value.return, props.data['bad_return'][i].return]));

    const start_value = toShortGermanFormat(props.data['start_value']);
    const start_date = Date.parse(props.data['start_date']);

    return (
      <HighChartBase options={{
        chart: {
          height: 370,
          marginTop: 30,
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                week: ["Woche vom %A, %b %e, %Y"],
              }
            },
            type: 'line',
            fillColor: undefined,
            compareBase: 0,
            showInNavigator: true,
          },
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          gridLineWidth: 1,
        },
        yAxis: [{
          opposite: false,
          title: {
            enabled: false
          },
          labels: {
            useHTML: true,
            enabled: true,
            x: -2,
            formatter: function () {
              return toShortGermanFormat(this.value, '', ' &euro;', 2, true);
            },
          }
        }, {
          opposite: true,
          linkedTo: 0,
          title: {
            enabled: false
          },
          labels: {
            useHTML: true,
            enabled: true,
            x: -2,
            formatter: function () {
              return toShortGermanFormat(this.value, '', ' &euro;', 2, true);
            },
          }
        }],
        legend: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<span style="display: block; padding: 2px 0px; font-size: 14px; font-family: Roboto-Bold;">{point.key}</span>',
          pointFormat: '<span style="display: block; padding: 6px 0px;"><div style="display: inline-block; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};"></div><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 5px;">{series.name} {point.y}</div></span>',
          formatter: function (tooltip) {
            let defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);
            // defaultTooltip is an array where 1st item is the formatted date according to headerFormat
            const daysDiff = Math.floor((this.points[0].x - start_date) / (1000 * 60 * 60 * 24));

            if (daysDiff > 365) {
              const yearsDiff = Math.floor(daysDiff / 365);
              defaultTooltip.splice(1, 0, `<span style="display: block; padding: 6px 0px; font-size: 14px; font-family: Roboto-Bold; color: #80858C">Investition ${start_value} &euro; <br>Chancen nach ${yearsDiff} Jahren</span>`);
            }

            return defaultTooltip
          },
          valueDecimals: 2,
          backgroundColor: 'white',
          shadow: {
            color: '#00000026',
            offsetX: 0,
            offsetY: 0,
            width: 10,
            opacity: 0.15
          },
          padding: 20,
          shape: 'square',
          borderWidth: 0,
          borderRadius: 4,
          valueSuffix: '&euro;',
          style: {
            fontFamily: '"Roboto-Regular"',
          }
        },
        series: [
          {
            type: 'arearange',
            name: 'fill_',
            showInLegend: false,
            enableMouseTracking: false,
            fillColor: '#F2F8F2',
            data: fillAreaTS,
            zIndex: 1,
          },
          {
            type: 'line',
            name: `${forecast['good'].tooltipText} auf min.`,
            data: goodReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: ChartColorBest
            },
            color: ChartColorBest,
          }, {
            type: 'line',
            name: `${forecast['mid'].tooltipText} auf min.`,
            data: midReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: ChartColorMiddle
            },
            color: ChartColorMiddle,
          }, {
            type: 'line',
            name: `${forecast['bad'].tooltipText} auf min.`,
            data: badReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: ChartColorWorst
            },
            color: ChartColorWorst,
          }
        ]
      }} language={'DE'}/>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        {renderChart()}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderLegend()}
      </Grid>
      <Grid item xs={12}>
        <p className={classes.footerExplanation}>
          *Für ausgewählte Depots. <span style={{textDecoration: 'underline'}}>Bitte beachten Sie:</span> Vergangenheitsbezogene Daten sind kein verlässlicher Indikator für die zukünftige Wertentwicklung. Depot- und Transaktionskosten (z. B. Ausgabeaufschlag) sind in der Berechnung nicht berücksichtigt.
        </p>
      </Grid>
    </Grid>
  )
}, (prevProps, nextProps) => {
  return prevProps.updatedAt == nextProps.updatedAt;
})

function PerformanceForecast({expanded, onExpanded, customerId, portfolios, dataProvider, assets, investmentStrategyId}) {

  const classes = useStyles();

  const data = usePerformanceForecastData(customerId, portfolios, dataProvider, assets, investmentStrategyId);

  return (
    <ChartSectionBordered
      title={(
        <>
          <b>Mögliche Wertentwicklung 15 Jahre*</b>
          <WarningTooltip
            title={"Mit der Bootstrapping-Methode werden 1000 Szenarien für zukünftige Renditen erzeugt. Dabei werden zukünftige Renditen durch zufällige Auswahl aus wöchentlichen, historischen Renditen der letzten 10 Jahre simuliert. Die gute, durchschnittliche und schlechte Wertentwicklung entspricht dem 90., 50. und 10. Perzentil der Renditeverteilung aus 1000 Szenarien."}
            icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
            width={600}
          />
        </>
      )}
      content={(
        <>
          {!!data.data && (
            <Chart data={data.data} updatedAt={data.updatedAt}/>
          )}
        </>
      )}
      loading={data.loading}
      error={data.errors}
      displayError={true}
      expanded={expanded}
      onExpanded={onExpanded}
    />
  )
}

PerformanceForecast.propTypes = {}

PerformanceForecast.propDefs = {}

PerformanceForecast.defaultProps = {}

export default PerformanceForecast;