import {CUSTOMER_ID_KEY, getFromStorage, USER_KEY} from "./utils/storage";
import {UserUtils} from "./utils/utils";
import {executeIfPathExist, getInvestmentDynamicPath} from "./containers/InvestmentPlatform/utils";
import {SharedSettingsResource} from "./utils/api";
import _ from "lodash";

const BROKER = {
  CUSTOMER_DASHBOARD: {
    path: '/investment-old/portfolio-analysis/customer/:customer_id',
    name: 'Vermögensübersicht',
  },
  RISK_DASHBOARD: {
    path: '/risk-dashboard',
    name: 'Risikoanalyse'
  },
  REPORT_SETTINGS: {
    path: '/investment-old/report-settings',
    name: 'Gruppen & Einstellungen für Reports'
  },
  REPORTING: {
    path: '/investment-old/reporting',
    name: 'Reports erstellen',
    children: {
      GROUPS:{
        path: '/investment-old/reporting/groups',
        children: {
          COVER: {
            path: '/investment-old/reporting/group/:group_id/cover',
            newDesignPath: '/group/:group_id/cover'
          },
          PREVIEW: {
            path: '/investment-old/reporting/group/:group_id/preview',
            newDesignPath: '/group/:group_id/preview'
          }
        },
        newDesignPath: '/groups'
      },
      CUSTOMERS: {
        path: '/investment-old/reporting/customers',
        children: {
          COVER: {
            path: '/investment-old/reporting/customer/:customer_id/cover',
            newDesignPath: '/customer/:customer_id/cover'
          },
          PREVIEW: {
            path: '/investment-old/reporting/customer/:customer_id/preview',
            newDesignPath: '/customer/:customer_id/preview'
          }
        },
        newDesignPath: '/customers'
      },
      // could be removed afret merge is finilized BCA-5734
      SINGLE_CUSTOMER: {
        newDesignPath: '/single-reporting',
        children: {
          REPORT:{
            newDesignPath: '/:customer_id/report'
          },
          AUTO_REPORT:{
            newDesignPath: '/:customer_id/auto_report'
          }
        }
      },
      GROUP: {
        newDesignPath: '/group-reporting',
        children: {
          REPORT:{
            newDesignPath: '/:group_id/report'
          },
          AUTO_REPORT:{
            newDesignPath: '/:group_id/auto_report'
          }
        }
      }
      //
    }
  },
  GROUP: {
    path: '/group-settings'
  },
  SERIES_REPORTING: {
    newDesignPath: '/series-reporting',
    children: {
      SINGLE_CUSTOMER: {
        newDesignPath: '/single-reporting',
        children: {
          REPORT:{
            newDesignPath: '/single-reporting/:customer_id/report'
          },
          AUTO_REPORT:{
            newDesignPath: '/single-reporting/:customer_id/auto_report'
          }
        }
      },
      GROUP: {
        newDesignPath: '/group-reporting',
        children: {
          REPORT:{
            newDesignPath: '/group-reporting/:group_id/report'
          },
          AUTO_REPORT:{
            newDesignPath: '/group-reporting/:group_id/auto_report'
          }
        }
      }
    }
  },

  GROUP_CREATE: {
    path: '/group-settings/create',
    newDesignPath: '/create'
  },
  DASHBOARD_GROUP_CREATE: {
    path: '/dashboard-group-settings/create',
    newDesignPath: '/create'
  },

  GROUP_UPDATE: {
    path: '/group-settings/:group_id',
    newDesignPath: '/group/:group_id'
  },
  NEW_GROUP_UPDATE: {
    path: '/new-group-settings/:group_id',
    newDesignPath: '/group/:group_id'
  },
  DASHBOARD_GROUP_UPDATE: {
    path: '/dashboard-group-settings-update/:group_id',
    newDesignPath: '/group/:group_id'
  },
  GROUP_CLIENTS_ADD: {
    path: '/group-settings/:group_id/clients/add'
  },
  GROUP_CLIENTS_REMOVE: {
    path: '/group-settings/:group_id/clients/remove'
  },
  OTHER_ASSETS: {
    path: '/investment-old/portfolio-analysis/other-assets/:customer_id',
    name: 'Sonstige Vermögenswerte'
  },
  OTHER_ASSETS_LIST: {
    path: '/investment-old/portfolio-analysis/other-assets/:customer_id/list',
    name: 'Sonstige Vermögenswerte',
    newDesignPath: '/list'
  },
  OTHER_ASSETS_CATEGORY: {
    path: '/investment-old/portfolio-analysis/other-assets/:customer_id/category/:category_id',
    name: 'Sonstige Vermögenswerte',
    newDesignPath: '/category/:category_id'
  },
  FORM_CENTER: {
    path: '/form-center/:customer_id/',
    name: 'Form Center'
  }
};

const CUSTOMER = {
  LOGIN: {
    path: '/:agency_id/login'
  },
  DOCUMENTS: {
    path: '/:agency_id/documents',
    name: 'Postfach'
  },
  DASHBOARD: {
    path: '/:agency_id/portfolio-dashboard',
    name: 'Vermögensübersicht'
  },
  DASHBOARD_VIRTUAL: {
    path: '/:agency_id/virtual-portfolio/:customer_id/dashboard',
    name: 'Virtuelle Depots'
  },
  VIRTUAL_PORTFOLIO_PAGE: {
    path: '/:agency_id/virtual-portfolio/analysis/:portfolio_id',
    name: 'Virtuelle Depots'
  },
  VIRTUAL_PORTFOLIO_MANAGER: {
    path: '/:agency_id/virtual-portfolio/management/:portfolio_id',
    name: 'Virtuelle Depots'
  },
  VIRTUAL_PRO_VIEW: {
    path: '/:agency_id/virtual-portfolio/:customer_id/:portfolio_id/pro-view',
    name: 'Virtuelle Depots'
  },
  PRO_VIEW:{
    path: '/:customer_id/:portfolio_id/pro-view',
    name: 'Sonstige Vermögenswerte'
  },
  OTHER_ASSETS: {
    path: '/:agency_id/other-assets',
    name: 'Sonstige Vermögenswerte'
  },
  OTHER_ASSETS_LIST: {
    path: '/:agency_id/other-assets/list',
    name: 'Sonstige Vermögenswerte'
  },
  OTHER_ASSETS_CATEGORY: {
    path: '/:agency_id/other-assets/category/:category_id',
    name: 'Sonstige Vermögenswerte'
  },
  FACTSHEETS_DASHBOARD: {
    path: '/factsheets/:isin',
    name: 'Factsheets'
  }
};

export const ROUTES = {
  BROKER,
  CUSTOMER
};

export const buildCustomerDashboardLink = (customer_id) => {
  return ROUTES.BROKER.CUSTOMER_DASHBOARD.path
    .replace(':customer_id', customer_id)
};

export const buildCustomerProViewLink = (customer_id, portfolio_id) => {
  return ROUTES.CUSTOMER.PRO_VIEW.path
    .replace(':customer_id', customer_id)
    .replace(':portfolio_id', portfolio_id)
}

export const buildOtherAssetsListLink = (customer_id) => {
  return ROUTES.BROKER.OTHER_ASSETS_LIST.path
    .replace(':customer_id', customer_id);
};

export const buildOtherAssetsCategoryLink = (customer_id, category_id) => {
  return ROUTES.BROKER.OTHER_ASSETS_CATEGORY.path
    .replace(':customer_id', customer_id)
    .replace(':category_id', category_id);
};

const getCurrentCustomerAgencyId = () => {
  const user = JSON.parse(getFromStorage(USER_KEY) || '{}');
  return user.agency && user.agency.agency_id;
};

const updateCustomerPath = (path, agency_id) => {
  return path.replace(':agency_id', agency_id || getCurrentCustomerAgencyId());
};

export const buildCurrentCustomerLoginPath = (agency_id) => {
  return updateCustomerPath(ROUTES.CUSTOMER.LOGIN.path, agency_id);
};

export const buildCurrentCustomerDocumentsPath = () => {
  return updateCustomerPath(ROUTES.CUSTOMER.DOCUMENTS.path);
};

export const buildCurrentCustomerDashboardPath = () => {
  return updateCustomerPath(ROUTES.CUSTOMER.DASHBOARD.path);
};

export const buildCurrentCustomerDashboardVirtualPath = (customer_id) => {
  let path =  updateCustomerPath(ROUTES.CUSTOMER.DASHBOARD_VIRTUAL.path);
  path = path.replace(':customer_id', customer_id);
  return path;
};

export const buildCurrentCustomerVirtualProViewPath = (customerId, portfolio_id) => {
  let path =  updateCustomerPath(ROUTES.CUSTOMER.VIRTUAL_PRO_VIEW.path);
  path = path.replace(':customer_id', customerId);
  path = path.replace(':portfolio_id', portfolio_id);
  return path;
};

export const buildCurrentCustomerVirtualPortfolioPage = (portfolio_id) => {
  let path = ROUTES.CUSTOMER.VIRTUAL_PORTFOLIO_PAGE.path;
  path = path.replace(':agency_id', getCurrentCustomerAgencyId());
  path = path.replace(':portfolio_id', portfolio_id);
  return path;
};

export const buildCurrentCustomerVirtualPortfolioManager = (portfolio_id) => {
  let path = ROUTES.CUSTOMER.VIRTUAL_PORTFOLIO_MANAGER.path;
  path = path.replace(':agency_id', getCurrentCustomerAgencyId());
  path = path.replace(':portfolio_id', portfolio_id);
  return path;
};

export const buildCurrentCustomerOtherAssetsPath = () => {
  return updateCustomerPath(ROUTES.CUSTOMER.OTHER_ASSETS.path);
};

export const buildCurrentCustomerOtherAssetsListPath = () => {
  return updateCustomerPath(ROUTES.CUSTOMER.OTHER_ASSETS_LIST.path);
};

export const buildCurrentCustomerOtherAssetsCategoryPath = (category_id) => {
  return updateCustomerPath(ROUTES.CUSTOMER.OTHER_ASSETS_CATEGORY.path)
    .replace(':category_id', category_id);
};

export const buildEditGroupPath = (groupId) => {
  return BROKER.GROUP_UPDATE.path.replace(':group_id', groupId);
}

export const buildFactSheetsLink = (isin) => {
  return ROUTES.CUSTOMER.FACTSHEETS_DASHBOARD.path
    .replace(':isin', isin);
};

export const getProViewLink = (auth, routes, portfolioId, customerId, isVirtual) => {
  if(isVirtual){
    return buildCurrentCustomerVirtualProViewPath(customerId, portfolioId)
  }
  if(!customerId) customerId = getFromStorage(CUSTOMER_ID_KEY);
  let userIsBroker = UserUtils.isBroker(auth);
  let path = undefined

  executeIfPathExist(routes, 'PRO_VIEW', (dynamicPath) => {
    if (userIsBroker) {
      path = '/' + getInvestmentDynamicPath() + dynamicPath.replace(':customer_id', customerId).replace(':portfolio_id', portfolioId)
    } else {
      path = buildCustomerProViewLink(auth.user.customer_id, portfolioId)
    }
  }, ':customer_id')

  // if current location equals path there is no need to return link
  return  window.location.pathname === path ? undefined : path
}

const allowedItem = (item, auth) => {
  return item.beta ? UserUtils.isBetaUser(auth) : true;
}

const filterMenuItems = (arr, auth) => {
  return arr.filter(item => {
    const isAllowed = allowedItem(item, auth);

    if (isAllowed && item.children) {
      item.children = filterMenuItems(item.children)
    }

    return isAllowed;
  });
}

export const getMenuItems = async(auth, components) =>{
  // if components is passed - only items from this list are retrieved
  let response = await SharedSettingsResource.at('menu-configuration/').post({components});
    if (_.isArray(response)) {
      response = filterMenuItems(response, auth);
    }

  return response
}