import React from 'react';
import { withRouter } from 'react-router';
import _ from "lodash";
import { connect } from "react-redux";

import withWidth from "@material-ui/core/withWidth/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import { HistoricalValueChart } from 'fincite-react-charts';
import {
  AGGREGATED_PORTFOLIO_ID,
  getPortfolioMinStartOfInvestment, handleChartSizeOnWindowResize,
  hasResponseError
} from "../../../../../../utils/utils";
import {formatPortfolioName} from "../../../../../../utils/aggregated-portfolio";
import ChartLegend from '../../../../../../components/Charts/Legend';
import BenchmarkModal from '../../../InvestmentDetailsNavigation/BenchmarkSettingsModal';
import {BENCHMARK_CHART_COLOR, DATE_FORMAT} from "../../../../../../utils/constants";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import {ChartTooltipSettings} from "../../../../utils";
import {DashboardChartSection} from "../index";
import BenchmarkWithConfiguration from './benchmark';
import moment from 'moment';

import { HOST_RELATED_SHARED_SETTINGS_KEYS } from '../../../../../../components/SharedSettingsProvider/constants';
import Switch from "../../../../../../components/AssetModal/components/Switch";
import {Grid} from "@material-ui/core";
import {setTimeSelectorDateChange} from "../../../../actions";
import clsx from "clsx";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import {aggregateSelectedDisabledExplanation} from "../../../../../DashboardSettings/constants";
import {getGuideSelector} from "../../../../CustomerDashboard";
import {getCustomerDashboardSelector} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import {createSelector} from "reselect";
import {renderToStaticMarkup} from "react-dom/server";
import {getColorsFromChartData} from "../HistoricalPerformanceChart";

const getBenchmarkConfiguration = (state) => state.get('benchmarkConfiguration')
export const getBenchmarkConfigurationSelector = createSelector(
  [getBenchmarkConfiguration],
  (benchmarkConfiguration) => benchmarkConfiguration.toJS()
)


const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  benchmarkConfiguration: getBenchmarkConfigurationSelector(state),
  guide: getGuideSelector(state),
});


const _performanceChartPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.chartData.timestamp === nextProps.chartData.timestamp
    && prevProps.startDate === nextProps.startDate
    && prevProps.endDate === nextProps.endDate
}

function yAxisFormatter() {
  return toShortGermanFormat(this.value, '', ' %', 2, true);
}

export const _PerformanceLineChart = React.memo(({chartData, dispatch, classes, onDatesChange, startDate, endDate, withLegend=true}) => {
  const handleOnPointClick = (endDate) => {
    dispatch(setTimeSelectorDateChange({
      startOfBenchmarkChanged: true,
      start: moment(chartData.startOfBenchmark.date),
      end: moment(endDate)
    }));
  }

  const updatePoints = (series) => {
    series.points.forEach(p => {
      p.update({
        marker: {
          fillColor: '#FFFFFF',
          lineWidth: 2,
          width: 5,
          lineColor: BENCHMARK_CHART_COLOR,
          enabled: true,
          symbol: 'circle',
          radius: 5
        },
        events: {
          click: function () {
            handleOnPointClick(this.series.chart.rangeSelector.maxInput.value);
          },
          mouseOver: function () {
            this.dataLabel.show();
          },
          mouseOut: function () {
            this.dataLabel.hide();
          }
        }
      });
    });
  }

  const hideDataLabels = (series) => {
    series.points.forEach(p => {
      p.dataLabel.hide();
    });
  }

  const updateSeries = (series) => {
    series.update({
      dataGrouping: {
        enabled: true,
        dateTimeLabelFormats: {
          week: ["Woche vom %A, %b %e, %Y"],
        }
      }
    })
  }

  const data = [
    ...chartData.data,
    chartData.showStartOfBenchmark ? {
      name: 'startOfBenchmark',
      portfolio: [chartData.startOfBenchmark]
    } : {name: '', portfolio: []}
  ]

  return (
    <div className={classes.chartContainer}>
      {withLegend && (
        <ChartLegend
          names={chartData.data.map(portfolio => portfolio.name)}
          colors={chartData.data.map(portfolio => portfolio.color)}
        />
      )}
      <HistoricalValueChart
        colors={getColorsFromChartData(data)}
        title=''
        data={data}
        chartOptions={{
          chart: {
            height: 350,
            marginTop: 0,
            events: {
              ...ChartTooltipSettings.chart.events,
              load: function (event) {
                this.tooltip.options.style.width = (this.chartWidth - 10) + 'px';
                handleChartSizeOnWindowResize(event);
                setTimeout(() => {
                  if (!this.series) {
                    return
                  }
                  (this.series.filter(s => s.name === 'startOfBenchmark') || []).forEach(s => {
                    updatePoints(s);
                  });
                  this.series.forEach(s => {
                    updateSeries(s);
                  });
                }, 100)
              },
              render: function() {
                if (!this.series) {
                  return
                }
                (this.series.filter(s => s.name === 'startOfBenchmark') || []).forEach(s => {
                  hideDataLabels(s);
                });
              }
            },
            animation: false,
          },
          boost: {
            enabled: true,
            useGPUTranslations: true,
            seriesThreshold: 1,
            debug: {
              timeRendering: true
            }
          },
          rangeSelector: {
            allButtonsEnabled: false,
            inputStyle: {
              visibility: 'hidden',
              opacity: 0,
            },
            labelStyle:{
              visibility: 'hidden',
              opacity: 0,
            },
            verticalAlign: 'top',
            buttons: []
          },
          navigator: {
            enabled: true
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: true
          },
          plotOptions: {
            series: {
              compareBase: 0,
              showInNavigator: true,
              dataGrouping: {
                enabled: false
              },
              dataLabels: {
                enabled: true,
                useHtml: true,
                allowOverlap: true,
                shape: 'callout',
                borderColor: '#666666',
                backgroundColor: 'rgba(255, 255, 255, .9)',
                borderWidth: 1,
                borderRadius: 6,
                y: -15,
                shadow: true,
                zIndex: 99,
                style: {
                  cursor: 'default',
                  fontWeight: 'normal'
                },
                formatter: function () {
                  if(this.series.name === 'startOfBenchmark'){
                    return renderToStaticMarkup(
                      <>
                        Startdatum der<br/>
                        Benchmark:<br/>
                        {chartData.startOfBenchmark && moment(chartData.startOfBenchmark.date).format(DATE_FORMAT)}
                      </>
                    );
                  }
                  return null;
                }
              },
            }
          },
          tooltip: {
            ...ChartTooltipSettings.tooltip,
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} %</b><br/>',
            valueDecimals: 2,
            split: true,
            padding: ChartTooltipSettings.getTooltipPadding(data.length)
          },
          yAxis: [{
            opposite: false,
            align: 'right',
            labels: {
              enabled: true,
              x: -2,
              y: 4,
              formatter: yAxisFormatter
            }
          }, {
            linkedTo: 0,
            opposite: true,
            align: 'right',
            labels: {
              enabled: true,
              x: 2,
              y: 4,
              formatter: yAxisFormatter
            }
          }],
          xAxis: {
            min: Date.parse(startDate) || undefined,
            max: Date.parse(endDate) || undefined,
            gridLineWidth: 1,
            events: {
              setExtremes: function (event) {
                const self = event.target.chart;
                if(self.rangeSelector.minInput.value && self.rangeSelector.maxInput.value) {
                  onDatesChange({start: moment(self.rangeSelector.minInput.value), end: moment(self.rangeSelector.maxInput.value)});
                }
              }
            }
          },
        }}
      />
    </div>
  )
}, _performanceChartPropsAreEquals)


const PerformanceLineChart = (props) => {
  const {
    dashboardData,
    dashboardDataLoading,
    dashboardDataLoadingError,
    classes,
    updateHistoricalData,
    updateRiskMetricsBenchmark,
    calculationDates,
    dispatch,
    benchmarkConfigurationEnabled,
    width,
    timestamp,
    customerDashboard,
    benchmarkConfiguration,
    dashboardDataOptions,
    isCustomerApp,
    guide,
    onChartSettingsChange,
    onExpanded,
    expanded,
    hideControl,
    showBenchmark,
    chartsSettings,
    aggregateSelectedPortfolios,
    isCumulativeDisplaySwitchDisabled,
    selectedPortfolios,
    isVirtual,
  } = props;

  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    data: [],
    error: false,
    showStartOfBenchmark: false,
    startOfBenchmark: undefined
  });

  const customCalculationDates = React.useMemo(() => {
    // in case MAX period selected calculate start tracking date as earliest date of selected portfolios
    // as we add benchmarks to Aggregated

    const dates = {...calculationDates};

    if (_.isNil(dates.start) && !_.isEmpty(selectedPortfolios)){
      dates.start = getPortfolioMinStartOfInvestment(selectedPortfolios);
    }

    return dates;
  }, [JSON.stringify(selectedPortfolios), JSON.stringify(calculationDates)]);

  React.useEffect(() => {
    if (requiredDataExist()) {
      const data = getData();

      setReloadTrigger(true);

      setTimeout(() => {
        setChartData({
          data: data.preparedData,
          error: data.hasError,
          timestamp: new Date().getTime(),
          showStartOfBenchmark: data.showStartOfBenchmark,
          startOfBenchmark: data.startOfBenchmark
        });

        setTimeout(() => {
          setReloadTrigger(false);
        }, 100);
      }, 100);

    } else {
      setChartData({...chartData, error: true});
    }
  }, [dashboardData, showBenchmark, aggregateSelectedPortfolios, timestamp]);

  const dashboardPortfolios = dashboardData && dashboardData.portfolios || [];
  const portfoliosWithoutAggregated = dashboardPortfolios.filter(p => p.id !== AGGREGATED_PORTFOLIO_ID);
  // FIXME: if customer has ONLY one portfolio set benchmark to it otherwise to Aggregated
  //  cause on backend Benchmark will be returned for this specific: portfolio_handlers/data_managers/portfolio_data.py:308
  const selectedPortfolio = portfoliosWithoutAggregated.length === 1 ? portfoliosWithoutAggregated[0] : dashboardPortfolios[0];

  const getData = () => {
    if (isBenchmarkConfigurationEnabled()) {
      let preparedData = [];
      let hasError = false;
      let benchmark = undefined;
      let showStartOfBenchmark = false;
      let startOfBenchmark = undefined;

      if (!_.isUndefined(dashboardData)) {
        hasError = dashboardData.portfolios.some((portfolio) => hasResponseError(portfolio.timeseries));

        benchmark = dashboardData.portfolios.find(p => p.id === AGGREGATED_PORTFOLIO_ID && !_.isEmpty(p.benchmark_performance_detailed)) ||
          portfoliosWithoutAggregated.reduce((prev, current) => {
            return moment(prev.investment_period.start).isSameOrBefore(moment(current.investment_period.start)) ? prev : current;
          });
        const portfolios = aggregateSelectedPortfolios ?  dashboardData.portfolios.filter(p => p.id === AGGREGATED_PORTFOLIO_ID) : portfoliosWithoutAggregated

        if (portfolios && !hasError) {
          preparedData = portfolios.map(portfolio => ({
            name: formatPortfolioName(portfolio.name),
            color: portfolio.color,
            portfolio: preparePortfolioTimeseries(portfolio.timeseries.portfolio)
          }));
        }
      }

      if (benchmark && benchmark.benchmark_performance_detailed && isBenchmarkConfigurationEnabled() && showBenchmark) {
        if (hasResponseError(benchmark.benchmark_performance_detailed)){
          hasError = true;
        } else {
          if (benchmark.benchmark_performance_detailed.portfolio) {
            const benchmarkTrackingStartDate = benchmark.benchmark_performance_detailed.tracking_start_date;
            const portfolioStartDate = _.get(benchmark.timeseries.portfolio[0], 'date');

            if (benchmarkTrackingStartDate && portfolioStartDate && moment(benchmarkTrackingStartDate).isAfter(portfolioStartDate)) {
              startOfBenchmark = {date: benchmarkTrackingStartDate, value: 0};
              showStartOfBenchmark = true;
            }
            preparedData.push({
              name: benchmark.benchmark_name || "Benchmark",
              color: BENCHMARK_CHART_COLOR,
              portfolio: preparePortfolioTimeseries(benchmark.benchmark_performance_detailed.portfolio)
            });
          }
        }
      }

      return {
        hasError,
        preparedData,
        showStartOfBenchmark,
        startOfBenchmark,
      }
    }
  };

  const preparePortfolioTimeseries = (timeseries) => timeseries.map(value => ({date: value.date, value: (value.cum_ret_g || 0) * 100}));

  const isBenchmarkConfigurationEnabled = () => {
    if (_.isArray(dashboardData && dashboardData.portfolios)) {
      return aggregateSelectedPortfolios || dashboardData.portfolios.length > 0;
    }
    return false;
  };

  const requiredDataExist = () => {
    if (_.isArray(dashboardData && dashboardData.portfolios) && !_.isEmpty(dashboardData.portfolios)) {
      return dashboardData.portfolios.every(portfolio => !!portfolio.timeseries);
    }
  };

  const handleBenchmarkConfigured = (configured) => {
    if (configured && benchmarkConfiguration.configuration_required) {
      return updateBenchmarkSwitchState(true);
    }
  };

  const updateBenchmarkSwitchState = (forceShow) => {
    const isDataExist = chartsSettings.currently_selected_benchmarks.length > 0  || false;

    if(!dashboardDataLoading){
      onChartSettingsChange('performance', 'withBenchmark', isDataExist || !!forceShow);
    }
  };

  let timeout = React.useRef(null);
  const onDatesChange = (dates) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      dispatch(setTimeSelectorDateChange(dates))
    }, 100)
  }

  const isBenchmarkSwitchVisible = () => {
    if (isCustomerApp && !isVirtual) {
      const isDataExist = dashboardData && dashboardData.portfolios && dashboardData.portfolios.length
        && dashboardData.portfolios[0].benchmark_performance_detailed.portfolio
        && dashboardData.portfolios[0].benchmark_performance_detailed.portfolio.length > 0;
      return guide.active || (isBenchmarkConfigurationEnabled() && isDataExist);
    }

    return true;
  }

  const handleAggregateSelectedChange = (checked) => {
    onChartSettingsChange('performance', 'aggregateSelected', checked);
  }

  const minHeight = ['xs', 'sm', 'md'].includes(width) ? 520 : 486;

  const empty = chartData.data && chartData.data.every(item => item.portfolio.length === 0);

  const contentControl = !hideControl ? (
    <Grid container spacing={1} className={classes.contentControl} justify={"space-between"}>
      <Grid item id="performance-chart-aggregate-selected-checkbox">
        <p className={classes.benchmarkLabel}>
          Kumulierte Anzeige
          {!dashboardDataLoading && isCumulativeDisplaySwitchDisabled &&
          <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} size={13}/>}
        </p>
        <Switch
          value={aggregateSelectedPortfolios}
          handleValueChanged={handleAggregateSelectedChange}
          disabled={isCumulativeDisplaySwitchDisabled}
        />
      </Grid>
      {isBenchmarkSwitchVisible() &&
        <Grid item>
          <BenchmarkWithConfiguration
            portfolio={selectedPortfolio}
            calculationDates={customCalculationDates}
            dispatch={dispatch}
            benchmarkConfigurationEnabled={benchmarkConfigurationEnabled}
            benchmarkConfiguration={benchmarkConfiguration}
            onChartSettingsChange={onChartSettingsChange}
            isBenchmarkConfigurationEnabled={isBenchmarkConfigurationEnabled()}
            requiredDataExist={requiredDataExist()}
            showInvestment={showBenchmark}
            selectedBenchmarks={chartsSettings.currently_selected_benchmarks}
            updateBenchmarkSwitchState={updateBenchmarkSwitchState}
            customerDashboard={customerDashboard}
          />
        </Grid>
      }
    </Grid>
  ) : null;

  return (
    <>
      <DashboardChartSection
        title={props.title || "Zeitgewichtete Rendite in Prozent"}
        contentControl={contentControl}
        content={dashboardData && (
          <_PerformanceLineChart
            chartData={chartData}
            onDatesChange={onDatesChange}
            dispatch={dispatch}
            startDate={dashboardDataOptions && dashboardDataOptions.start_date}
            endDate={dashboardDataOptions && dashboardDataOptions.end_date}
            classes={classes}
          />
        )}
        loading={dashboardDataLoading || reloadTrigger}
        error={dashboardDataLoadingError || chartData.error}
        minHeight={minHeight}
        empty={empty}
        displayError={true}
        expanded={expanded}
        onExpanded={onExpanded}
        sharedSettingKey={HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_PERFORMANCE_CHART_EXPLANATION}
      />
      {!hideControl && benchmarkConfiguration && benchmarkConfiguration.modal_opened && (
        <BenchmarkModal
          calculationDates={customCalculationDates}
          onBenchmarkConfigured={handleBenchmarkConfigured}
          benchmarks={chartsSettings.currently_selected_benchmarks}
          selectedPortfolio={selectedPortfolio}
          onChartSettingsChange={onChartSettingsChange}
          updateHistoricalData={updateHistoricalData}
          updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
          isVirtual={isVirtual}
          selectedPortfolios={selectedPortfolios}
        />
      )}
    </>
  );
};

export default withWidth()(
  withStyles(styles)(
    connect(mapStateToProps)(
      withRouter(PerformanceLineChart)
    )
  )
);