import React from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';

/* BCA Components*/
import TimeFramePicker from './PortfolioTimeSelector';

/* BCA modules */
import useStyles from './styles';
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {isTradingEnabled} from "../../../../components/TradingStore/utils";
import Switch from "../../../../components/AssetModal/components/Switch";
import {Grid} from "@material-ui/core";
import _ from 'lodash';
import {getGuideSelector} from "../../CustomerDashboard";
import {getCustomerDashboardSelector} from "../../../../components/DashboardDataProvider/DashboardDataProvider";
import {DashboardPortfoliosSelectorContext} from "../../utils";
import PortfolioList from "../../components_v2/PortfolioList";
import ReportTypeSelector from '../PortfolioNavigation/ReportTypeSelector';
import { REPORT_TYPES } from '../../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import DepotViewTypeSelector from "../../components_v2/DepotViewTypeSelector/DepotViewTypeSelector";
import OrderButton from '../../components_v2/ActionButtons/OrderButton';
import PDFButton from '../../components_v2/ActionButtons/PDFButton';
import ComparisonButton from '../../components_v2/ActionButtons/ComparisonButton';
import AlertButton from '../../components_v2/ActionButtons/AlertButton';
import BenchmarkModal from "./BenchmarkSettingsModal";

const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  guide: getGuideSelector(state)
});

const Navigation = connect(mapStateToProps)((props) => {
  const classes = useStyles();

  const {
    calculationDates,
    handleCalculationDatesChanged,
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    handleSelectedPortfolioChanged,
    selectedPortfolios,
    investmentData,
    alwaysScrollableList,
    isVirtual,
    dataLoading,
    stickyContainer,
    onGoToTradeDetails,
    instrumentListDataLoading,
    customerHasTradings,
    tradingSession,
    dispatch,
    customerDashboard,
    isCustomerApp,
    reportType,
    setReportType,
    calculationDatesType,
    benchmarkConfigurationEnabled,
    chartsSettings,
    onChartSettingsChange,
    handlePdfExportClick,
    pdfExportDisabled,
    includeHistoricalPortfolios,
    onAddToComparisonClick,
    dashboardSettingsLoaded,
    extraActions,
  } = props;

  const [benchmarkModalOpened, setBenchmarkModalOpened] = React.useState(false);

  const withOrderBtn = _.isFunction(onGoToTradeDetails) && isTradingEnabled() && !instrumentListDataLoading;
  const showDates = _.isFunction(handleCalculationDatesChanged);
  const showReportType = _.isFunction(setReportType);
  const showPortfolios = _.isFunction(handleSelectedPortfolioChanged);
  {/* sm=true -> will take all available width */}
  const itemsSize = true;

  const handleBenchmarkConfigured = (benchmarks) => {
    if (_.isEmpty(benchmarks)) {
      onChartSettingsChange('performance', 'withBenchmark', false)
    }
    if (!_.isUndefined(benchmarks)) {
      onChartSettingsChange('performance', 'currently_selected_benchmarks', benchmarks);
    }

    setBenchmarkModalOpened(false); // close modal
  };

  return (
    <Grid container spacing={2} id={'investment-navigation-component'}>
      {showPortfolios  &&
        <Grid item xs={12} sm={itemsSize} className={classes.portfoliosContainer}>
          <PortfolioList
            selectedPortfolios={selectedPortfolios}
            handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}
            portfolios={portfolios}
            portfoliosLoadingError={portfoliosLoadingError}
            portfoliosDataLoading={portfoliosDataLoading}
            alwaysScrollableList={alwaysScrollableList}
            isVirtual={isVirtual}
            loading={dataLoading}
            stickyContainer={stickyContainer}
            includeHistoricalPortfolios={includeHistoricalPortfolios}
          />
        </Grid>
      }
      {showDates &&
        <Grid item xs={12} sm={itemsSize} className={classes.timeFrameContainer}>
          <TimeFramePicker
            calculationDates={calculationDates}
            calculationDatesType={calculationDatesType}
            handleCalculationDatesChanged={handleCalculationDatesChanged}
            startOfInvestment={investmentData && investmentData.start_date}
            isVirtual={isVirtual}
            loading={dataLoading}
            isMounted={dashboardSettingsLoaded}
            stickyContainer={stickyContainer}
            dispatch={dispatch}
            timeSelectorDateChange={customerDashboard && customerDashboard.time_selector_date_change}
            showNoPriceOnWeekendsWarning
            useFilterStyles
            BenchmarkProps={{
              enabled: benchmarkConfigurationEnabled,
              showBenchmark: chartsSettings.performance.withBenchmark,
              configuration: chartsSettings.performance.currently_selected_benchmarks,
              onValueChanged: () => onChartSettingsChange('performance', 'withBenchmark', !chartsSettings.performance.withBenchmark),
              onConfigurationClick: () => setBenchmarkModalOpened(true)
            }}
          />
        </Grid>
      }
      {showReportType &&
        <Grid item xs={12} sm={itemsSize} className={classes.portfoliosContainer}>
          <DepotViewTypeSelector
            hideName={stickyContainer}
            reportType={reportType}
            setReportType={setReportType}
            loading={dataLoading}
            isMounted={dashboardSettingsLoaded}
          />
        </Grid>
      }
      {stickyContainer &&
        <Grid item style={{marginLeft: 'auto'}}>
          <Grid container spacing={1}>
            {extraActions}
            {/*<Grid item>*/}
            {/*  <AlertButton variant={"icon"}/>*/}
            {/*</Grid>*/}
            {_.isFunction(onAddToComparisonClick) && (
              <Grid item>
                <ComparisonButton
                  variant={"icon"}
                  onButtonClick={onAddToComparisonClick}
                />
              </Grid>
            )}
            {_.isFunction(handlePdfExportClick) && (
              <Grid item>
                <PDFButton
                  variant={"icon"}
                  onButtonClick={handlePdfExportClick}
                  disabled={pdfExportDisabled}/>
              </Grid>
            )}
            {withOrderBtn && (
              <Grid item>
                <OrderButton
                  customClasses={{ root: classes.buttonRoot}}
                  disabled={!customerHasTradings}
                  tradingSession={tradingSession}
                  onButtonClick={onGoToTradeDetails}
                  ordersCount={props.ordersCount}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      }

      {benchmarkModalOpened && (
        <BenchmarkModal
          onBenchmarkConfigured={handleBenchmarkConfigured}
          benchmarks={chartsSettings.performance.currently_selected_benchmarks}
        />
      )}

    </Grid>
  )
});


export function DashboardPortfoliosSelectorSharedState({children}) {

  const [selectedPortfolios, setSelectedPortfolios] = React.useState([]);
  const [selectedComponents, setSelectedComponents] = React.useState({});

  return (
    <DashboardPortfoliosSelectorContext.Provider value={{
      selectedPortfolios,
      selectedComponents,
      onSelectedPortfoliosChanged: setSelectedPortfolios,
      onSelectedComponentsChanged: setSelectedComponents
    }}>
      {children}
    </DashboardPortfoliosSelectorContext.Provider>
  )
}


export const StickyNavigation = connect(mapStateToProps)((props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.stickyContainer, props.guide.active)} id={'investment-navigation-component-sticky'}>
      <Navigation alwaysScrollableList stickyContainer {...props}/>
    </div>
  )
});


export default Navigation;



