import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from "lodash";

import {CircularProgress, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Tooltip from '../../../../components/Tooltip'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import {InfoIcon, SignatureIcon} from '../../../../images'
import { ESignResource } from '../../../../utils/api'
import {
  BUSINESS_CASE_FINISHED_STATUS_VALUE,
  BUSINESS_CASE_TYPE,
  ONBOARDING_BUSINESS_CASE_STATUS
} from './constants'
import {displayErrorSnackBar, displaySuccessSnackBar} from '../../../../components/SnackbarProvider/actions'

import useStyles from './styles'
import clsx from "clsx";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import {getMemberFieldName} from "../../../RiskProfiling/utils";
import WarningTooltip from "../../../../components/WarningTooltip";
import IconButton from "@material-ui/core/IconButton";
import {useUploadDocumentModalContext} from "../../LegalDocumentsSigningStatus";
import CustomerFullnameMenu from "../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";
import SelectContactEmailModal from '../SelectContactEmailModal/index';
import {
  mergeArchiversFromExternalResource,
  mergePDFsFromExternalResource
} from "../../../../utils/utils";


const CASE_STATUS_INTEGER =  {
  1: "Unvollständig",
  2: "Komplett",
  3: "Abgelaufen",
  4: "Abgelehnt"
};

const FILE_SYNCHRONIZATION_STATUS = {
  NOT_SIGNED: 'NOT_SIGNED',
  SIGNED: 'COMPLETED'
}

function openLink(link) {
  let a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a)
}


export default connect()(function BusinessCase(props) {

  const {
    businessCase,
    bordered,
    isRelated,
    useDepotName
  } = props;

  const {onOpenUploadDocumentModal} = useUploadDocumentModalContext();

  const [expanded, setExpanded] = React.useState(!_.isEmpty(businessCase.related_business_cases || []));
  const [shareCustomersMenuOpen, setCustomersShareMenuOpen] = React.useState(false);
  const [uploadedDocuments, setUploadedDocuments] = React.useState(
    businessCase.uploaded_documents || []);
  const [documentsLoading, setDocumentsLoading] = React.useState(false)
  const anchorRef = React.useRef(null);

  const [businessCaseDetails, setBusinessCaseDetails] = React.useState({
    data: undefined,
    loading: false,
    errors: undefined
  });
  const [selectEmailModal, setSelectEmailModal] = React.useState({
    opened: false,
    customer: {},
    userRole: undefined
  });

  const classes = useStyles();

  /**
   * In case business case has related business
   * cases inside - it is main business case (kind of holder).
   *
   * @type {boolean}
   */
  const hasRelatedBusinessCases = React.useMemo(() => {
    return businessCase.hasOwnProperty('related_business_cases') && !_.isEmpty(businessCase.related_business_cases || [])
  }, [businessCase]);

  /**
   * Convert Business case and related business cases
   * to the simple list of business cases.
   *
   * @type {Array<Object>}
   */
  const businessCasesList = React.useMemo(() => {

    if (!businessCase) {
      return []
    }

    if (hasRelatedBusinessCases) {

      let businessCases = [{
        ...businessCase,
        related_business_cases: null, // Remove related business cases to prevent infinite rendering,
        documents: businessCase.is_combined_process ? businessCase.documents : [] // Remove documents in case we group business cases, as download button will be in holder container
      }];

      return [...businessCases, ...(businessCase.related_business_cases || [])]
    }

    return [businessCase]

  }, [businessCase, hasRelatedBusinessCases]);

  /**
   * List of links (that lead to zip files) that could be used for documents downloading.
   *
   * @type {unknown}
   */
  const documentsToDownload = React.useMemo(() => {

    if (isRelated) {
      return businessCase.documents || []
    }

    const documents = _.reduce(businessCasesList, (documents, bCase) => {
      if (!_.isEmpty(bCase.documents || [])) {
        documents = documents.concat(bCase.documents)
      }
      return documents
    }, [])

    return documents

  }, [businessCase, businessCasesList])


  /**
   * Check, if for combined process there are at least one business case from EDocBox.
   * @type {boolean|*|boolean}
   */
  const combinedProcessHasShareButton = (() => {

    if (!businessCase.is_combined_process || isRelated) {
      return false
    }

    return _.some(businessCasesList, (bCase) => bCase.case_id)
  })()

  /**
   * Validate, if business case has few members.
   * @type {boolean}
   */
  const hasMembers = React.useMemo(() => {

    return !_.isEmpty(businessCase.relationships || [])

  }, [businessCase]);

  /**
   * Prepare list of customers, that belongs to business case.
   * In case main customer is Family account - family members will be used.
   * @type {unknown}
   */
  const businessCaseCustomers = React.useMemo(() => {

    return hasMembers ? businessCase.relationships : [businessCase.customer]

  }, [businessCase, hasMembers]);

  const membersFieldName = React.useMemo(() => {

    return getMemberFieldName(businessCase.customer_type)

  }, [businessCase, hasMembers]);

  /**
   * List of documents, that should be approved.
   * @type {{name: string, external_id: string|number}}
   */
  const needApprovalDocuments = React.useMemo(() => {

    let eSignDocuments = [];
    if (businessCaseDetails.data) {
      eSignDocuments = businessCaseDetails.data.documents.map((document) => document.external_id)
    }

    const needApprovalDocuments = _.filter((businessCase.need_approval_documents || []),
      (document) => !_.find(eSignDocuments, external_id => external_id == document.external_id));

    needApprovalDocuments.map(document => {

      document.depotName = !hasRelatedBusinessCases ? _.get(businessCaseDetails, 'data.depot.name') : undefined;

      // if document manually uploaded set it's path and process as signed
      const uploadedDocument = _.find(uploadedDocuments, (d) => d.document_external_id == document.external_id);
      if (!_.isNil(uploadedDocument)){
        document.path = uploadedDocument.document_path;
        document.status = FILE_SYNCHRONIZATION_STATUS.SIGNED;
        document.isDocumentUploaded = true;
      }
    });

    return needApprovalDocuments;

  }, [businessCase, businessCaseDetails.data, uploadedDocuments]);

  const depotName = _.get(businessCase, 'depot.name');

  /**
   * Open document storage link to trigger document download process.
   * @param event
   */
  const handleDownloadDocumentsButtonClick = async (event) => {
    event.stopPropagation();
    try {
      setDocumentsLoading(true)
      // for current BC we fetch details. If it has nested business cases details for them are fetched too
      let details = await fetchDetails()

      // create zip name
      let customerFullName = _.get(businessCase, 'customer.customer_full_name') || '';
      customerFullName = customerFullName.replace(',', '');
      let customerId = _.get(businessCase, 'customer.customer_id') || '';
      const zipName = `Sammelorder - ${customerFullName} ${customerId} ${Date.now()}.zip`;

      if (!businessCase.has_documents_to_download) {
        // for old business cases use old logic
        documentsToDownload.length > 1
          ? await mergeArchiversFromExternalResource(documentsToDownload, zipName)
          : documentsToDownload.forEach((path) => {openLink(path)})
      } else {
        // all business cases created after BCA-6848 will have has_documents_to_download

        // details contain documents objects with pathes to document on azure
        let documentsToDownloadInZip = _.concat(_.get(details, 'documents', []), needApprovalDocuments)
          .filter(document => !_.isNil(document.path))
          .map((document) => {
            document.preffix = hasRelatedBusinessCases ? document.depotName : undefined;
            return document;
          })
        await mergePDFsFromExternalResource(documentsToDownloadInZip, zipName, !isRelated)
      }

    } catch (error) {
      console.error(error);
      props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen der Dokumente'))  // downloading documents error
    } finally {
      setDocumentsLoading(false)
    }
  };

  /**
   * Upload signed documents to be visible for approver.
   * @param {number|string} e_doc_box_document_id Identifier of the document in eDocBox
   * @param event
   */
  const handleUploadDocumentsButtonClick = async (event, document_name, external_document_id, e_doc_box_document_id=undefined) => {
    event.stopPropagation();

    onOpenUploadDocumentModal && onOpenUploadDocumentModal(
      businessCase.id, document_name, e_doc_box_document_id, external_document_id, (uploadedDocuments) => {
        props.dispatch(displaySuccessSnackBar('Dateie wurden erfolgreich hochgeladen'));
        e_doc_box_document_id && _removeDocumentFromEDocBoxDocuments(external_document_id);
        setUploadedDocuments(uploadedDocuments)
      }, (errors) => {
        props.dispatch(displayErrorSnackBar('Beim hochladen der Dateien ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal'))
      })
  };

  /**
   * Filter out document from list pof documents, that located on edocbox
   * @param {string|number} external_document_id External document identifier
   * @private
   */
  const _removeDocumentFromEDocBoxDocuments = (external_document_id) => {

    const _businessCaseDetails = _.cloneDeep(businessCaseDetails.data);
    _businessCaseDetails.documents = _.filter(_businessCaseDetails.documents,
      (document) => document.external_id != external_document_id);

    setBusinessCaseDetails({
      ...businessCaseDetails,
      data: _businessCaseDetails
    })
  };

  /**
   * Fetch document editor launch link.
   * @param document Document data
   * @param userRole {'account_holder_1'|'account_holder_2'|'broker'|'legal_guardian_1'|'legal_guardian_2'} User role
   * @return {Promise<void>}
   */
  const _fetchDocumentEditorLaunchLink = async (document, userRole) => {

    const signatureFields = _.get(document, `signature_fields_roles.${userRole}`);

    try {

      const response = await ESignResource.getBusinessCaseEditorLaunchUrl(
        businessCase.id, document.id, Object.keys(signatureFields).map(key => ({[key]: _.get(signatureFields[key], 'type', null)})));

      openLink(response)

    } catch (error) {
      let error_msg = _.get(error, 'data.error', 'Error during editor launch link generation.');
      props.dispatch(displayErrorSnackBar(error_msg))

    }
  };

  /**
   * Share business case to customer.
   * @param customer Customer data
   * @param userRole {'account_holder_1'|'account_holder_2'|'legal_guardian_1'|'legal_guardian_2'} User role
   * @param email - email address for send_to
   * @param mailText - custom email text
   * @return {Promise<void>}
   * @private
   */
  const _shareBusinessCaseToCustomer = async (customer, userRole, email, mailText) => {

    try {

      if (!combinedProcessHasShareButton) {
        await ESignResource.shareBusinessCase(businessCase.id, customer.customer_id, userRole, email, mailText);
      } else {
        const businessCasesToFetch = businessCasesList.filter((bCase) => !!bCase.case_id)
        await Promise.all(businessCasesToFetch
          .map((bCase) => ESignResource.shareBusinessCase(bCase.id, customer.customer_id, userRole, email, mailText)))
      }

      props.dispatch(displaySuccessSnackBar('Nachricht erfolgreich gesendet'))

    } catch (error) {
      const errorMessage = _.get(error, 'data.detail') || 'Ein Fehler ist beim Weiterleiten des Dokumentes aufgetreten.';
      props.dispatch(displayErrorSnackBar(errorMessage))
    }
  };

  const validateSignatureFields = (document, userRole) => {
    return !_.isEmpty(_.get(document, `signature_fields_roles.${userRole}`) || []);
  };

  /**
   * Render button to open document editor launchh url.
   * @param document Document data
   * @param userRole {'account_holder_1'|'account_holder_2'|'broker'|'legal_guardian_1'|'legal_guardian_2'} User role
   * @return {JSX.Element|null}
   */
  const renderDocumentEditorLaunchButton = (document, userRole) => {

    if(!validateSignatureFields(document, userRole)) return null;

    return (
      <PrimaryButton
        icon={<SignatureIcon color={"#0092E5"}/>}
        onButtonClick={() => _fetchDocumentEditorLaunchLink(document, userRole)}
        variant="outlined"
        customClasses={{
          root: clsx(classes.buttonRoot, classes.buttonRootSign)
        }}
      />
    )
  };

  const handleShareBusinessCaseButtonClick = React.useCallback((event) => {

    event.stopPropagation();

    if (businessCase.url) {
      let mailto = businessCase.customer.email;
      let subject = 'E-Unterschreiben';
      let body = businessCase.url;

      window.open(`mailto:${mailto}?subject=${subject}&body=${body}`, '_blank');
      return
    }

    if (businessCaseCustomers.length === 1) {
      fetchDetails();
      setSelectEmailModal({
        opened: true,
        customer: businessCaseCustomers[0],
        userRole: 'account_holder_1',
      });
      return;
    }

    setCustomersShareMenuOpen(true)

  }, [businessCaseCustomers]);

  const handleSignDocumentsButtonClick = (event) => {
    event.stopPropagation();
    if (businessCase.url) {
      window.open(businessCase.url, '_blank');
    }
  };

  const fetchDetails = () => {

    if (!businessCase.case_id) {
      return;
    }

    let response = undefined;
    if (businessCase.type == BUSINESS_CASE_TYPE.INTERNAL) {
      if (!combinedProcessHasShareButton) {
        response = fetchBusinessCaseDetails()
      } else {
        response = fetchCombinedProcessBusinessCaseDetails()
      }
    } else if (businessCase.type == BUSINESS_CASE_TYPE.ONBOARDING) {
      response = fetchOnboardingBusinessCaseDetails()
    }
    return response
  };

  const businessCaseEmpty = !hasRelatedBusinessCases && !businessCase.case_id && !businessCase.url && !businessCase.approval;

  const handleDetailsExpanded = (event, expanded) => {

    // Prevent expand event in case user click on disabled button
    if (event.target.getAttribute('data-stop-event')) {
      return
    }


    if (businessCaseEmpty) {
      return
    }

    setExpanded(expanded);

    if (expanded && !businessCaseDetails.data && !businessCase.loading && businessCase.case_id) {
      fetchDetails()
    }
  };

  const fetchCombinedProcessBusinessCaseDetails = async () => {
    setBusinessCaseDetails({
      data: undefined,
      errors: undefined,
      loading: true
    });
    let response = undefined;
    try {
      const businessCasesToFetch = businessCasesList.filter((bCase) => !!bCase.case_id)
      response = await Promise.all(businessCasesToFetch.map((bCase) => fetchBusinessCaseDetails(bCase.id, false, false)))

      response = response.reduce((result, bCaseResponse) => {
        return {
          ...bCaseResponse,
          documents: (result.documents || []).concat((bCaseResponse.documents || []).map((document) => {
            document.depotName = _.get(bCaseResponse, 'depot.name');
            return document;
          }))
        }
      }, {})

      setBusinessCaseDetails({
        data: response,
        errors: undefined,
        loading: false
      });
    } catch (errors) {
      setBusinessCaseDetails({
        data: response,
        errors,
        loading: false
      });
      props.dispatch(displayErrorSnackBar(
        'Der E-Signatur-Status konnte nicht abgerufen werden.'))
    }

    return response
  }

  const fetchBusinessCaseDetails = async (caseId, setToState=true, displayErrorSnackbar=true) => {
    setToState && setBusinessCaseDetails({
      data: undefined,
      errors: undefined,
      loading: true
    });

    let response = undefined;
    try {

      response = await ESignResource.getBusinessCaseDetails(caseId || businessCase.id);

      setToState && setBusinessCaseDetails({
        data: response,
        errors: undefined,
        loading: false
      })

    } catch (errors) {
      setToState && setBusinessCaseDetails({
        data: response,
        errors,
        loading: false
      });
      displayErrorSnackbar && props.dispatch(displayErrorSnackBar(
        'Der E-Signatur-Status konnte nicht abgerufen werden.'))
    }

    return response
  };

  const fetchOnboardingBusinessCaseDetails = async () => {
    setBusinessCaseDetails({
      data: undefined,
      errors: undefined,
      loading: true
    });

    let response = undefined;
    try {

      response = await ESignResource.getOnboardingBusinessCaseDetails(businessCase.id);

      setBusinessCaseDetails({
        data: response,
        errors: undefined,
        loading: false
      })

    } catch (errors) {
      setBusinessCaseDetails({
        data: response,
        errors,
        loading: false
      });
      props.dispatch(displayErrorSnackBar('Der E-Signatur-Status konnte nicht abgerufen werden.'))
    }

    return response
  };

  const renderStatusIcon = (status) => {
    if (isDocumentSigned(status)) {
      return <i class="fal fa-check" style={{color: '#23D374', fontSize: 19}} />
    }

    return <i class="fal fa-times" style={{color: '#E50000', fontSize: 21}} />
  };

  const getGeneralStatusName = () => {
    if (businessCase.type == BUSINESS_CASE_TYPE.INTERNAL) {
      return businessCaseDetails.data.status_text || (isDocumentSigned(businessCaseDetails.data.status) ? 'Komplett' : 'Unvollständig')
    } else {
      return businessCaseDetails.data.status && ONBOARDING_BUSINESS_CASE_STATUS[businessCaseDetails.data.status]
    }
  };

  const getOnlineStatus = () => {
    return (
      <>
        {ONBOARDING_BUSINESS_CASE_STATUS[_.get(businessCaseDetails, 'data.online_status', businessCase.online_status)]}
        {getStatusHistory()}
      </>
    )
  }

  const getHeaderStatus = () => {
    if (businessCaseDetails.data) {
      return getGeneralStatusName()
    } else if (businessCase.type === BUSINESS_CASE_TYPE.INTERNAL) {
      return businessCase.status_text || CASE_STATUS_INTEGER[businessCase.status]
    } else {
      return businessCase.status && ONBOARDING_BUSINESS_CASE_STATUS[businessCase.status]
    }
  };

  const getStatusHistory = () => {
    let statusHistory = _.get(businessCaseDetails, 'data.status_history', []);
    if (!statusHistory.length) {
      let defaultOnlineHistory = businessCase.online_status_history || []
      statusHistory = _.get(businessCaseDetails, 'data.online_status_history', defaultOnlineHistory)
    }
    statusHistory = _.uniq(statusHistory);
    if (statusHistory.length > 1) {
      return (
        <span style={{marginLeft: '5px'}}>
          <WarningTooltip title={
              <ul className={classes.tooltipStatusList}>
                {statusHistory.map(item => <li>{ONBOARDING_BUSINESS_CASE_STATUS[item]}</li>)}
              </ul>
          } size={16} />
        </span>
      )
    } else {
      return null
    }
  };

  const shareBusinessCaseDisabled = !businessCase.url && [_.get(businessCaseDetails, 'data.status'), businessCase.status].includes(BUSINESS_CASE_FINISHED_STATUS_VALUE);
  const shareBusinessHasNoEmail = !_.some(businessCaseCustomers, (customer) => !!customer.email);
  const shareBusinessCaseButton = (
    <PrimaryButton
      text="An Kunden senden"
      icon={<i class="far fa-envelope"  style={{color: shareBusinessCaseDisabled || shareBusinessHasNoEmail ? 'rgba(0, 0, 0, 0.26)' : '#0092E5'}} />}
      variant="outlined"
      onButtonClick={handleShareBusinessCaseButtonClick}
      disabled={shareBusinessCaseDisabled || shareBusinessHasNoEmail}
      customClasses={{
        root: classes.buttonRoot
      }}
      onRef={anchorRef}
    />
  );

  const isDocumentSigned = (status) => {
    return status === FILE_SYNCHRONIZATION_STATUS.SIGNED
  }

  /**
   * Validate, if document should be approved
   * @param {{external_id: Number|String}} document Document data
   * @returns {boolean}
   */
  const documentApprovalRequired = (document) => {
    if (_.isEmpty(businessCase.need_approval_documents)) {
      return false
    }

    return !!_.find(businessCase.need_approval_documents,
      (approvalDocument) => approvalDocument.external_id == document.external_id)
  };

  const handleShareBusinessCase = (customer, userRole, email, mailText) => {
    _shareBusinessCaseToCustomer(customer, userRole, email, mailText);
    setSelectEmailModal({
      opened: false,
      customer: {},
      userRole: undefined
    })
  };

  const TooltipWithBtn = (props) => {
    return (
      <Tooltip title={props.title}>
        <span className={clsx(classes.previewButton, classes.infoIcon)}>
          <IconButton
            className={classes.uploadDocumentIcon}
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.icon || (<DownloadIcon className={classes.downloadIcon} style={props.iconStyles}/>)}
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const DocumentRow = ({document}) => {
    const isDocumentUploaded = !!document.isDocumentUploaded;
    let isSigned = isDocumentSigned(document.status);

    return <>
      <Grid item xs={businessCase.url ? 9 : businessCaseCustomers.length > 1 ? 6 : 7}>
        <Grid container justifyContent="space-around" alignItems={'center'}>
        <Grid item xs={9} style={{display: 'flex', alignItems: 'center'}}>
          <p className={classes.status} style={{marginLeft: 10, display: 'inline-block'}}>
            { document.name }
          </p>
          { document.document_status && (
            <span style={{marginLeft: '5px'}}>
              <WarningTooltip title={document.document_status} size={16} />
            </span>
          )}
        </Grid>

        <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
          <Grid item xs={6}>
          {/* upload icon */}
          {documentApprovalRequired(document) && businessCase.approval && !document.is_online && !isSigned && onOpenUploadDocumentModal && (
            <TooltipWithBtn
              title={isDocumentUploaded ? 'Die Datei wurde bereits hochgeladen' : 'Dokument in signierter Form hochladen.'}
              onClick={(event) => handleUploadDocumentsButtonClick(event, document.name, document.external_id, document.id)}
              disabled={isDocumentUploaded}
              iconStyles={{
                transform: 'rotate(180deg)',
                ...(isDocumentUploaded ? {color: '#23D374'} : {})
              }}
            />
          )}
          </Grid>
          {/* download icon */}
          <Grid item xs={6}>
          {businessCase.has_documents_to_download && document.path &&
            <TooltipWithBtn
              title={isSigned ? 'Das Dokument mit Unterschrift steht zur Verfügung und kann heruntergeladen werden.' : 'Das Dokument ohne Unterschrift steht zur Verfügung und kann heruntergeladen werden.'}
              onClick={(_event) => openLink(document.path)}
              iconStyles={{color: isSigned ? '#23D374' : '#0092E5' }}
            />
          }
          </Grid>
        </Grid>
        </Grid>
      </Grid>
      {!businessCase.url && (
        <>
          {businessCaseCustomers.map((customer, index) => (
            <Grid item xs={1}>
              {renderDocumentEditorLaunchButton(document, `${membersFieldName}_${index + 1}`)}
            </Grid>
          ))}
          <Grid item xs={1}>
            {renderDocumentEditorLaunchButton(document, 'broker')}
          </Grid>
        </>
      )}
      <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
        {!_.isNil(document.status) && <b>{ renderStatusIcon(document.status) }</b>}
      </Grid>
    </>
  };

  return (
    <div className={classes.container}>
      <Accordion
        onChange={handleDetailsExpanded}
        expanded={expanded}
        classes={{
          root: clsx(classes.accordionRoot, bordered && classes.bordered)
        }}
      >
        {/* header of the accordion */}
        <AccordionSummary
          expandIcon={businessCaseEmpty ? <ExpandMoreIcon style={{color: '#F7F7F7'}} /> : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{
            root: classes.accordionSummaryExpanded
          }}
        >
          {/* row of the header */}
          <Grid container style={{alignItems: 'center'}}>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={6} sm={3} className={classes.textItemContainer}>
                    <Typography className={classes.heading}>
                      {!isRelated && (
                        <>
                          {businessCase && (
                            <CustomerFullnameMenu
                              customer={businessCase.customer}
                              customerFullname={businessCase.customer.customer_full_name}
                            />)
                          }
                        </>
                      )}
                      {isRelated && businessCase.is_combined_process && (
                        <>{businessCase.name || ''}</>
                      )}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3} className={classes.textItemContainer}>
                    <Typography className={classes.heading_help}>
                      {!isRelated && (
                        <>
                          Erstellt am: { businessCase && moment(businessCase.created_at).format('DD.MM.YYYY') }
                        </>
                      )}
                      {(useDepotName && !!depotName) && depotName}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2} className={classes.textItemContainer}>
                    <Typography className={classes.heading_help}>
                      {!hasRelatedBusinessCases && (businessCase.case_id || businessCase.url) && (
                          <>
                            {businessCase.type == BUSINESS_CASE_TYPE.INTERNAL && (
                              <span>Gültig: { businessCase && businessCase.valid_period } Tage </span>
                            )}
                          </>
                      )}
                    </Typography>
                </Grid>

                {[getHeaderStatus, getOnlineStatus].map(renderStatusLabel => {
                  return (
                    <Grid item xs={3} sm={2} className={classes.textItemContainer}>
                      {!hasRelatedBusinessCases && (
                        <Typography className={classes.heading_help}>
                          { businessCase && renderStatusLabel() }
                        </Typography>
                      )}
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.buttonsContainer}>
              {(businessCase.has_documents_to_download || !_.isEmpty(businessCase.documents || [])) && (
                <PrimaryButton
                  disabled={documentsLoading}
                  text="Herunterladen"
                  icon={<DownloadIcon className={classes.downloadIcon} style={{color: documentsLoading ? 'rgba(0, 0, 0, 0.26)' : "#0092E5"}}/>}
                  variant="outlined"
                  onButtonClick={handleDownloadDocumentsButtonClick}
                  customClasses={{
                    root: classes.buttonRoot
                  }}
                />
              )}
              {(!hasRelatedBusinessCases || combinedProcessHasShareButton) && (
                <>
                  {businessCase.url && (
                    <PrimaryButton
                      text="E-Unterschreiben"
                      icon={<SignatureIcon color={"#0092E5"}/>}
                      variant="outlined"
                      onButtonClick={handleSignDocumentsButtonClick}
                      customClasses={{
                        root: classes.buttonRoot
                      }}
                    />
                  )}
                  {(combinedProcessHasShareButton || businessCase.case_id || businessCase.url) && (
                    <>
                      {shareBusinessCaseDisabled || shareBusinessHasNoEmail ? (
                        <Tooltip title={shareBusinessHasNoEmail
                          ? "Diese Versandoption ist nicht verfügbar, da für diesen Kunden keine E-Mail Adresse hinterlegt ist."
                          : "Der Vorgang is abgeschlossen. Ein Teilen ist nicht mehr möglich."}>
                          <span data-stop-event={true}>
                            { shareBusinessCaseButton }
                          </span>
                        </Tooltip>
                      ) : (
                        <>
                          { shareBusinessCaseButton }
                        </>
                      )}
                    </>
                  )}
                  <Popper open={shareCustomersMenuOpen} anchorEl={anchorRef.current}>
                    <Paper>
                      <ClickAwayListener onClickAway={() => setCustomersShareMenuOpen(false)}>
                        <MenuList>
                          {_.filter(businessCaseCustomers, (customer) => !!customer.email)
                            .map((customer, index) => (
                            <MenuItem
                              className={classes.customerMenuItem}
                              key={customer.customer_id}
                              onClick={(event) => {
                                event.stopPropagation();
                                setCustomersShareMenuOpen(false);
                                fetchDetails();
                                setSelectEmailModal({
                                  opened: true,
                                  customer: customer,
                                  userRole: `${membersFieldName}_${index+1}`
                                })
                              }}
                            >
                              {customer.customer_full_name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                </>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>

        {/* body of the accordion */}
        <AccordionDetails className={clsx(bordered && hasRelatedBusinessCases && classes.accordionDetailsSkipPadding)}>
          {!hasRelatedBusinessCases ? (
            <>
              {
                businessCaseDetails.loading ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress color="primary"/>
                  </div>
                ) : (
                  <Grid container spacing={2} style={{padding: '22px 0 0'}}>
                    {businessCaseDetails.data && (
                      <>
                        {/* first row (header of 'columns') */}
                        <>
                        <Grid item xs={businessCase.url ? 9 : businessCaseCustomers.length > 1 ? 6 : 7}>
                          <b>Allgemeiner Status</b>
                          <IconButton size={'small'} title={'Status aktualisieren'} onClick={fetchDetails} color={'primary'}>
                            <i className={'fa fa-refresh'} />
                          </IconButton>
                        </Grid>
                        {!businessCase.url && (
                          <>
                            {businessCaseCustomers.map((customer) => (
                              <Grid item xs={1}>
                                <b>{ customer.customer_full_name || '' }</b>
                              </Grid>
                            ))}
                            <Grid item xs={1}>
                              <b>Berater</b>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                           <span style={{textAlign: 'end'}}>
                             <b>E-Unterschrift-Status <br /> { getGeneralStatusName() }</b>
                            </span>
                        </Grid>
                        </>

                        {/* render row for each doc */}
                        { businessCaseDetails.data.documents && businessCaseDetails.data.documents.map(document => (
                          <DocumentRow document={document} />
                        )) }


                      </>
                    )}
                    {businessCase.approval && !_.isEmpty(needApprovalDocuments) && (
                      <>
                        <Grid item xs={12}><b>Formulare zur Freigabe</b></Grid>
                        {needApprovalDocuments.map((document) => (
                          <DocumentRow document={document} />
                        ))}
                      </>
                    )}
                  </Grid>
                )
              }
            </>
          ) : (
            <Grid container>
              {businessCasesList.map((businessCase) => (
                <Grid item xs={12} style={{marginTop: 5}}>
                  <BusinessCase businessCase={businessCase} isRelated dispatch={props.dispatch} bordered={true} useDepotName={true}/>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <SelectContactEmailModal
        selectEmailModal={selectEmailModal}
        handleModalClose={() => setSelectEmailModal({
          opened: false,
          customer: {},
          userRole: undefined
        })}
        handleShareBusinessCase={handleShareBusinessCase}
        businessCaseDetails={businessCaseDetails}
        validateSignatureFields={validateSignatureFields}
      />
    </div>
  )
})
