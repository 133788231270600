import React from 'react';
import connect from "react-redux/es/connect/connect";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton";
import {DISTRIBUTION_TYPES} from "../../../../../DashboardSettings/components/CustomersList/components/DistributionType/constants";
import {SendReportUtils} from "../../../../SendReportUtils";
import SuccessSendDialog from "../SuccessSendDialog";
import Snackbar from "../../../../../../components/Snackbar";
import _ from 'lodash';
import { CircularProgress, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { styles } from './styles';

const mapStateToProps = (state) => ({
  groups: state.get('reporting').toJS().groups
});

const SendReportButton = props => {
  const {
    reportSettings,
    groups,
    classes,
    isButtonDisabled
  } = props;

  const [successMessage, setSuccessMessage] = React.useState(undefined);
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const getButtonText = () => {
    const distributionType = (!_.isEmpty(reportSettings) && reportSettings.distribution_type);
    const startWord = groups && groups.length ? 'Reports' : 'Report';
    if(reportSettings.is_auto_report) {
      return 'Vorschau herunterladen';
    }
    if (distributionType === DISTRIBUTION_TYPES.POSTBOX.value) {
      return `${startWord} in elektronisches Kundenpostfach versenden`;
    }
    return `${startWord} als PDF herunterladen`;
  };

  const handleSendReport = async () => {
    if (!_.isEmpty(reportSettings)) {
      setIsLoading(true);
      // create report for customer
      if(_.get(reportSettings, 'customer_id')){
        return SendReportUtils.createCustomerReport(reportSettings, _.get(reportSettings, 'portfolio_ids', undefined))
          .then(message => setSuccessMessage(message))
          .catch(message => setErrorMessage(message))
          .finally(() => setIsLoading(false));
      }


      // create report for group
      return SendReportUtils.createGroupReport(reportSettings)
        .then(message => setSuccessMessage(message))
        .catch(message => setErrorMessage(message))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <PrimaryButton
        id="reporting-send-report-btn-tour-element"
        onButtonClick={handleSendReport}
        icon={<ArrowDownwardIcon />}
        text={getButtonText()}
        disabled={isButtonDisabled}
        customClasses={props.customClasses}
        style={props.style}
      />

      <SuccessSendDialog
        open={!!successMessage}
        message={successMessage}
        handleClose={() => setSuccessMessage(undefined)}
      />

      <Snackbar
        open={!!errorMessage}
        variant='error'
        message={errorMessage}
        handleClose={() => setErrorMessage(undefined)}
      />

      <Dialog open={isLoading} disableBackdropClick>
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
          Bitte warten Sie einen Moment.
        </div>
      </Dialog>
    </>
  )
};

export default connect(mapStateToProps)(withStyles(styles)(SendReportButton));