import { makeStyles } from '@material-ui/core';

const useAggregatedReturnSectionStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  },
  value: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
  }
}));

export default useAggregatedReturnSectionStyles;