import React from 'react';
import { withRouter } from 'react-router';
import _ from "lodash";
import { connect } from "react-redux";

import withWidth from "@material-ui/core/withWidth/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import {
  AGGREGATED_PORTFOLIO_ID,
  hasResponseError, withPercentOrDash
} from "../../../../../../utils/utils";
import {BENCHMARK_CHART_COLOR} from "../../../../../../utils/constants";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import {getPortfolioChartName} from "../../../../utils";
import moment from 'moment';

import { HOST_RELATED_SHARED_SETTINGS_KEYS } from '../../../../../../components/SharedSettingsProvider/constants';
import {Chip, Grid} from "@material-ui/core";
import {setTimeSelectorDateChange} from "../../../../actions";
import clsx from "clsx";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import {aggregateSelectedDisabledExplanation} from "../../../../../DashboardSettings/constants";
import {getGuideSelector} from "../../../../CustomerDashboard";
import {getCustomerDashboardSelector} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import {createSelector} from "reselect";
import AggregatedDepotsSwitch from "../../../../components_v2/AggregatedDepotsSwitch/AggregatedDepotsSwitch";
import HTMLHostRelatedSharedSetting from "../../../../../../components/HTMLHostRelatedSharedSettingV2";
import ChartSectionBordered from "../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import Legend from "../../../../components_v2/Legend/Legend";
import {
  _PerformanceLineChart
} from "../../../../../CustomerDashboard/components/Widgets/components/PerformanceLineChart";
import {useTimeWeightedReturnData} from "../../../../hooks/useTimeWeightedReturnData";
import TimeWeightedPerformanceTable
  from "../../../../components_v2/TimeWeightedPerformanceTable/TimeWeightedPerformanceTable";

const getBenchmarkConfiguration = (state) => state.get('benchmarkConfiguration')
export const getBenchmarkConfigurationSelector = createSelector(
  [getBenchmarkConfiguration],
  (benchmarkConfiguration) => benchmarkConfiguration.toJS()
)


const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  benchmarkConfiguration: getBenchmarkConfigurationSelector(state),
  guide: getGuideSelector(state),
});


const _performanceChartPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.chartData.timestamp === nextProps.chartData.timestamp
    && prevProps.startDate === nextProps.startDate
    && prevProps.endDate === nextProps.endDate
}

const PerformanceLineChart = (props) => {
  const {
    classes,
    dispatch,
    width,
    isCustomerApp,
    guide,
    onChartSettingsChange,
    onExpanded,
    expanded,
    showBenchmark,
    aggregateSelectedPortfolios,
    isCumulativeDisplaySwitchDisabled,
    isVirtual,
    customerId,
    benchmarks,
    startDate,
    endDate,
    dataProvider,
    portfolios,
    withHistorical,
    showPerformanceTable,
    aggregatedPortfolioName,
    investmentStrategyId,
  } = props;

  const timeWeightedData = useTimeWeightedReturnData(
    customerId, portfolios, startDate, endDate, withHistorical, benchmarks, dataProvider, investmentStrategyId);

  const [
    dashboardData,
    dashboardDataLoading,
    dashboardDataLoadingError,
    timestamp
  ] = [
    timeWeightedData.data,
    timeWeightedData.loading,
    timeWeightedData.errors,
    timeWeightedData.updatedAt
  ]

  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    data: [],
    error: false,
    showStartOfBenchmark: false,
    startOfBenchmark: undefined
  });

  React.useEffect(() => {
    if (requiredDataExist()) {
      const data = getData();

      setReloadTrigger(true);

      setTimeout(() => {
        setChartData({
          data: data.preparedData,
          error: data.hasError,
          timestamp: new Date().getTime(),
          showStartOfBenchmark: data.showStartOfBenchmark,
          startOfBenchmark: data.startOfBenchmark
        });

        setTimeout(() => {
          setReloadTrigger(false);
        }, 100);
      }, 100);

    } else {
      setChartData({...chartData, error: true});
    }
  }, [dashboardData, showBenchmark, aggregateSelectedPortfolios, timestamp]);

  const dashboardPortfolios = dashboardData && dashboardData.portfolios || [];
  const portfoliosWithoutAggregated = dashboardPortfolios.filter(p => p.id !== AGGREGATED_PORTFOLIO_ID);

  const getData = () => {
    if (isBenchmarkConfigurationEnabled()) {
      let preparedData = [];
      let hasError = false;
      let benchmark = undefined;
      let showStartOfBenchmark = false;
      let startOfBenchmark = undefined;

      if (!_.isUndefined(dashboardData)) {
        hasError = dashboardData.portfolios.some((portfolio) => hasResponseError(portfolio.timeseries));

        benchmark = dashboardData.portfolios.find(p => p.id === AGGREGATED_PORTFOLIO_ID && !_.isEmpty(p.benchmark_performance_detailed)) ||
          portfoliosWithoutAggregated.reduce((prev, current) => {
            return moment(prev.investment_period.start).isSameOrBefore(moment(current.investment_period.start)) ? prev : current;
          });
        const portfolios = aggregateSelectedPortfolios ?  dashboardData.portfolios.filter(p => p.id === AGGREGATED_PORTFOLIO_ID) : portfoliosWithoutAggregated

        if (portfolios && !hasError) {
          preparedData = portfolios.map(portfolio => ({
            name: getPortfolioChartName(portfolio, aggregatedPortfolioName),
            color: portfolio.color,
            portfolio: preparePortfolioTimeseries(portfolio.timeseries.portfolio),
            id: portfolio.id
          }));
        }
      }

      if (benchmark && benchmark.benchmark_performance_detailed && isBenchmarkConfigurationEnabled() && showBenchmark) {
        if (hasResponseError(benchmark.benchmark_performance_detailed)){
          hasError = true;
        } else {
          if (benchmark.benchmark_performance_detailed.portfolio) {
            const benchmarkTrackingStartDate = benchmark.benchmark_performance_detailed.tracking_start_date;
            const portfolioStartDate = _.get(benchmark.timeseries.portfolio[0], 'date');

            if (benchmarkTrackingStartDate && portfolioStartDate && moment(benchmarkTrackingStartDate).isAfter(portfolioStartDate)) {
              startOfBenchmark = {date: benchmarkTrackingStartDate, value: 0};
              showStartOfBenchmark = true;
            }
            preparedData.push({
              name: benchmark.benchmark_name || "Benchmark",
              color: BENCHMARK_CHART_COLOR,
              portfolio: preparePortfolioTimeseries(benchmark.benchmark_performance_detailed.portfolio)
            });
          }
        }
      }

      return {
        hasError,
        preparedData,
        showStartOfBenchmark,
        startOfBenchmark,
      }
    }
  };

  const preparePortfolioTimeseries = (timeseries) => timeseries.map(value => ({date: value.date, value: (value.cum_ret_g || 0) * 100}));

  const isBenchmarkConfigurationEnabled = () => {
    if (_.isArray(dashboardData && dashboardData.portfolios)) {
      return aggregateSelectedPortfolios || dashboardData.portfolios.length > 0;
    }
    return false;
  };

  const requiredDataExist = () => {
    if (_.isArray(dashboardData && dashboardData.portfolios) && !_.isEmpty(dashboardData.portfolios)) {
      return dashboardData.portfolios.every(portfolio => !!portfolio.timeseries);
    }
  };

  let timeout = React.useRef(null);
  const onDatesChange = (dates) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      dispatch(setTimeSelectorDateChange(dates))
    }, 100)
  }


  const handleAggregateSelectedChange = (checked) => {
    onChartSettingsChange('performance', 'aggregateSelected', checked);
  }

  const minHeight = ['xs', 'sm', 'md'].includes(width) ? 520 : 486;

  const empty = chartData.data && chartData.data.every(item => item.portfolio.length === 0);

  const getCurrentPerformanceChip = () => {
    if (!dashboardData) {
      return null;
    }

    const aggregatedPortfolio = dashboardData.portfolios.find(p => p.id === AGGREGATED_PORTFOLIO_ID);
    if (!aggregatedPortfolio) {
      return null
    }

    const value = _.get(aggregatedPortfolio, 'timeseries.return') || 0;
    const isValuePositive = value >= 0;

    return (
      <Chip
        label={`${isValuePositive > 0 ? "+" : ""}${withPercentOrDash(value, true)}`}
        className={clsx(classes.chip, isValuePositive ? 'green' : 'red')}
      />
    )
  }

  return (
    <>
      <ChartSectionBordered
        title={(
          <>
            <b>{props.title || "Zeitgewichtete Rendite in %"}</b>
            <WarningTooltip
              title={<HTMLHostRelatedSharedSetting
                sharedSettingKey={HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_PERFORMANCE_CHART_EXPLANATION}
              />}
              icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
              width={600}
              interactive
            />
            {getCurrentPerformanceChip()}
          </>
        )}
        titleControl={(
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <AggregatedDepotsSwitch
                disabled={isCumulativeDisplaySwitchDisabled}
                value={aggregateSelectedPortfolios}
                onChange={handleAggregateSelectedChange}
                tooltip={(!dashboardDataLoading && isCumulativeDisplaySwitchDisabled) ? aggregateSelectedDisabledExplanation : undefined}
              />
            </Grid>
          </Grid>
        )}
        content={dashboardData && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <_PerformanceLineChart
                chartData={chartData}
                onDatesChange={onDatesChange}
                dispatch={dispatch}
                classes={classes}
                withLegend={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {!_.isEmpty(chartData.data) && (
                <Legend data={chartData.data}/>
              )}
            </Grid>
            {showPerformanceTable && (
              <Grid item xs={12}>
                <TimeWeightedPerformanceTable
                  customerId={customerId}
                  portfolios={portfolios}
                  withHistorical={withHistorical}
                  dataProvider={dataProvider}
                  aggregatedPortfolioName={aggregatedPortfolioName}
                  investmentStrategyId={investmentStrategyId}
                />
              </Grid>
            )}
          </Grid>
        )}
        loading={dashboardDataLoading || reloadTrigger}
        error={dashboardDataLoadingError || chartData.error}
        minHeight={minHeight}
        empty={empty}
        displayError={true}
        expanded={expanded}
        onExpanded={onExpanded}
      />
    </>
  );
};

export default withWidth()(
  withStyles(styles)(
    connect(mapStateToProps)(
      withRouter(PerformanceLineChart)
    )
  )
);