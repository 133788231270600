import React from "react";
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";

import Skeleton from "@material-ui/lab/Skeleton";
import {Chip, makeStyles, Grid} from "@material-ui/core";
import {openMorningStartIsin, withEuroOrDash, withPercentOrDash} from "../../../utils/utils";
import {
  DepotSubNumberCell,
  IsinCell, SustainabilityCell
} from '../../../utils/commonTableColumns';
import {SAVING_PLANS_ACTIONS} from "../../Charts/InstrumentsAllocationTable/constants";
import {InstrumentNameCell} from "../../../containers/Modelportfolios/components/InstrumentsList/table-data";
import {ORDER_EXIST_TOOLTIP, TradingAction} from "../InstrumentsAllocationTable/table-data";


const RowNumberCell = {
  header: 'Nr.',
  body: {
    content: (item, options, index) => {
      if (item.isSubComponent) {
        return <i className="far fa-level-up" style={{transform: 'rotate(90deg)', marginLeft: 5}}></i>
      }
      return (item.isModelportfolio || item.isHeader) ? null : `${index + 1}.`
    },
  },
  key: 'RowNumberCell'
}

const ProductNameCell = {
  header: 'Produktname',
  body: {
    content: (item) => item.name,
    onClick: (item, options) => !item.isModelportfolio && openMorningStartIsin(item.isin, options.customer_id),
    className: 'name bold link',
    ellipsis: true
  },
  key: 'ProductNameCell'
}

const PayoutPlansProductNameCell = {
  ...ProductNameCell,
}

const SwitchPlansProductNameCell = {
  ...ProductNameCell,
}

const CycleCell = {
  header: 'Turnus',
  body: {
    content: (item) => item.shifts,
  },
  key: 'CycleCell'
}

const ContractCell = {
  header: 'VERTRAGSART',
  body: {
    content: (item) => item.contract
  },
  key: 'ContractCell'
}

const RateCell = {
  header: 'Rate',
  body: {
    content: (item) => withEuroOrDash(+item.rate)
  },
  key: 'RateCell'
};

const DynamicPercentageCell = {
  header: 'Dynamisierung',
  body: {
    // pass undefined to prevent show zero
    content: (item) => withPercentOrDash(item.dynamic_percentage || undefined, false)
  },
  key: 'DynamicPercentageCell'
};

const StartDateCell = {
  header: 'Startdatum',
  body: {
    content: (item) => {
      if (!item.start_date) {
        return '-'
      }

      return moment(item.start_date).format('DD.MM.YYYY')
    }
  },
  key: 'StartDateCell'
}

const EndDateCell = {
  header: 'Enddatum',
  body: {
    content: (item) => {
      if (!item.end_date) {
        return '-'
      }

      return moment(item.end_date).format('DD.MM.YYYY')
    }
  },
  key: 'EndDateCell'
}

const PAYMENT_PLAN_STATUS = {
  1: {
    label: 'Aktiv',
    className: 'active'
  },
  2: {
    label: 'Pausiert',
    className: 'paused'
  },
  4: {
    label: 'Inaktiv',
    className: 'inactive'
  },
  5: {
    label: 'Geplant',
    className: 'inactive'
  }
};

const useChipStyles = makeStyles(() => ({
  chip: {
    height: 22,
    '&.active': {
      color: '#06f19a',
      background: '#dffeee'
    },
    '&.paused': {
      color: '#ffbe31',
      background: '#fff6df'
    },
    '&.inactive': {
      color: '#b9bec2',
      background: '#f0f1f2'
    }
  }
}));

const StatusCell = {
  header: 'Status',
  body: {
    content: (item) => {

      const classes = useChipStyles();

      const status = PAYMENT_PLAN_STATUS[item.status];

      return (
        <Chip
          label={status.label}
          className={clsx(classes.chip, status.className)}
        />
      )
    }
  },
  key: 'StatusCell'
};

const TradingActionsCell = {
  header: 'Plan ändern',
  body: {
    content: (item, options) => {

      let {
        tradePlans, paymentPlan, onAddSavingPlanOption, loading, tradeTransactions, disabled
      } = options;
      if(!onAddSavingPlanOption){
        return null;
      }
      if(loading){
        return <Skeleton />
      }

      const [EDIT_ACTION, DELETE_ACTION] = SAVING_PLANS_ACTIONS;

      const tradingOption = _.find(tradePlans, instrument => instrument.instrumentId == item.payment_id);
      // tradingType info from existing trade session
      let value = _.get(tradeTransactions, item.payment_id) || (tradingOption && tradingOption.action) || "default";

      const handleTradingOptionChange = (option) => {
        onAddSavingPlanOption(paymentPlan, item, value == option.value ? 'default' : option.value);
      };

      if (item.isSubComponent || !item.isin) {
        return null;
      }

      return (
        <Grid container spacing={1} style={{justifyContent: 'center'}}>
          <Grid item>
            <TradingAction
              title={EDIT_ACTION.actionButtonText}
              tooltip={disabled ? ORDER_EXIST_TOOLTIP : EDIT_ACTION.actionButtonTooltip}
              selected={value==EDIT_ACTION.value}
              onClick={() => handleTradingOptionChange(EDIT_ACTION)}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <TradingAction
              title={DELETE_ACTION.actionButtonText}
              tooltip={disabled ? ORDER_EXIST_TOOLTIP : DELETE_ACTION.actionButtonTooltip}
              selected={value==DELETE_ACTION.value}
              color={'#fe3f58'}
              onClick={() => handleTradingOptionChange(DELETE_ACTION)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )
    }
  },
  key: 'TradingActionsCell'
};

const getProductNameCell = (paymentPlanType) => {
  return {
    'savings_plan': ProductNameCell,
    'payout_plan': PayoutPlansProductNameCell,
    'switch_plan': SwitchPlansProductNameCell
  }[paymentPlanType] || ProductNameCell
}

export const getTableStructure = (paymentPlanType) => {

  const productNameCell = getProductNameCell(paymentPlanType)

  return {
    default: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, IsinCell, DepotSubNumberCell]
      },
      {
        content: [CycleCell],
        align: 'center'
      },
      {
        content: [RateCell],
        align: 'right'
      },
      {
        content: [DynamicPercentageCell],
        align: 'center'
      },
      {
        content: [StartDateCell],
        align: 'center'
      },
      {
        content: [EndDateCell],
        align: 'center'
      },
      {
        content: [StatusCell],
        align: 'center'
      }
    ],
    xs: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, StatusCell]
      },
      {
        content: [RateCell],
        align: 'right'
      },
    ]
  }

}

export const getTradingTableStructure = (paymentPlanType) => {

  const productNameCell = getProductNameCell(paymentPlanType)

  return {
    default: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, IsinCell, DepotSubNumberCell]
      },
      {
        content: [CycleCell],
        align: 'center'
      },
      {
        content: [RateCell],
        align: 'right'
      },
      {
        content: [DynamicPercentageCell],
        align: 'center'
      },
      {
        content: [StartDateCell],
        align: 'center'
      },
      {
        content: [EndDateCell],
        align: 'center'
      },
      {
        content: [StatusCell],
        align: 'center'
      },
      {
        content: [TradingActionsCell],
        align: 'center'
      }
    ],
    xs: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, StatusCell]
      },
      {
        content: [RateCell, TradingActionsCell],
        align: 'right'
      }
    ]
  }

}

export const getTableStructureEditList = (withSustainability) => {
  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [CycleCell]
      },
      {
        content: [RateCell]
      },
      {
        content: [StatusCell]
      },
    ],
  }

  if (withSustainability) {
    structure.default.splice(1, 0, {content: [SustainabilityCell], cellClassName: 'sustainability-cell'});
  }
  return structure
}