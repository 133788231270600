import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import { CircularProgress, Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles';

const LoadingTab = ({ classes }) => (
  <Tab classes={classes} label={<Skeleton style={{ width: '75%' }}/>} disabled/>
);

const LoadingTabContent = ({ classes }) => {
  return (
    <Grid item xs={12} className={classes.loadingTabContent}>
      <CircularProgress/>
    </Grid>
  );
};

const TabsContainer = (props) => {
  const {
    classes,
    value,
    loading,
    onChange,
    tabs,
    tabContent,
    disabled,
  } = props;

  return (
    <TabContext value={value}>
      <Paper square={true} classes={{ root: classes.paperRoot }} style={{ overflow: 'visible' }}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            {loading ? (
              <>
                <LoadingTab classes={{ root: classes.tab }}/>
                <LoadingTab classes={{ root: classes.tab }}/>
                <LoadingTab classes={{ root: classes.tab }}/>
              </>
            ) : (
              <Tabs
                value={value}
                classes={{
                  indicator: classes.activeTabIndicator,
                  scrollButtons: classes.scrollButtons
                }}
                variant="scrollable"
                onChange={onChange}
                TabIndicatorProps={{ children: <div/> }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected
                    }}
                    label={tab.label}
                    value={tab.id}
                    disabled={disabled}
                    wrapped
                  />
                ))}
              </Tabs>
            )}
            <Divider/>
          </Grid>
          <Grid item xs={12} className={classes.tabContentContainer} style={{ padding: 24 }}>
            {loading ? <LoadingTabContent classes={classes}/> : tabContent}
          </Grid>
        </Grid>
      </Paper>
    </TabContext>
  );
};

TabsContainer.propTypes = {
  loading: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.object),
  tabContent: PropTypes.element,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(TabsContainer);