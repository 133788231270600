import React from "react";
import clsx from "clsx";
import _ from 'lodash';

import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import withStyles from "@material-ui/core/styles/withStyles";
import {ExpandIconButton} from '../DashboardCard/components/ExpandButton';

import styles from "./styles";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../constants";
import {getErrorMessage} from "../../../../../../utils/utils";

import {extend_chart_with_explanation} from '../../../../../../utils/utils';
import ErrorMessage
  from '../../../../components_v2/ChartSectionBordered/components/ErrorMessage';

// TODO: merge DashboardChartSection and DashboardSection into one component
const DashboardChartSection = props => {
  const {
    loading,
    error,
    title,
    titleControl,
    showTitleControlFolded,
    contentControl,
    content,
    classes,
    minHeight,
    empty,
    emptyMessage,
    displayError,
    onExpanded,
    expanded,
    sharedSettingKey='',
    extraContent,
    dataId
  } = props;

  const customClasses = props.customClasses || {};

  const errorContent = (
    <>
      {displayError && !_.isBoolean(error) && getErrorMessage(error) ? (
         <ErrorMessage error={error} />
      ) : (
        <ErrorIcon/>
      )}
    </>
  );

  const emptyContent = <ErrorMessage error={emptyMessage || DEFAULT_EMPTY_SECTION_MESSAGE} />;

  const isExpanded = !onExpanded || expanded;

  const ExpandButtonComponent = props.ExpandButton || ExpandIconButton;

  return (
    <div className={clsx(classes.container, customClasses.container)} style={
      expanded ? {minHeight: minHeight || 'auto'} : {}
    } data-id={dataId}>
      <div className={clsx(classes.headerContainer, customClasses.headerContainer)}>
        <div className={clsx(classes.header, customClasses.header)}>
          {title}
        </div>
        {(isExpanded || showTitleControlFolded) && titleControl && (
          <div className={clsx(classes.titleControl, customClasses.titleControl)}>
            {titleControl}
          </div>
        )}
        <div>
          {!!onExpanded && <ExpandButtonComponent expanded={!!expanded} onChanged={onExpanded}/>}
        </div>
      </div>

      {contentControl}

      <div className={clsx(classes.contentContainer, customClasses.contentContainer, (loading || error || empty) && classes.centerContent, !isExpanded && 'hidden')}>
        {loading ? ( <CircularProgress/> ) : error ? errorContent : empty ? emptyContent : (
          // Do not remove content from DOM in case content is collapsed.
          // It will save time as we do not need to render content again when it will be expanded.
          <div style={{display: isExpanded ? 'block' : 'none', height: props.fullHeight && '100%'}}>
            {extend_chart_with_explanation(content, sharedSettingKey)}
            {extraContent || null}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(DashboardChartSection);