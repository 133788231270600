import { makeStyles, createStyles } from '@material-ui/core'
import {smFontSize, xsFontSize} from "../../../../utils/constants";

export const styles = theme => createStyles({
  selectRoot: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    background: 'white',
    height: 45,

    '&::before': {
      borderBottom: 'none',
      display: 'none'
    },
    '&::after': {
      borderBottom: 'none',
      display: 'none'
    },

    '& fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important'
    },

    '&.Mui-disabled': {
      opacity: '0.38 !important'
    }
  },
  selectMenuPaper: {
    border: '1px solid rgba(0, 0, 0, 0.23) !important',
    maxHeight: 450,
  },
  selectMenuItemRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'initial !important',
    fontFamily: 'Roboto-Regular !important',
    fontSize: 14,
    backgroundColor: 'white !important',
    padding: 17,
    minHeight: '2rem',
    height: 45,

    '&.bold': {
      fontFamily: 'Roboto-Bold !important',
    },

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },

    '& .MuiListItemText-root span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38',
    },

    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
    },
  },
  selectMenuIcon: {
    color: '#202A38'
  },
  selectMenu: {
    background: 'transparent !important',
    padding: '11.5px 14px !important'
  },
  selectOutlined: {
    width: '100%'
  },
  formControlLabel_label: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
    width: '100%',
    cursor: 'text'
  },
  formControlLabel_root: {
    alignItems: 'flex-start',
    margin: 0
  },
  selectMenuItemRoot_selected: {
    fontFamily: 'Roboto-Bold !important',
    backgroundColor: '#DBEDFA !important'
  },

})

export default makeStyles(styles)