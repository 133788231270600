import {CUSTOMER_ID_KEY, getFromStorage, setInStorage} from "../../utils/storage";
import {buildCustomerDashboardLink, buildOtherAssetsListLink, ROUTES} from "../../routes";
import {AGGREGATED_PORTFOLIO_ID, handleChartSizeOnWindowResize} from "../../utils/utils";
import {getPortfolioCategory} from "../../components/CustomersDataProvider/utils";
import {hasPortfolios} from "../../components/CustomersSelectorProviderNew/utils";
import {OBJECT_TYPES} from "../RiskProfiling/constants";
import {displayErrorSnackBar} from "../../components/SnackbarProvider/actions"
import {addPortfolioCombinedTradingOption, removeAllCombinedTradings,} from '../../components/TradingStore/actions'
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import {parseResponse, PortfolioHandlerResource} from "../../utils/api"
import _ from "lodash";
import {getDisabledTradingOptions} from "../RiskProfiling/utils";
import moment from "moment";
import React from "react";
import Base64 from "crypto-js/enc-base64";
import HmacSHA1 from "crypto-js/hmac-sha1";
import {formatPortfolioName} from "../../utils/aggregated-portfolio";
import {getHostRelatedSetting} from "../../utils/sharedSettings";
import {NOT_FOUND} from "../../components/SharedSettingsProvider/constants";
import {createSelector} from "reselect";

function portfolioTradingPossible(portfolio, banksMapping) {

  if (portfolio.is_group) {
    return false
  }

  let { isDisabled, bankCode, isModelportfolio } = getPortfolioCategory(portfolio)
  // empty MP and historical are not tradable
  let emptyPortfolioTradeable = !isModelportfolio && !portfolio.is_historical;
  let inactiveValidationPassed = !portfolio.is_empty || emptyPortfolioTradeable;

  return (!isDisabled &&
    !portfolio.not_connected &&
    !portfolio.is_passive &&
    inactiveValidationPassed &&
    banksMapping && banksMapping.hasOwnProperty(bankCode))

}

function getPortfoliosWithNewBuyEnabled(portfolios, banksMapping) {
  return portfolios.reduce((result, portfolio) => {
    if (portfolioTradingPossible(portfolio, banksMapping)) {
      result.push(portfolio)
    }
    return result
  }, [])
}

export function tradingPossible(portfolios, banksMapping) {

  if (!Array.isArray(portfolios) || !portfolios.length) {
    return false
  }

  return portfolios.reduce((result, portfolio) => {
    if (!result) {
      return portfolioTradingPossible(portfolio, banksMapping)
    }
    return result
  }, false)
}

export function buildCustomerDashboardRoute() {
  const customer_id = getFromStorage(CUSTOMER_ID_KEY) || '';
  return buildCustomerDashboardLink(customer_id);
}

export function buildCustomerAssetsRoute() {
  const customer_id = getFromStorage(CUSTOMER_ID_KEY) || '';
  return buildOtherAssetsListLink(customer_id);
}

export function saveCustomerIdByPathName(pathname) {
  const customer_id_index = ROUTES.BROKER.CUSTOMER_DASHBOARD.path.split('/').indexOf(':customer_id');
  const clear_path = ROUTES.BROKER.CUSTOMER_DASHBOARD.path.replace('/:customer_id', '');

  if (pathname.includes(clear_path)) {
    const customer_id = pathname.split('/')[customer_id_index] || '';
    return saveCustomerId(customer_id);
  }
}

export function saveCustomerIdByUserName(username) {

  return saveCustomerId(username.slice(1));
}

export function saveCustomerId(customerId) {
  setInStorage(CUSTOMER_ID_KEY, customerId);
}

/*
* Extend portfolios with savings plans.
* **/
const extendPortfoliosWithSavingsPlans = (portfolios, portfoliosWithSavingPlans) => {
  if (!portfolios) return []

  portfolios.forEach(portfolio => {
    let portfolioWithSavingPlans = _.find(portfoliosWithSavingPlans,
      spPortfolio => spPortfolio.id == portfolio.id);
    extendPortfolioWithSavingsPlans(portfolio, portfolioWithSavingPlans);
  });

  return portfolios
}

export const extendPortfolioWithSavingsPlans = (portfolio, portfolioWithSavingPlans) => {
  portfolio.base_components = portfolio.components;
  if (portfolioWithSavingPlans) {
    portfolio.components = portfolioWithSavingPlans.components
  } else {
    portfolio.components = []
  }
}

export const extendPaymentPlanWithInstrumentData = (paymentPlan, instruments) => {

  const paymentPlanCopy = _.cloneDeep(paymentPlan);
  for (let instrument of instruments) {
    if (instrument.isin == paymentPlanCopy.isin) {
      for (let key in instrument) {
        if (!paymentPlanCopy.hasOwnProperty(key)) {
          paymentPlanCopy[key] = instrument[key]
        }
      }
      if (!_.isEmpty(paymentPlanCopy.components)) {
        paymentPlanCopy.components = paymentPlanCopy.components
          .map((component) => extendPaymentPlanWithInstrumentData(component, instruments))
      }
      break;
    }
  }

  return paymentPlanCopy;
}


export const extendPortfoliosWithDataForTrading = (portfolios, banksMappingData, banksData=undefined) => {

  let portfoliosCopy
  if (!Array.isArray(portfolios)) {
    portfoliosCopy = [portfolios]
  } else {
    portfoliosCopy = portfolios
  }

  portfoliosCopy.forEach(portfolio => {
    const { isModelportfolio, isPrivateInvestment, isDisabled, categoryName, bankCode } = getPortfolioCategory(portfolio)

    portfolio.isModelportfolio = isModelportfolio
    portfolio.isPrivateInvestment = isPrivateInvestment
    portfolio.isDisabled = isDisabled
    portfolio.categoryName = categoryName
    portfolio.bankCode = banksMappingData.hasOwnProperty(bankCode) && banksMappingData[bankCode].code
    portfolio.companyId = bankCode
    portfolio.trading_enabled = portfolioTradingPossible(portfolio, banksMappingData)
    portfolio.saving_plan_trading_enabled = isPortfolioAvailableForSavingsPlan(portfolio, portfoliosCopy, banksMappingData)
    portfolio.payout_plan_trading_enabled = isPortfolioAvailableForPayoutPlan(
      portfolio, portfoliosCopy, banksMappingData, banksData)
    portfolio.switch_plan_trading_enabled = isPortfolioAvailableForSwitchPlan(
      portfolio, portfoliosCopy, banksMappingData, banksData)
  })
}

/**
 * Get list of portfolios, that are valid for savings plans.
 *  - Portfolio shouldn't have active savings plans.
 *
 *  @param {Array<Object>} portfolios List of portfolios data
 *  @param {Object} banksMapping Dict with mapping between custodian and bank id
 *
 *  @return {Array<Object>} List of portfolios, that are valid for creating new savings plans
 * */
const getPortfoliosForSavingPlans = (portfolios, banksMapping, banksData=undefined, isVirtual=false) => {
  return !!isVirtual ? portfolios : _.filter(portfolios, portfolio => {
    return portfolioTradingPossible(portfolio, banksMapping);
  })
};

const getPortfoliosForSwitchPlans = (portfolios, banksMapping, banksData=undefined, isVirtual=false) => {
  return !!isVirtual ? portfolios : _.filter(portfolios, portfolio => {

    const { isModelportfolio } = getPortfolioCategory(portfolio)

    return portfolioTradingPossible(portfolio, banksMapping)
      && !!isPortfolioSwitchPlansAllowed(portfolio, banksData)
      && !portfolio.is_empty
      && !isModelportfolio
  })
};

/**
 * Check, if banks allow payout plans for portfolio
 * @param portfolio
 * @param banksData
 */
export const isPortfolioPayoutPlansAllowed = (portfolio, banksData=undefined) => {
  // Portfolio is not available for payout plans,
  // as we could not read banks configuration to know if it is possible to have payout plans
  if (_.isEmpty(banksData)) return false;

  let { bankCode } = getPortfolioCategory(portfolio);
  return _.get(_.find(banksData, (bank) => bank.custodian_id == bankCode), 'payout_plans_allowed')
}


/**
 * Check, if banks allow switch plans for portfolio
 * @param portfolio
 * @param banksData
 */
export const isPortfolioSwitchPlansAllowed = (portfolio, banksData=undefined) => {
  // Portfolio is not available for switch plans,
  // as we could not read banks configuration to know if it is possible to have switch plans
  if (_.isEmpty(banksData)) return false;

  let { bankCode } = getPortfolioCategory(portfolio);
  return _.get(_.find(banksData, (bank) => bank.custodian_id == bankCode), 'switch_plans_allowed')
}


/**
 * Get list of portfolios, that are valid for payout plans.
 *  - Portfolio shouldn't have active payout plans.
 *
 *  @param {Array<Object>} portfolios List of portfolios data
 *  @param {Object} banksMapping Dict with mapping between custodian and bank id
 *
 *  @return {Array<Object>} List of portfolios, that are valid for creating new payout plans
 * */
const getPortfoliosForPayoutPlans = (portfolios, banksMapping, banksData=undefined, isVirtual=false) => {
  return !!isVirtual ? portfolios : _.filter(portfolios, portfolio => {
    return portfolioTradingPossible(portfolio, banksMapping)
      && !!isPortfolioPayoutPlansAllowed(portfolio, banksData)
      && !portfolio.is_empty
  })
};

export const isPortfolioAvailableForSavingsPlan = (savingsPlan, portfolios, banksMapping) => {
  const portfolio = _.find(portfolios, p => p.id == savingsPlan.id);
  return portfolio && portfolioTradingPossible(portfolio, banksMapping);
};

export const isPortfolioAvailableForSwitchPlan = (savingsPlan, portfolios, banksMapping, banksData=undefined) => {
  const portfolio = _.find(portfolios, p => p.id == savingsPlan.id);

  if (!portfolio) return false

  const { isModelportfolio } = getPortfolioCategory(portfolio)

  return !!isPortfolioSwitchPlansAllowed(portfolio, banksData)
    && !isModelportfolio
    && !portfolio.is_empty;
};

export const isPortfolioAvailableForPayoutPlan = (savingsPlan, portfolios, banksMapping, banksData=undefined) => {
  const portfolio = _.find(portfolios, p => p.id == savingsPlan.id);
  return portfolio
    && portfolioTradingPossible(portfolio, banksMapping)
    && !!isPortfolioPayoutPlansAllowed(portfolio, banksData)
    && !portfolio.is_empty;
};


/**
 * Check, if portfolio is Payment plans only.
 * Payment plans only portfolio = portfolio with only payment
 * plans or instruments,resulted by running payment plans.
 * @param portfolio {Object} Portfolio data
 * @param paymentPlanPortfolio Savings plans portfolio data
 */
export const portfolioIsPaymentPlansOnly = (components, paymentPlans) => {

  if (!paymentPlans) {
    return false
  }

  for (let component of (components || [])) {
    if (!_.find(paymentPlans, (paymentPlan) => paymentPlan.isin === component.isin)) {
      return false
    }
  }

  return true

}

/**
 * Validate, if payment plan already hase executed position in instruments list.
 * @param paymentPlan {Object} Payment plan data
 * @param positions {List<Object>} Portfolio data
 */
export const paymentPlanHasPosition = (paymentPlan, positions) => {

  return !!_.find(positions, (component) => paymentPlan.isin === component.isin)

}

/**
 * Check, if there is at least one payment plans for isin, that was not added to transactions.
 * @param isin {String} ISIN
 * @param paymentPlans {Object[]} List of payment plans
 * @param transactions {Object[]} List of transactions
 */
export const hasUnusedPaymentPlans = (isin, paymentId, paymentPlans, transactions) => {

  if (_.isEmpty(paymentPlans)) {
    return false
  }

  const isinPaymentPlans = paymentPlans
    .filter((_paymentPlan) => _paymentPlan.isin === isin)

  if (isinPaymentPlans.length <= 1) {
    return false
  }

  if (_.isEmpty(transactions)) {
    return true
  }

  const hasTransactionForPaymentPlan = (paymentPlan) => {

    const deleteTransactions = transactions.filter((transaction) => transaction.action === 'delete')

    return _.find(deleteTransactions, (transaction) => {
      return _.get(transaction, 'data.payment_id') === paymentPlan.payment_id || paymentPlan.payment_id === paymentId
    })
  }

  for (let paymentPlan of isinPaymentPlans) {

    if (!hasTransactionForPaymentPlan(paymentPlan)) {
      return true
    }
  }

  return false

}

export const ChartTooltipSettings = {
  chart: {
    events: {
      load: function(event) {
        this.tooltip.options.style.width = (this.chartWidth-10)+'px';
        handleChartSizeOnWindowResize(event)
      },
      redraw: function() {
        this.tooltip.options.style.width = (this.chartWidth-10)+'px';
      }
    }
  },
  tooltip: {
    positioner: function (labelWidth, labelHeight, point) {
      return this.getPosition(labelWidth, labelHeight, point);
    },
    style: {
      whiteSpace: 'normal',
    },
    formatter: function (tooltip) {
      function truncate(str, n){
        return (str.length > n) ? str.substr(0, n-1) + '..' : str;
      }

      this.points = this.points.filter(point => point.series.name !== 'startOfBenchmark')

      const chartWidth = this.points[0].series.chart.chartWidth,
            coef = this.points.length > 3 ? 2 : 1;

      const maxChars = chartWidth / (chartWidth > 500 ? 3 : 5) / coef;

      this.points.map(function (point) {
        point.series.name = truncate(point.series.name,  maxChars);
      });

      return tooltip.defaultFormatter.call(this, tooltip);
    },
  },
  getTooltipPadding(seriesCount, shownNumber=6) {
    const defaultPadding = 8, minPadding = 1;
    const tooltipPadding = seriesCount > shownNumber ? (defaultPadding - (seriesCount - shownNumber)) : defaultPadding;

    return tooltipPadding < minPadding ? minPadding : tooltipPadding;
  }
}


/**
 * Prepare portfolios data for combined trading process.
 * @param kwargs {{
 *   instrumentListPortfolios: List<Object>,
 *   profitAndLossPortfolios: List<Object>,
 *   savingPlansPortfolios: List<Object>?,
 *   payoutPlansPortfolios: List<Object>?,
 *   switchPlansPortfolios: List<Object>?,
 *   banksMappingData,
 *   banksData
 * }}
 */
export const preparePortfoliosForCombinedTrading = (kwargs) => {

  const {
    instrumentListPortfolios,
    profitAndLossPortfolios,
    savingPlansPortfolios,
    payoutPlansPortfolios,
    switchPlansPortfolios,
    banksMappingData,
    banksData,
    isVirtual, // for now skip some validation for virtual portfolios
  } = kwargs;

  // In case we do not have active portfolios trading is not possible
  if (_.isEmpty(instrumentListPortfolios)) {
    return []
  }

  function _getPaymentPlanPortfolios(paymentPlansList) {
    if (!paymentPlansList) {
      return []
    }

    let portfolios = _.cloneDeep(instrumentListPortfolios);
    let portfoliosWithSavingPlans = _.cloneDeep(paymentPlansList);

    if (!portfoliosWithSavingPlans) {
      return []
    }

    return extendPortfoliosWithSavingsPlans(portfolios, portfoliosWithSavingPlans)
  }

  function _updatePortfolioWithPaymentPlanData(portfolio, paymentPlans, componentsFieldName) {

    portfolio[componentsFieldName] = []

    for (let paymentPlanPortfolio of paymentPlans) {
      if (portfolio.id === paymentPlanPortfolio.id) {
        portfolio[componentsFieldName] = paymentPlanPortfolio.components
        break
      }
    }
  }

  const normalTradingPortfolios = getPreparedPortfoliosForTradingsBuyProcess(instrumentListPortfolios, profitAndLossPortfolios, banksMappingData, isVirtual);
  const _savingPlansPortfolios = getPortfoliosForSavingPlans(_getPaymentPlanPortfolios(savingPlansPortfolios), banksMappingData || {}, banksData, isVirtual);
  const _payoutPlansPortfolios = getPortfoliosForPayoutPlans(_getPaymentPlanPortfolios(payoutPlansPortfolios), banksMappingData || {}, banksData, isVirtual);
  const _switchPlansPortfolios = getPortfoliosForSwitchPlans(_getPaymentPlanPortfolios(switchPlansPortfolios), banksMappingData || {}, banksData, isVirtual);

  normalTradingPortfolios.forEach((portfolio) => {
    _updatePortfolioWithPaymentPlanData(portfolio, _savingPlansPortfolios, 'savings_plan');
    _updatePortfolioWithPaymentPlanData(portfolio, _payoutPlansPortfolios, 'payout_plan');
    _updatePortfolioWithPaymentPlanData(portfolio, _switchPlansPortfolios, 'switch_plan')
  });

  return normalTradingPortfolios

};


const getPreparedPortfoliosForTradingsBuyProcess = (instrumentList, profitAndLoss, banksMappingData, isVirtual) => {
  const portfoliosToValidate = _.uniqBy(_.flatten([instrumentList || [], profitAndLoss || []]), (p) => p.id);
  let portfolios = !!isVirtual ? portfoliosToValidate : getPortfoliosWithNewBuyEnabled(portfoliosToValidate, banksMappingData || {})

  portfolios.forEach(ptf => {
    const { isModelportfolio, isPrivateInvestment, isDisabled, categoryName, bankCode } = getPortfolioCategory(ptf);

    ptf.isModelportfolio = isModelportfolio;
    ptf.isPrivateInvestment = isPrivateInvestment;
    ptf.isDisabled = isDisabled;
    ptf.categoryName = categoryName;
    ptf.bankCode = banksMappingData.hasOwnProperty(bankCode) && banksMappingData[bankCode].code;
    ptf.companyId = bankCode
  });

  return portfolios
};

export const tradeDetailsRedirect = (routes, destinitionComponentIdentifier, customer_id, success_callback) => {
  let investmentDynamicPath = getInvestmentDynamicPath()

  executeIfPathExist(routes, destinitionComponentIdentifier, (path) => {
    let url = '/' + investmentDynamicPath + path;
    success_callback(url.replace(':customer_id', customer_id));
  },':customer_id');
}

export class OnboardingButtonAttributesHelper{
  static isOnboardingProcessStarted(onboardingProcess){
    /**
     * Returns true if onboarding process is not finished and has answers
     */
    return onboardingProcess && !onboardingProcess.finished && onboardingProcess.has_answers
  }

  static getDefaultAttributes(isLastOnboardingFinished, customer){
    let componentIdentifier = 'TRADE_DETAILS';
    let disabled = !hasPortfolios(customer);

    const title = isLastOnboardingFinished ? "Order fortsetzen" : "Order";
    const deleteBtnTitle = "Order löschen";

    return {
      title: title,
      deleteBtnTitle: deleteBtnTitle,
      disabled: disabled,
      componentIdentifier: componentIdentifier,
    };

  }

  static async dispatchIfPropperPortfolios(props, portfolio, customerData){
    let errorMsg = 'Für diesen Kunde ist Handeln nicht möglich';

    if(!_.isEmpty(portfolio)){
      await props.dispatch(addPortfolioCombinedTradingOption(customerData, portfolio));
      tradeDetailsRedirect(props.investmentPlatform.routes, 'TRADE_DETAILS', customerData.customer_id, (url) => {
        props.history.push(url);
      })
    } else{
      props.dispatch(displayErrorSnackBar(errorMsg));
    }
  }

  static async dispatchRemoveTradings(props, customerId){
    await props.dispatch(removeAllCombinedTradings(customerId));
  }

  static async getCombinedTradingPortfolioValidationData(customerId){
    // Returns portfolio data needed for onboarding of provided type
    let response = await PortfolioHandlerResource.getPortfolioData(customerId);
    let validationData = {};
    parseResponse(response, 'instruments_table', (data) => {
      validationData.portfolios = data.instruments || [];
      validationData.profitAndLoss = data.profit_loss || [];
    });

    ['payment_plans', 'payout_plans', 'switch_plans'].forEach((paymentPlanKey) => {
      parseResponse(response, paymentPlanKey, (data) => {
        validationData[paymentPlanKey] = data || [];
      });
    })

    return validationData
  }

  static async getPortfolioValidationData(customerId, onboardingType){
    // Returns portfolio data needed for onboarding of provided type
    let response = await PortfolioHandlerResource.getPortfolioData(customerId);
    let validationData = {};
    parseResponse(response, 'instruments_table', (data) => {
      validationData.portfolios = data.instruments || [];
      validationData.profitAndLoss = data.profit_loss || [];
    });

    const dataKey = {
      [OBJECT_TYPES.SAVINGS_PLAN]: 'payment_plans',
      [OBJECT_TYPES.PAYOUT_PLAN]: 'payout_plans',
      [OBJECT_TYPES.SWITCH_PLAN]: 'switch_plans'
    }[onboardingType];

    if (dataKey) {
      parseResponse(response, dataKey, (data) => {
        validationData.plans = data || [];
      });
    }

    return validationData
  }

}

export const prepareComponentsWithContractNumber = (data, toTable=false, componentsFieldKey='components') => {
  const result = {}
  data[componentsFieldKey]
    .filter((paymentPlan) => !!paymentPlan.contract_number)
    .forEach((paymentPlan) => {

      if (!result.hasOwnProperty(paymentPlan.contract_number)) {
        let name = data.name;

        let mp_number = data.is_private_investment
          ? _.get(data, 'investment_strategy_data.portfolio_id')
          : _.get(data, 'model_portfolio_data.agency_portfolio_id');

        if(toTable){
          // in case dashbaord table selected
          if(mp_number){
            name = data.is_private_investment
              ? _.get(data, 'investment_strategy_data.name')
              : _.get(data, 'model_portfolio_data.name');
          }
        }
        result[paymentPlan.contract_number] = {
          ...paymentPlan,
          name: name,
          isin: paymentPlan.contract_number, // use contract number as an isin to differentiate payment plans
          wkn: null,
          isModelportfolio: data.is_model_portfolio,
          is_private_investment: data.is_private_investment,
          is_model_portfolio: data.is_model_portfolio,
          investment_strategy_data: data.investment_strategy_data,
          model_portfolio_data: data.model_portfolio_data,
          mp_number: mp_number,
          components: [],
        }
      }
      // extend with isSubComponent to know in what case trading functionality should be used or not
      let componentData = {...paymentPlan}
      if (toTable) {
        componentData['isSubComponent'] = true
        result[paymentPlan.contract_number] = {
          ...result[paymentPlan.contract_number],
          tableProps: {
            isHeader: true // Should be handled inside DashboardTable as header
          }
        }
      }
      result[paymentPlan.contract_number].components.push(componentData)
      result[paymentPlan.contract_number].rate = _.round(result[paymentPlan.contract_number].components
        .reduce((rate, paymentPlan) => rate + parseFloat(paymentPlan.rate || 0), 0), 2)
    })
  return result
}

export const isCumulativeDisplayEnabled = (selectedPortfolios) =>{
  /** Function used to check if cumulative display should be enabled for historical and performance line charts
   * Cumulative display is enabled if more than one portfolio is selected, but not all of them (aggregated is not included)
   * */

  // indicates that more than one portfolio is selected
  let moreThanOneSelected = selectedPortfolios && selectedPortfolios.length > 1

  return moreThanOneSelected
}

export const wholeDepotOrderHandler = (portfolio, onAddTradingOption, tradingType, custodiansInstrumentsData) => {
  if (onAddTradingOption) {
    (portfolio.components || []).filter(i => i.weight).forEach((instrument) => {
      instrument.wholeDepotOrder = tradingType !== 'default';
      const disabledTradingOptions = getDisabledTradingOptions(instrument, custodiansInstrumentsData);
      if (!disabledTradingOptions.includes(tradingType)) {
        onAddTradingOption(portfolio, instrument, tradingType);
      }
    })
  }
}

export const isUnrealizedProfitLossDataExist = (unrealizedProfitAndLossData) => {
  return unrealizedProfitAndLossData && (
    unrealizedProfitAndLossData.inflows.length > 0 || unrealizedProfitAndLossData.outflows.length > 0
  );
};

export const getTrasactionSaldoTitle = (unrealizedProfitAndLossData) => {
  let dates = '';
  if(unrealizedProfitAndLossData){
    let start_date = unrealizedProfitAndLossData.start_date && moment(unrealizedProfitAndLossData.start_date).format('DD.MM.YYYY');
    let end_date = unrealizedProfitAndLossData.end_date && moment(unrealizedProfitAndLossData.end_date).format('DD.MM.YYYY');
    dates = start_date && end_date ? `vom ${start_date} bis zum ${end_date}` : '';
  }

  return `Transaktionssaldo ${dates}`
}

export const automaticallyUncollapsePaymentPlans = (instrumentListData, onExpandedItemsChange, plans) => {
  if (!_.isNil(instrumentListData)) {
    const instrumentsItems = instrumentListData.filter(portfolio => !portfolio.is_empty).map(portfolio => portfolio.id)
    onExpandedItemsChange('instrumentsItems', instrumentsItems);

    // TODO: onExpandedItemsChange pass object instead of key,value

    let timeOut = 100

    // loop over dict of payment plans  where key is payment plan name in expanded items and value is list
    for (const [planKey, plansData] of Object.entries(plans)) {
      setTimeout(() => {
        // if plans exist
        if( _.isArray(plansData)) {
          // filter plans of empty portfolios
          const plansItems = plansData.filter(item => instrumentsItems.includes(item.id)).map(item => item.id)
          // automatically expand plans for portfolios that are not empty
          onExpandedItemsChange(planKey, plansItems);
        }
      }, timeOut);  // timeout was added in PR for redmine ticket 67126 in CustomerDashboard/components/Widgets component

      // increase delay
      timeOut += 100
    }
  }
}

/**
 * Build internal id for asset. It is isin + sub depot number (in case asset belongs to customer/depot);
 * @param {{isin: String, depot_sub_number: String?}} assetData
 * @returns {string}
 */
export const getAssetInternalId = (assetData) => {

  let id = [assetData.isin];
  let depotSubNumber = assetData.depot_sub_number;
  if (depotSubNumber) {
    id.push(depotSubNumber)
  }

  return id.join('_');

}

export const getKeyFieldValue = (data, keyField) => _.isFunction(keyField) ? keyField(data) : data[keyField];

export const DashboardPortfoliosSelectorContext = React.createContext({
  selectedPortfolios: undefined,
  onSelectedPortfoliosChanged: undefined,
  selectedComponents: undefined,
  onSelectedComponentsChanged: undefined,
})

export function useDashboardPortfoliosSelectorContext() {

  const context = React.useContext(DashboardPortfoliosSelectorContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

export const getOrdersCount = (newOrders, existingSession) => {
  if (!_.isEmpty(existingSession)) {
    return Object.values(existingSession).reduce((ordersCount, portfolioTradings) => {
      return ordersCount + Object.keys(portfolioTradings).length;
    }, 0);
  }

  return newOrders.reduce((ordersCount, portfolio) => {
    return ordersCount + (portfolio.instruments || []).length + !!portfolio.tradingType;
  }, 0);
}

export const SharedDataContext = React.createContext({});

export function useSharedDataContext() {
  return React.useContext(SharedDataContext);
}

export function SharedData({children}) {

  const dataLoading = React.useRef({});
  const [data, setData] = React.useState({});

  const setPortfolioDataLoading = React.useCallback((portfolioId, loading) => {
    dataLoading.current[portfolioId] = loading;
  }, []);

  const isPortfolioDataLoading = React.useCallback((portfolioId) => {
    return dataLoading.current[portfolioId];
  }, []);

  const setPortfolioData = React.useCallback((portfolioId, _data) => {
    setData((current) => ({
      ...current,
      [portfolioId]: _data
    }));
  }, []);

  const getPortfolioData = React.useCallback((portfolioId, defaultValue={}) => {
    return data[portfolioId] || defaultValue;
  }, [data]);

  const contextValue = React.useMemo(() => ({
    setPortfolioData,
    getPortfolioData,
    setPortfolioDataLoading,
    isPortfolioDataLoading
  }), [setPortfolioData, getPortfolioData, setPortfolioDataLoading, isPortfolioDataLoading]);

  return (
    <SharedDataContext.Provider value={contextValue}>
      {children}
    </SharedDataContext.Provider>
  )
}

export const getDataRetrievingParamsFromProps = (props) => {

  const showBenchmark = props.chartsSettings.performance.withBenchmark;

  return {
    dataProvider: props.dataProvider || PortfolioHandlerResource,
    dates: {
      start: props.calculationDates.start && props.calculationDates.start.format('YYYY-MM-DD') || undefined,
      end: props.calculationDates.end && props.calculationDates.end.format('YYYY-MM-DD') || undefined,
    },
    portfolios: (props.selectedPortfolios || []).filter((portfolio) => !!portfolio.depotNumber),
    customerId: _.get(props, 'customerData.customer_id'),
    showBenchmark,
    benchmarks: showBenchmark ? props.chartsSettings.performance.currently_selected_benchmarks.map(b => {
      b.percentage = parseInt(b.percentage)
      return b
    }) : undefined,
    withHistorical: props.chartsSettings.global.withHistoricalPortfolios,
    assets: [], //TODO: we have no assets selection for now
    investmentStrategyId: props.investmentStrategyId,
  }
}

export function getHashValue(...values) {
  const valuesString = values.join('_');
  return Base64.stringify(HmacSHA1(valuesString, ''));
}

export const getPortfolioChartName = (portfolio, aggregatedPortfolioName) => {
  return portfolio.id == AGGREGATED_PORTFOLIO_ID
    ? aggregatedPortfolioName
    : formatPortfolioName(portfolio.name)
};

// by default everything is unfolded (shown)
export const isExpanded = (expandedItems, path) => _.get(expandedItems, path, true);

export const getSharedSettingsWarning = (key, sharedSettingsData) => {
  return !!key && (getHostRelatedSetting(sharedSettingsData, key) || NOT_FOUND);
};

const getSharedSettings = (state) => state.get('sharedSettings');
export const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
);

export const getPortfolioColor = (portfolios, portfolio) => {
  portfolio = _.find(portfolios || [], (p => (portfolio.depotNumber || portfolio.id) == p.depotNumber)) || portfolio;
  return portfolio.color;
};