import {useState} from "react";
import _ from "lodash";

import {PortfolioHandlerResource} from "../../../utils/api";
import {_parseResponse, getBenchmarkPortfolio, getPortfoliosToUse} from "./useRollingVolatilityData";
import {AGGREGATED_PORTFOLIO_ID} from "../../../utils/utils";

async function fetchPortfolioRollingSharpeRatioData(customerId, portfolio, assets, startDate, endDate, dataProvider, investmentStrategyId) {
  const response = await dataProvider.getRollingSharpeData(
    customerId, portfolio.portfolioIdsToRequest || portfolio.depotNumber, startDate, endDate, assets, investmentStrategyId);

  return [portfolio, response];
}

function handleRollingSharpeRatio(data) {
  let _data = data['indicators'];
  return {
    portfolio: _data.map(value => ({date: value.date, value: value.sharpe_ratio_a_rolling || 0})),
    error: null
  }
}

/**
 * Provide rolling volatility data.
 * @param customerId {Number|String} Customer identifier
 * @param portfolios {Array} List with portfolios/assets identifiers.
 * portfolios example:
 * @example
 * useRollingVolatilityData(9999999999, [
 *   {
 *     'assets': [], // If array is empty then data for whole portfolio should be requested.
 *     'name': 'portfolio name',
 *     'color': 'black'
 *   },
 *   {
 *      'assets': [ //Otherwise data for specific assets should be requested.
 *        'assetId_1',
 *        'assetId_2'
 *      ],
 *      'name': 'portfolio name',
 *      'color': 'black'
 *   }
 * ])
 */
export function useRollingSharpeRatioData(customerId, portfolios, benchmarks, aggregated, startDate, endDate, dataProvider=PortfolioHandlerResource, aggregatedPortfolioName, investmentStrategyId) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  async function fetch() {
    setData({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const response = await Promise.all(
        getPortfoliosToUse(aggregated, portfolios, aggregatedPortfolioName)
          .map((portfolioData) => fetchPortfolioRollingSharpeRatioData(
              customerId, portfolioData, portfolioData.assets || [],
              startDate, endDate, dataProvider, investmentStrategyId)
          ));

      let data = _parseResponse(response, 'rolling_sharpe_ratio', handleRollingSharpeRatio);
      if (!_.isEmpty(benchmarks)) {
        const bmResponse = await dataProvider.getRollingSharpeBMData(
          customerId, AGGREGATED_PORTFOLIO_ID, startDate, endDate,
          benchmarks, investmentStrategyId, {portfolio_ids: portfolios.map((p) => p.depotNumber)});
        const bmData = _parseResponse([[getBenchmarkPortfolio(benchmarks),
          bmResponse]], 'rolling_sharpe_ratio_bm', handleRollingSharpeRatio);
        data = {...data, ...bmData}
      }

      setData({
        data: data,
        errors: null,
        loading: false,
        updatedAt: +new Date()
      })

    } catch (errors) {
      setData({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return [data, fetch];
}