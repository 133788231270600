import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts/highstock'
import addMapModule from 'highcharts/modules/map'
import addAnnotationsModule from 'highcharts/modules/annotations'
import addBulletModule from 'highcharts/modules/bullet'
import addHeatmapModule from 'highcharts/modules/heatmap'
import addTreemapModule from 'highcharts/modules/treemap'
import boost from 'highcharts/modules/boost'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more';
import {handleChartSizeOnWindowResize} from "../../../utils/utils";

addMapModule(Highcharts);
addAnnotationsModule(Highcharts);
addBulletModule(Highcharts);
addHeatmapModule(Highcharts);
addTreemapModule(Highcharts);
highchartsMore(Highcharts);
boost(Highcharts);

(function(H) {
  // internal functions
  function stopEvent(e) {
    if (e) {
      if (e.preventDefault) {
        e.preventDefault()
      }
      if (e.stopPropagation) {
        e.stopPropagation()
      }
      e.cancelBubble = true
    }
  }

  // the wrap
  H.wrap(H.Chart.prototype, 'render', function(proceed) {
    const chart = this;
    const isScrollable = chart.options.customMouseScroll;
    proceed.call(chart);
    if (isScrollable) {
      // Add the mousewheel event
      H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'wheel', function(event) {
        let delta;
        let extr;
        let step;
        let axis = chart.xAxis[0];
        let dataMax = chart.xAxis[0].dataMax;
        let dataMin = chart.xAxis[0].dataMin;
        let newExtrMin;
        let newExtrMax;

        const e = chart.pointer.normalize(event);
        // Firefox uses e.detail, WebKit and IE uses wheelDelta
        delta = e.detail || -(e.deltaY / 120);
        delta = delta < 0 ? 1 : -1;

        if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
          extr = axis.getExtremes();
          step = (extr.max - extr.min) / 15 * delta;

          if ((extr.min + step) <= dataMin) {
            newExtrMin = dataMin;
            newExtrMax = dataMin + (extr.max - extr.min)
          } else if ((extr.max + step) >= dataMax) {
            newExtrMin = dataMax - (extr.max - extr.min);
            newExtrMax = dataMax
          } else {
            newExtrMin = extr.min + step;
            newExtrMax = extr.max + step
          }

          axis.setExtremes(newExtrMin, newExtrMax, true, false)
        }

        stopEvent(event);
        return false
      })
    }
  })
}(Highcharts));

function HighchartsBase(props) {
  const {
    language,
    options,
    ...other
  } = props;
  setHighchartsOptions(language);

  let optionsCopy = {...options}

  if (!optionsCopy.hasOwnProperty('chart')) {
    optionsCopy.chart = {}
  }

  if (!optionsCopy.chart.hasOwnProperty('events')) {
    optionsCopy.chart.events = {}
  }

  optionsCopy.chart.events.load = handleChartSizeOnWindowResize

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{style: {height: '100%', width: '100%'}}}
      {...other}
    />
  )
}

HighchartsBase.propTypes = {
  options: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired
};

function setHighchartsOptions(language) {
  const locale = language.toUpperCase();
  switch (locale) {
    case 'DE':
      Highcharts.setOptions({
        lang: {
          decimalPoint: ',',
          thousandsSep: '.',
          loading: 'Daten werden geladen...',
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          exportButtonTitle: 'Exportieren',
          printButtonTitle: 'Drucken',
          rangeSelectorFrom: 'Von',
          rangeSelectorTo: 'Bis',
          rangeSelectorZoom: 'Zeitraum',
          downloadPNG: 'Download als PNG-Bild',
          downloadJPEG: 'Download als JPEG-Bild',
          downloadPDF: 'Download als PDF-Dokument',
          downloadSVG: 'Download als SVG-Bild',
          resetZoom: 'Zoom zurücksetzen',
          resetZoomTitle: 'Zoom zurücksetzen'
        }
      });
      break;
    case 'EN':
    default:
      Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: '\u0020',
          loading: 'Loading...',
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          exportButtonTitle: 'Export',
          printButtonTitle: 'Print',
          rangeSelectorFrom: 'From',
          rangeSelectorTo: 'To',
          rangeSelectorZoom: 'Zoom',
          downloadPNG: 'Download PNG image',
          downloadJPEG: 'Download JPEG image',
          downloadPDF: 'Download PDF document',
          downloadSVG: 'Download SVG vector image',
          resetZoom: 'Reset zoom',
          resetZoomTitle: 'Reset zoom level 1:1'
        }
      });
      break
  }
}

export default HighchartsBase;
